import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

export const ColloreSwitchType1 = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const ColloreSwitchType2 = withStyles({
  switchBase: {
    color: "blue",
    "&$checked": {
      color: "blue",
    },
    "&$checked + $track": {
      backgroundColor: "blue",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const ColloreSwitchType3 = withStyles({
  switchBase: {
    color: "orange",
    "&$checked": {
      color: "orange",
    },
    "&$checked + $track": {
      backgroundColor: "orange",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const ColloreSwitchType4 = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const ColloreSwitchType5 = withStyles({
  switchBase: {
    color: "DarkRed",
    "&$checked": {
      color: "DarkRed",
    },
    "&$checked + $track": {
      backgroundColor: "DarkRed",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const ColloreSwitchType6 = withStyles({
  switchBase: {
    color: "Gray",
    "&$checked": {
      color: "Gray",
    },
    "&$checked + $track": {
      backgroundColor: "Gray",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const ColloreSwitchType7 = withStyles({
  switchBase: {
    color: "Green",
    "&$checked": {
      color: "Green",
    },
    "&$checked + $track": {
      backgroundColor: "Green",
    },
  },
  checked: {},
  track: {},
})(Switch);

export const ColloreSwitchType8 = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
  },
  checked: {},
  track: {},
})(Switch);
