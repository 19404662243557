import '../styles/marker.css';

const ShiptoMarker = (props) => {
  return (
    <div className="marker">
      <div className="pin" style={{ backgroundColor: props.color, width: props.size + 'px', height: props.size + 'px', cursor: 'pointer' }} />
      <div className="pulse" />
      <div className="tooltip">
        <p>TS ID:  <b>{props.tsId}</b></p>
        <p>Product group: <b>{props.productGroup}</b></p>
        <p>Customer type: <b>{props.customerType}</b></p>
        {/* <p>Delivery type: <b>{props.deliveryType}</b></p> */}
        <p>Shipto: <b>{props.shipToName}</b></p>
        <p>Soldto business: <b>{props.soldtoBusinessArea}</b></p>
      </div>
    </div>
  );
};

export default ShiptoMarker;