const items = (state, action) => {
  switch (action.type) {
    case "SET_ITEMS":
      // duplicate items with new and existing if GTS >= 2
      // const gts01 = action.data.filter(x => x.GTS < 2)
      // const gts2345 = action.data.filter(x => x.GTS > 1).flatMap((x) => [{ TS_ID: x.TS_ID + '|New', ...x }, { TS_ID: x.TS_ID + '|Existing', ...x }, { ...x }])
      return { ...state, list: action.data };// [...gts01, ...gts2345] };
    case "SET_ITEM":
      return {
        ...state,
        item: { ...state.item, [action.selectedId]: action.data },
      };
    default:
      return state;
  }
};

export default items;
