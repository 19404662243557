import { useEffect, useContext, useState, useRef, useMemo } from "react";
import { Store } from "../store";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import GoogleMapReact, { fitBounds } from "google-map-react";
import useSupercluster from "use-supercluster";
import PlantMarker from "./LocationPlantMarker";
import ShiptoMarker from "./LocationShiptoMarker";
import "../App.css";

const colorsArray = [
  "#FF6633",
  "#80B300",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const Marker = ({ children }) => children;
const isValid = (item) => item !== "nan" && item !== 0
function LocationsMap() {
  const { state } = useContext(Store);
  const mapRef = useRef();
  const validGTS04 = useMemo(() => state.items?.list?.filter(item => isValid(item.LAT) && (item.GTS === 0 || item.GTS === 4)), [state.items.list.length])
  const points = useMemo(() =>
    validGTS04?.filter((item) => (state.app.mapFilters.PRODUCT_TYPE === null ||
      state.app.mapFilters.PRODUCT_TYPE === "" ||
      state.app.mapFilters.PRODUCT_TYPE === item.GTS) &&
      (!state.app.mapFilters.PRODUCT_GROUP ||
        state.app.mapFilters.PRODUCT_GROUP === item.PRODUCT_GROUP) &&
      (!state.app.mapFilters.CUSTOMER_TYPE ||
        state.app.mapFilters.CUSTOMER_TYPE === item.CUSTOMER_TYPE) &&
      // (!state.app.mapFilters.DELIVERY_TYPE ||
      //   state.app.mapFilters.DELIVERY_TYPE === item.DELIVERY_TYPE) &&
      (!state.app.mapFilters.SOLDTO_BUSINESS_AREA_NAME ||
        state.app.mapFilters.SOLDTO_BUSINESS_AREA_NAME === item.SOLDTO_BUSINESS_AREA_NAME)).map((item) => {
          // console.log(item)
          return ({
            type: "Feature",
            properties: { cluster: false, ...item },
            geometry: {
              type: "Point",
              coordinates: [item.LONG, item.LAT],//[parseFloat(item[0].LONG), parseFloat(item[1].LAT)],
            },
          })
        }), [validGTS04, state.app.mapFilters.CUSTOMER_TYPE, state.app.mapFilters.PRODUCT_TYPE, state.app.mapFilters.PRODUCT_GROUP, state.app.mapFilters.SOLDTO_BUSINESS_AREA_NAME]);
  // console.log(state.items.list?.at(1), [typeof (parseFloat(state.items.list?.at(1).LONG)), typeof (state.items.list?.at(1).LAT)], typeof (points[0].geometry.coordinates[0]))
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);


  // useEffect(() => {
  //   if (state.app.mapFilters && state.items.list.length) {
  //     createMap(state.items.list);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.app.mapFilters, state.items.list]);

  const soldtoBusinessArea = [
    ...new Set(
      validGTS04
        .map((item) => item.SOLDTO_BUSINESS_AREA)
    ),
  ];

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  // TODO: please gt this api key out of here
  return (
    <Card>
      <CardContent>
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBb6z3-f6tzyXUXmZYvkM2_FPmzabbI6Hc",
            }}
            center={{ lat: 51.1657, lng: 10.4515 }}
            defaultZoom={5}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat
              ]);
            }}
          >

            {clusters.map((marker) => {

              const [lng, lat] = marker.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount
              } = marker.properties;


              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${marker.id}`}
                    lat={lat}
                    lng={lng}
                  >
                    <div
                      className="cluster-marker"
                      style={{
                        width: `${30 +
                          (pointCount / points.length) * 20
                          }px`,
                        height: `${30 +
                          (pointCount / points.length) * 20
                          }px`,
                        color: "#fff",
                        background: "#1978c8",
                        borderRadius: "50%",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                      }}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(marker.id),
                          20
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({ lat, lng });
                      }}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              }
              return marker.properties.GTS === 0 ? (
                <ShiptoMarker
                  key={marker.properties.TS_ID}
                  lat={lat}
                  lng={lng}
                  tsId={marker.properties.TS_ID}
                  productGroup={marker.properties.PRODUCT_GROUP}
                  customerType={marker.properties.CUSTOMER_TYPE}
                  deliveryType={marker.properties.DELIVERY_TYPE}
                  shipToName={marker.properties.SHIPTO_NAME}
                  soldtoBusinessArea={marker.properties.SUPPLY_MODE === "BULK" ? marker.properties.SOLDTO_BUSINESS_AREA : marker.properties.SOLDTO_BUSINESS_AREA_NAME}
                  color={
                    colorsArray[
                    soldtoBusinessArea.indexOf(
                      marker.properties.SOLDTO_BUSINESS_AREA
                    )
                    ]
                  }
                  size={marker.properties.SIZE * 0.000004 + 10}
                />
              ) : (
                <PlantMarker
                  key={marker.properties.TS_ID}
                  lat={lat}
                  lng={lng}
                  tsId={marker.properties.TS_ID}
                  productGroup={marker.properties.PRODUCT_GROUP}
                  customerType={marker.properties.CUSTOMER_TYPE}
                  deliveryType={marker.properties.DELIVERY_TYPE}
                  shipToName={marker.properties.SHIPTO_NAME}
                  size={marker.properties.SIZE * 0.0000002 + 20}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      </CardContent>
    </Card>
  );
}

export default LocationsMap;
/*
            {clusters?.map((marker) => {
              console.log(marker);

              if (marker.properties.isCluster) {
                return (
                  <Marker
                    key={`cluster-${marker.id}`}
                    lat={marker.properties.LAT}
                    lng={marker.properties.LONG}
                  >
                    <div
                      className="cluster-marker"
                      style={{
                        width: `${
                          10 +
                          (marker.properties.point_count / points.length) * 20
                        }px`,
                        height: `${
                          10 +
                          (marker.properties.point_count / points.length) * 20
                        }px`,
                      }}
                      onClick={() => {}}
                    >
                      {marker.properties.point_count}
                    </div>
                  </Marker>
                );
              }
              return marker.properties.GTS === 0 ? (
                <ShiptoMarker
                  key={marker.properties.TS_ID}
                  lat={marker.properties.LAT}
                  lng={marker.properties.LONG}
                  tsId={marker.properties.TS_ID}
                  productGroup={marker.properties.PRODUCT_GROUP}
                  customerType={marker.properties.CUSTOMER_TYPE}
                  deliveryType={marker.properties.DELIVERY_TYPE}
                  shipToName={marker.properties.SHIPTO_NAME}
                  soldtoBusinessArea={marker.properties.SOLDTO_BUSINESS_AREA}
                  color={
                    colorsArray[
                      soldtoBusinessArea.indexOf(
                        marker.properties.SOLDTO_BUSINESS_AREA
                      )
                    ]
                  }
                  size={marker.properties.SIZE * 0.000004 + 10}
                />
              ) : (
                <PlantMarker
                  key={marker.properties.TS_ID}
                  lat={marker.properties.LAT}
                  lng={marker.properties.LONG}
                  tsId={marker.properties.TS_ID}
                  productGroup={marker.properties.PRODUCT_GROUP}
                  customerType={marker.properties.CUSTOMER_TYPE}
                  deliveryType={marker.properties.DELIVERY_TYPE}
                  shipToName={marker.properties.SHIPTO_NAME}
                  size={marker.properties.SIZE * 0.0000002 + 20}
                />
              );
            })}
            */
