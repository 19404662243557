import { useState, useContext } from "react";
import { Store } from "../store";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import "../App.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
}));

function ForecastRangePicker() {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [option, setOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const handleInputValueChange = (event) => {
    setInputValue(parseFloat(event.target.value));
  };
  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const applyChanges = () => {

    if (option === "" || (option && !inputValue)) return;

    let _itemForecastsCopy = JSON.parse(
      JSON.stringify(state.items.item[state.app.selectedId].forecast)
    );

    const start = startDate ? startDate.getTime() : null;
    const end = endDate ? endDate.getTime() : null;
    // if no updates, then copy forecasts as base update so the apply functin works
    if (!_itemForecastsCopy["UPDATES"]) {
      _itemForecastsCopy["UPDATES"] = { ..._itemForecastsCopy["FORECAST"] }
    }

    console.log(start, end, _itemForecastsCopy)
    // TODO: q2 and q3
    for (let ts in _itemForecastsCopy.FORECAST) {
      if ((start && ts < start) || (end && ts > end)) continue;
      ["UPDATES"].forEach((type) => {
        switch (option) {
          // increase by %
          case 1:
            _itemForecastsCopy[type][ts] =
              _itemForecastsCopy[type][ts] +
              _itemForecastsCopy[type][ts] * (inputValue / 100);
            break;
          // decrease by %
          case 2:
            _itemForecastsCopy[type][ts] =
              _itemForecastsCopy[type][ts] -
              _itemForecastsCopy[type][ts] * (inputValue / 100);
            break;
          // increase by const
          case 3:
            _itemForecastsCopy[type][ts] =
              _itemForecastsCopy[type][ts] + inputValue;
            break;
          // decrease by const
          case 4:
            _itemForecastsCopy[type][ts] =
              _itemForecastsCopy[type][ts] - inputValue;
            break;
          // set to const
          default:
            _itemForecastsCopy[type][ts] = inputValue;
        }
      });
    }
    console.log('BEFORE DISPATCH', {
      ...state.items.item[state.app.selectedId],
      forecasts: _itemForecastsCopy,
    })
    dispatch({
      type: "SET_ITEM",
      selectedId: state.app.selectedId,
      data: {
        ...state.items.item[state.app.selectedId],
        forecast: _itemForecastsCopy,
      },
    });
    //resetChanges();
  };

  const resetChanges = () => {
    setInputValue("");
    setOption("");
    setStartDate(null);
    setEndDate(null);
  };

  const forecastDates =
    Object.keys(
      (
        (((state.items || {}).item || {})[(state.app || {}).selectedId] || {})
          .forecast || {}
      ).FORECAST || {}
    ) || [];
  const minDate = forecastDates.length
    ? new Date(parseInt(forecastDates[0]))
    : null;
  const maxDate = forecastDates.length
    ? new Date(parseInt(forecastDates[forecastDates.length - 1]))
    : null;


  return (
    <Card>
      <CardContent className="m-1">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-between" alignItems="center">
            <div>
              <DatePicker
                className="mr-2"
                disableToolbar
                variant="inline"
                initialFocusedDate={minDate}
                minDate={minDate}
                autoOk={true}
                format="MM/dd/yyyy"
                id="start-date"
                label="Start date"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <DatePicker
                className="mr-2"
                disableToolbar
                variant="inline"
                initialFocusedDate={maxDate}
                autoOk={true}
                format="MM/dd/yyyy"
                id="end-date"
                label="End date"
                value={endDate}
                onChange={handleEndDateChange}
              />
              <FormControl className={`${classes.formControl} mr-2`}>
                <InputLabel id="range-picker-options-label">
                  Choose method
                </InputLabel>
                <Select
                  labelId="range-picker-options-label"
                  id="range-picker-options"
                  value={option}
                  onChange={handleOptionChange}
                >
                  <MenuItem value={0}>Set to constant</MenuItem>
                  <MenuItem value={1}>Increase by percent</MenuItem>
                  <MenuItem value={2}>Decrease by percent</MenuItem>
                  <MenuItem value={3}>Increase by number</MenuItem>
                  <MenuItem value={4}>Decrease by number</MenuItem>
                </Select>
              </FormControl>

              <TextField
                type="number"
                id="input-value"
                label="Value"
                value={inputValue}
                onChange={handleInputValueChange}
                InputProps={
                  option === 1 || option === 2
                    ? {
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }
                    : {}
                }
              />

            </div>
            <div>
              <Button onClick={resetChanges}>Clear filters</Button>
              <Button onClick={applyChanges} color="primary">
                Apply
              </Button>
            </div>
          </Grid>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
}

export default ForecastRangePicker;
