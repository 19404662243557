import { useContext } from 'react';
import { Store } from '../store';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import '../App.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function PredictionLineChartFilters() {
  const { state, dispatch } = useContext(Store);
  const classes = useStyles();
  const handleChange = (event) => {
    dispatch({
      type: 'SET_PREDICTIONS_LINE_CHART_DISPLAY',
      data: event.target.value
    });
  };

  if (state.app.selectedId && state.items.item?.[state.app.selectedId]?.gts_info?.GTS % 2) return (<></>);
  if (!state.app.selectedId) return (<></>);

  // console.log(Object.keys(state.items?.item?.[state.app?.selectedId]?.predictions))
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="predictions-select">Select predictions to show</InputLabel>
      <Select
        labelId="predictions-select"
        multiple
        value={state.app?.predictionsLineChartDisplay}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.filter(value => value).map((value) => {
              console.log(value); return (
                <Chip key={value} label={value} className={classes.chip} />
              )
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >

        {state.items?.item?.[state.app.selectedId]?.predictions && Object.keys(state.items?.item?.[state.app.selectedId]?.predictions)?.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={state.app?.predictionsLineChartDisplay?.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PredictionLineChartFilters;