import React, { useState, useContext, useEffect, useMemo } from "react";
import { Store } from "../store";
import { serviceCall } from "../services";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import "date-fns";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";

import "../App.css";
import AutoWidthPopper from "./AutoWidthPopper";

function CustomerFilter() {
  const location = useLocation();
  const [hideAlert, setHideAlert] = useState(true);
  const { state, dispatch } = useContext(Store);
  const [message, setMessage] = useState("");
  const [supplyMode, setSupplyMode] = useState("BULK");
  const [aggregation, setAggregation] = useState(null);
  const [shipTo, setShipTo] = useState("");
  const [soldTo, setSoldTo] = useState("");
  const [newExisting, setNewExisting] = useState("Existing")

  const [plantName, setPlantName] = useState("");
  const [productGroup, setProductGroup] = useState("");
  const [material, setMaterial] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [deliveryType, setDeliveryType] = useState("");

  useEffect(() => {

    if (state.items.list.length) {
      //console.log("USE  FIRST: ", state.items.list, supplyMode);
      const item = state.items.list.find((x) => x.SUPPLY_MODE === supplyMode);

      setSupplyMode(item.SUPPLY_MODE);
      setAggregation(aggregations[item.GTS]);

      setProductGroup(
        item[supplyMode === "BULK" ? "PRODUCT_GROUP" : "PRODUCT"]
      );


      supplyMode === "PGP" && setMaterial(item.MATERIAL);

      switch (item.GTS) {
        case 0:
          setShipTo(item.SHIPTO_NAME);
          break;
        case 1:
          setSoldTo(item.SOLDTO_NAME);
          break;
        case 2:
          setPlantName(item.PLANT_NAME);
          break;
        case 3:
          setCustomerType(item.CUSTOMER_TYPE);
          supplyMode === "BULK" && setDeliveryType(item.DELIVERY_TYPE);
          break;
        default:
      }
      dispatch({
        type: "SET_SELECTED_ID",
        data: item.TS_ID,
      });
    }
    // TODO: these dependencies are dodgy, only really want this on first data load
  }, [state.items?.list?.length]);


  const supplyModes = [
    ...new Set(state.items?.list?.map((item) => item.SUPPLY_MODE)),
  ];


  const aggregations = [
    "Shipto",
    "Soldto",
    `${supplyMode === "BULK" ? "Product" : "Minor Code"} Detailed`,
    `${supplyMode === "BULK" ? "Product" : "Minor Code"}`,
    "Plant",
    `${supplyMode === "BULK" ? "Product Group" : "Major Group"}`
    // `${supplyMode === "BULK" ? "Product" : "Material"} Detailed`,
    // `${supplyMode === "BULK" ? "Product" : "Material"}`,
  ];


  const shipTos = useMemo(() => {
    return (
      [
        ...new Set(
          state.items.list
            .filter(
              (item) =>
                aggregations[item.GTS] === aggregation &&
                item.SUPPLY_MODE === supplyMode &&
                item.PRODUCT === material)
            // TODO: how does this change for PGP

            .map((item) => item.SHIPTO_NAME)
        ),
      ])
  }, [aggregation, supplyMode, material, state.items.list]);


  const soldTos = useMemo(() => {
    return (
      [
        ...new Set(
          state.items.list
            .filter(
              (item) =>
                aggregations[item.GTS] === aggregation &&
                item.SUPPLY_MODE === supplyMode &&
                item.PRODUCT_GROUP === productGroup
            )
            .map((item) => item.SOLDTO_NAME)
        ),
      ])
  }, [aggregation, productGroup, material, supplyMode]);


  const plantNames = useMemo(() => {
    return ([
      ...new Set(
        state.items.list
          .filter(
            (item) =>
              aggregations[item.GTS] === aggregation &&
              item.SUPPLY_MODE === supplyMode &&
              item.PRODUCT_GROUP === productGroup
            // TODO: how does this change for PGP - 
          )
          .map((item) => item.PLANT_NAME)
      ),
    ])
  }, [aggregation, supplyMode, productGroup])

  // mapping for bulk/pgp accounted for on server already
  const productGroups = useMemo(() => {
    return ([
      ...new Set(
        state.items.list
          .filter((item) => aggregations[item.GTS] === aggregation && item.SUPPLY_MODE === supplyMode)
          .map((item) =>
            item.PRODUCT_GROUP
          )
          .filter(Boolean)
      ),
    ])
  }, [aggregation, supplyMode])

  // mapping for bulk/pgp accounted for on server already
  const materials = useMemo(() => {
    return (
      [
        ...new Set(
          state.items.list
            .filter((item) => aggregations[item.GTS] === aggregation && item.SUPPLY_MODE === supplyMode)
            // TODO: how does this change for PGP just minor code?
            .map((item) => item.PRODUCT)
        ),
      ])
  }, [aggregation, supplyMode]);

  const customerTypes = useMemo(() => {
    return ([
      ...new Set(
        state.items.list
          .filter((item) => aggregations[item.GTS] === aggregation && item.SUPPLY_MODE === supplyMode && item.PRODUCT === material)
          .map((item) => item.CUSTOMER_TYPE)
      ),
    ])
  }, [aggregation, supplyMode, material])

  const deliveryTypes = useMemo(() => {
    return ([
      ...new Set(
        state.items.list
          .filter((item) => item.SUPPLY_MODE === "BULK" && aggregations[item.GTS] === aggregation && item.PRODUCT === material)
          .map((item) => item.DELIVERY_TYPE)
      ),
    ])
  }, [aggregation, supplyMode, material])

  const hasChanged = state.app.newExistingHasChanged

  const applyChanges = () => {
    console.log(hasChanged)
    let selectedItem = null;
    dispatch({
      type: "SET_LINE_CHART_DISPLAY",
      data: {
        ...state.app.lineChartDisplay,
        updates: false,
      },
    });
    // dispatch({
    //   type: "SET_NEW_EXISTING_CHANGED",
    //   data: newExisting

    // })
    switch (aggregations.indexOf(aggregation)) {

      case 0: // shipto

        if (!material || !shipTo) {
          displayAlert("Please select all fields GTS 0");
          return;
        }
        selectedItem = state.items.list.find(
          (item) =>
            item.PRODUCT === material &&
            item.SHIPTO_NAME === shipTo &&
            aggregations[item.GTS] === aggregation
        );

        break;
      case 1: // soldto

        if (!productGroup || !soldTo) {
          displayAlert("Please select all fields GTS 1");
          return;
        }
        selectedItem = state.items.list.find(
          (item) =>
            item.PRODUCT_GROUP === productGroup &&
            item.SOLDTO_NAME === soldTo &&
            aggregations[item.GTS] === aggregation
        );
        break;

      case 2: // product detailed
        // does PGP have delivery type
        if (!material || !customerType || (!deliveryType && supplyMode === "BULK")) {

          displayAlert("Please select all fields GTS 2");
          return;
        }


        selectedItem = supplyMode === "BULK" ? state.items.list.find(
          (item) =>
            item.PRODUCT === material &&
            item.CUSTOMER_TYPE === customerType &&
            item.DELIVERY_TYPE === deliveryType &&
            aggregations[item.GTS] === aggregation) : state.items.list.find(
              (item) =>
                item.PRODUCT === material &&
                item.CUSTOMER_TYPE === customerType &&
                // (supplyMode === 'BULK' && item.DELIVERY_TYPE === deliveryType) &&
                aggregations[item.GTS] === aggregation
            );
        break;
      case 3: // product

        if (!material) {
          displayAlert("Please select all fields GTS 3");
          return;
        }
        // TODO: product == material in BULK, in PGP minor code
        selectedItem = state.items.list.find(
          (item) =>
            item.PRODUCT === material &&
            aggregations[item.GTS] === aggregation
        );
        break;

      case 4: // plant

        if (!plantName || !productGroup) {
          displayAlert("Please select all fields GTS 4");
          return;
        }
        // TODO: product == material in BULK, in PGP minor code
        selectedItem = state.items.list.find(
          (item) =>
            item.PLANT_NAME === plantName &&
            item.PRODUCT_GROUP === productGroup &&
            aggregations[item.GTS] === aggregation
        );
        break;

      case 5: // product 

        if (!productGroup) {
          displayAlert("Please select all fields GTS 5");
          return;
        }
        // TODO: product == material in BULK, in PGP minor code
        selectedItem = state.items.list.find(
          (item) =>
            item.PRODUCT_GROUP === productGroup &&
            aggregations[item.GTS] === aggregation
        );
        break;
    }

    if (!selectedItem) {
      displayAlert("Customer not found");
      return;
    }

    dispatch({
      type: "SET_SELECTED_ID",
      data: selectedItem.TS_ID,
    });

    dispatch({
      type: "SET_NEW_EXISTING_CHANGED",
      data: newExisting,

    })
  };

  const displayAlert = (message) => {
    setMessage(message);
    setHideAlert(false);
    setTimeout(() => {
      setHideAlert(true);
    }, 5000);
  };

  const resetFields = () => {
    setProductGroup("");
    setShipTo("");
    setSoldTo("");
    setPlantName("");
    setCustomerType("");
    setDeliveryType("");
  };


  useEffect(() => {
    console.log('tank call trigger')
    if (state.app.selectedId) {
      console.log(Number(state.app.selectedId.at(4)), `${state.app.selectedId}|${newExisting}`)
      const ts_id = Number(state.app.selectedId.at(4)) > 1 ? `${state.app.selectedId}|${newExisting}` : state.app.selectedId
      // some weird characters in name, mainly trying to encode the # and spaces, add back in |
      serviceCall(
        `tank?id=${encodeURIComponent(ts_id).replace(
          "%7C",
          "|"
        )}`,
        "GET",
        null,
        (data) => {
          dispatch({
            type: "SET_ITEM",
            selectedId: state.app.selectedId,
            data: data,
          });
          const bestModelValue = Math.min(...Object.values(data.score));
          dispatch({
            type: "SET_PREDICTIONS_LINE_CHART_DISPLAY",
            data: [
              Object.keys(data.score).find(
                (model) => data.score[model] === bestModelValue
              ),
            ],
          });
        }
      );
    }
  }, [state.app.selectedId, state.app.newExistingHasChanged]);


  const materialDropDownLabel = useMemo(() => supplyMode === "BULK" ? "Product" : "Minor Code", [supplyMode])
  const productGroupDropDownLabel = useMemo(() => supplyMode === "BULK" ? "Product Group" : "Major Group", [supplyMode])

  return (
    <Card
      className="mb-2"
      hidden={!["/trends", "/validation"].includes(location.pathname)}
    >
      <CardContent className="m-1">
        <Typography color="textSecondary" gutterBottom>
          Select filters
        </Typography>
        <Grid container justify="space-between" alignItems="center">
          <div>
            <Grid container justify="space-between" alignItems="center">
              <Autocomplete
                disableClearable
                value={supplyMode}
                options={supplyModes}
                onChange={(event, item) => {
                  setSupplyMode(item);
                  resetFields();
                }}
                style={{ width: 150 }}
                renderInput={(params) => (
                  <TextField {...params} label="Supply mode" />
                )}
              />
              {supplyMode ? (
                <Autocomplete
                  className="ml-1"
                  disableClearable
                  value={aggregation}
                  options={aggregations}
                  onChange={(event, item) => {
                    setAggregation(item);

                    resetFields();
                  }}
                  style={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Aggregation type" />
                  )}
                />
              ) : (
                ""
              )}
              {aggregation ? (
                aggregations.indexOf(aggregation) === 1 || aggregations.indexOf(aggregation) === 4 || aggregations.indexOf(aggregation) === 5 ? (
                  <Autocomplete
                    id="product"
                    className="ml-1"
                    disableClearable
                    // TODO: this is major group in pgp
                    // TODO: should this be material in gts 3
                    value={productGroup}
                    options={productGroups}
                    onChange={(event, item) => {
                      setProductGroup(item);
                    }}
                    style={{ width: 150 }}
                    // HERE
                    renderInput={(params) => (
                      <TextField {...params} label={productGroupDropDownLabel} />
                    )}
                  />
                ) : (
                  <Autocomplete
                    id="material"
                    className="ml-1"
                    PopperComponent={AutoWidthPopper}
                    disableClearable
                    style={{ width: 350 }}
                    value={material}
                    options={materials}
                    onChange={(event, item) => {
                      setMaterial(item);
                    }}
                    // HERE
                    renderInput={(params) => (
                      <TextField {...params} label={materialDropDownLabel} />
                    )}
                  />
                )
              ) : (
                ""
              )}
              {aggregation === "Shipto" ? (
                <Autocomplete
                  id="shipTo"
                  className="ml-1"
                  disableClearable
                  PopperComponent={AutoWidthPopper}
                  value={shipTo}
                  options={shipTos}
                  onChange={(event, item) => {
                    setShipTo(item);
                  }}
                  style={{ width: 350 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Shipto name" />
                  )}
                />
              ) : (
                ""
              )}
              {aggregation === "Soldto" ? (
                <Autocomplete
                  id="soldTo"
                  className="ml-1"
                  disableClearable
                  value={soldTo}
                  options={soldTos}
                  onChange={(event, item) => {
                    setSoldTo(item);
                  }}
                  style={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Soldto name" />
                  )}
                />
              ) : (
                ""
              )}

              {aggregation === "Plant" ? (
                <Autocomplete
                  id="plantName"
                  className="ml-1"
                  disableClearable
                  value={plantName}
                  options={plantNames}
                  onChange={(event, item) => {
                    setPlantName(item);
                  }}
                  style={{ width: 350 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Plant name" />
                  )}
                />
              ) : (
                ""
              )}
              {aggregations.indexOf(aggregation) === 2 ? (
                <React.Fragment>
                  <Autocomplete
                    id="CustomerType"
                    className="ml-1"
                    disableClearable
                    value={customerType}
                    options={customerTypes}
                    onChange={(event, item) => {
                      setCustomerType(item);
                    }}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Customer type" />
                    )}
                  />
                  {supplyMode === "BULK" && (
                    <Autocomplete
                      id="deliveryType"
                      className="ml-1"
                      disableClearable
                      value={deliveryType}
                      options={deliveryTypes}
                      onChange={(event, item) => {
                        setDeliveryType(item);
                      }}
                      style={{ width: 150 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Delivery type" />
                      )}
                    />
                  )}


                </React.Fragment>
              ) : (
                ""
              )}
              {aggregations.indexOf(aggregation) > 1 &&

                <Autocomplete
                  id="newExisting"
                  className="ml-1"
                  disableClearable
                  value={newExisting}
                  options={["Existing", "New"]}
                  onChange={(event, item) => {

                    setNewExisting(item);
                  }}
                  style={{ width: 150 }}
                  renderInput={(params) => (
                    <TextField {...params} label="New or Existing" />
                  )}
                />

              }
            </Grid>

          </div>
          <div>
            <Button onClick={applyChanges} color="primary">
              Apply
            </Button>
          </div>
        </Grid>
        <div hidden={hideAlert} className="alert-container">
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        </div>
      </CardContent>
    </Card>
  );
}

export default CustomerFilter;
