import { useState, useEffect, useContext, useRef } from "react";
import { Store } from "../store";
import { serviceCall } from "../services";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import CsvDownloader from "react-csv-downloader";
import "../App.css";
import items from "../reducers/items";
import toast from "react-hot-toast";

function ForecastDataTable() {
  const { state, dispatch } = useContext(Store);
  const [tableRows, setTableRows] = useState([]);

  function createData(date, forecast, forecast_low, forecast_high) {
    return { date, forecast, forecast_low, forecast_high };
  }

  // TODO: make this memo or useffect
  function createTableData(data) {
    const hasUpdates = data.hasOwnProperty("UPDATES");

    setTableRows([
      ...Object.keys(data?.FORECAST)?.map((ts) =>
        createData(
          ts,
          hasUpdates ? data.UPDATES[ts] : data.FORECAST[ts],
          data.FORECAST_LOW_20[ts],
          data.FORECAST_HIGH_80[ts]
        )
      ),
    ]);
  }

  const saveChanges = () => {
    // parse the row data and put it back in shape for api
    const updatesToSend = {};
    tableRows.forEach((row) => {
      updatesToSend[row.date] = parseFloat(row.forecast || 0);
    });
    console.log(tableRows, state.items.item[state.app.selectedId]?.forecast);
    // TODO: how to get newExisting here
    // const ts_id = Number(state.app.selectedId.at(4)) > 1 ? `${state.app.selectedId}|${newExisting}` : state.app.selectedId
    serviceCall(
      "update_forecast",
      "POST",
      {
        TS_ID: state.app.selectedId,
        FORECASTS: { FORECAST: updatesToSend },
      },
      () => {
        toast.success("Forecast changes saved");
        // setTimeout(() => {
        //setHideAlert(true);
        // pull latest data - we should use SWR or some global request but too much effort
        // to refactor Bojans context stuff
        dispatch({
          type: "SET_NEW_EXISTING_CHANGED",
          data: !state.app.newExistingHasChanged,
        });
        // serviceCall(
        //   `tank?id=${encodeURIComponent(state.app.selectedId).replace(
        //     "%7C",
        //     "|"
        //   )}`,
        //   "GET",
        //   null,
        //   (data) => {
        //     dispatch({
        //       type: "SET_ITEM",
        //       selectedId: state.app.selectedId,
        //       data: data,
        //     });

        //     const bestModelValue = Math.min(...Object.values(data.score));
        //     dispatch({
        //       type: "SET_PREDICTIONS_LINE_CHART_DISPLAY",
        //       data: [
        //         Object.keys(data.score).find(
        //           (model) => data.score[model] === bestModelValue
        //         ),
        //       ],
        //     });
        //   }
        // );
        //}, 2000);
      }
    );
  };

  useEffect(() => {
    console.log("using effect innit");
    if (state.app.selectedId && state.items.item[state.app.selectedId]) {
      createTableData(state.items.item[state.app?.selectedId]?.forecast);
      console.log(state.items.item[state.app?.selectedId]?.forecast);
    }
  }, [
    state.app.selectedId,
    state.items.item,
    state.items.list.length,
    state.items.item[state.app?.selectedId],
  ]);

  const updateValueInRow = (event, index, row, field) => {
    // Create a copy of row data without the current row
    let newData = [...tableRows];
    console.log(index, field, row, event.target.value);
    const newRow = { ...row, [field]: event.target.value };
    newData[index] = newRow;
    // Update state
    setTableRows(newData);
    // TODO: update the forecast line optimistically
    // let _itemForecastsCopy = JSON.parse(
    //   JSON.stringify(state.items.item[state.app.selectedId].forecast)
    // );
    // _itemForecastsCopy["UPDATES"] =
  };

  const csvData = () => ({
    columns: [
      {
        id: "date",
        displayName: "Date",
      },
      {
        id: "forecast",
        displayName: "Forecast",
      },
      {
        id: "updates",
        displayName: "Forecast",
      },

      {
        id: "forecast_lower",
        displayName: "Lower forecast confidence level",
      },
      {
        id: "forecast_upper",
        displayName: "Upper forecast confidence level",
      },
    ],

    data: (
      Object.keys(
        state.items.item[state.app?.selectedId]?.forecast?.FORECAST || {}
      ) || []
    ).map((ts) => ({
      date: new Date(parseInt(ts)).toLocaleDateString(),
      forecast: (state.items.item[state.app?.selectedId]?.forecast.FORECAST ||
        {})[ts],
      updates: (state.items.item[state.app?.selectedId]?.forecast.UPDATES ||
        {})[ts],
      forecast_lower: (state.items.item[state.app?.selectedId]?.forecast
        .FORECAST_LOW_20 || {})[ts],
      forecast_upper: (state.items.item[state.app?.selectedId]?.forecast
        .FORECAST_HIGH_80 || {})[ts],
    })),
  });

  return (
    <Card>
      <CardContent>
        <div className="buttons-container">
          <button className="button-style save-button" onClick={saveChanges}>
            Save forecast
          </button>
          <CsvDownloader
            className="button-style"
            filename={`${state.app?.selectedId}`}
            columns={csvData().columns}
            datas={csvData().data}
            text="Download CSV"
          />
        </div>
        {tableRows.length ? (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Forecast</TableCell>
                  <TableCell align="center">
                    Lower forecast confidence level
                  </TableCell>
                  <TableCell align="center">
                    Upper forecast confidence level
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row, index) => (
                  <TableRow key={row.date}>
                    <TableCell>
                      {new Date(parseInt(row.date)).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        onChange={(event) =>
                          updateValueInRow(event, index, row, "forecast")
                        }
                        // defaultValue={row.forecast}
                        value={row.forecast}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        onChange={() => console.log("pass FORECAST_HIGH_80")}
                        value={row.forecast_low}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        onChange={() => console.log("pass FORECAST_HIGH_80")}
                        value={row.forecast_high}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default ForecastDataTable;
