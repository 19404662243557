import { useEffect, useState, useContext } from 'react';
import { Store } from '../store';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chart from "react-google-charts";
import CircularProgress from '@material-ui/core/CircularProgress';
import '../App.css';

function PredictionBarChart() {
  const [chartData, setChartData] = useState([]);
  const [opened, setOpened] = useState(null);
  const { state } = useContext(Store);

  function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  function getColor(value) {
    var hue = ((1 - value) * 120).toString(10);
    return hslToHex(hue, 100, 50);
  }

  function createChart(data, timeout) {
    const scores = data.score;

    let chartData = [];

    Object.keys(scores)?.forEach(name => {
      chartData.push([name, scores[name]]);
    });
    chartData.sort((a, b) => (a[1] > b[1]) ? 1 : -1);
    chartData = chartData.map((cd, i) => [...cd, getColor(i / chartData.length)]);
    chartData.unshift([
      'Element',
      'Score',
      { role: 'style' }
    ]);
    if (!timeout) setChartData(chartData);
    else setTimeout(() => {
      setChartData(chartData);
    }, 1000);
  }

  useEffect(() => {
    if (state.app.lineChartDisplay && state.app.selectedId && state.items.item[state.app.selectedId] && opened !== null && opened !== state.app.drawerOpened) {
      createChart(state.items.item[state.app.selectedId], true);
    }
    setOpened(state.app.drawerOpened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.app.drawerOpened]);

  useEffect(() => {
    if (state.app.selectedId && state.items.item[state.app.selectedId]) {
      createChart(state.items.item[state.app.selectedId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.app.selectedId, state.items.item]);


  return (
    <Card>
      <CardContent>
        <div>
          <div className="inline-flex">
            <Typography color="textSecondary" gutterBottom>
              Best model: {(((state.items || {}).item || {})[state.app.selectedId] || {}).best_models}
            </Typography>
          </div>
        </div>
        <div>
          {chartData.length ?
            <Chart
              width={'104%'}
              chartType="BarChart"
              data={chartData}
              options={{
                chartArea: {
                  top: 40,
                  bottom: 60
                },
                height: 500,
                legend: { position: 'none' },
                hAxis: {
                  textStyle: {
                    fontSize: 8,
                  }
                },
                vAxis: {
                  textStyle: {
                    fontSize: 12,
                  }
                },
                tooltip: {
                  textStyle: {
                    fontSize: 12,
                  }
                },
              }}
            />
            : <div className="loader"><CircularProgress /></div>}

        </div>
      </CardContent>
    </Card>
  )
}

export default PredictionBarChart;