import { useState, useContext } from 'react';
import { Store } from '../store';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import '../App.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  }
}));

function LocationsFilters() {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  // TODO: single dict with the things and a single handler
  const [productGroup, setProductGroup] = useState('');
  const [productType, setProductType] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [deliveryType, setDeliveryType] = useState('');
  const [soldTo, setSoldTo] = useState('');
  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };
  const handleProductGroupChange = (event) => {
    setProductGroup(event.target.value);
  };
  const handleCustomerTypeChange = (event) => {
    setCustomerType(event.target.value);
  };
  const handleDeliveryTypeChange = (event) => {
    setDeliveryType(event.target.value);
  };
  const handleSoldToChange = (event) => {
    setSoldTo(event.target.value);
  };
  const applyChanges = () => {
    dispatch({
      type: 'SET_MAP_FILTERS',
      data: {
        "PRODUCT_TYPE": productType,
        "PRODUCT_GROUP": productGroup,
        "CUSTOMER_TYPE": customerType,
        "DELIVERY_TYPE": deliveryType,
        "SOLDTO_BUSINESS_AREA_NAME": soldTo
      }
    });
  };

  const resetChanges = () => {
    setProductType('');
    setProductGroup('');
    setCustomerType('');
    setDeliveryType('');
    setSoldTo('');
    dispatch({
      type: 'SET_MAP_FILTERS',
      data: {
        "PRODUCT_TYPE": null,
        "PRODUCT_GROUP": null,
        "CUSTOMER_TYPE": null,
        "DELIVERY_TYPE": null,
        "SOLDTO_BUSINESS_AREA_NAME": null
      }
    });
  }

  // TODO: usememo here bro
  const productTypeOptions = [0, 4];
  const productGroupOptions = [...new Set(state.items.list.map(item => item.PRODUCT_GROUP))];
  const customerTypeOptions = [...new Set(state.items.list.map(item => item.CUSTOMER_TYPE))];
  const deliveryTypeOptions = [...new Set(state.items.list.map(item => item.DELIVERY_TYPE))];
  const soldToOptions = [...new Set(state.items.list.map(item => item.SOLDTO_BUSINESS_AREA_NAME))];

  return (
    <Card>
      <CardContent className="m-1">
        <Typography color="textSecondary" gutterBottom>
          Locations filters
        </Typography>
        <Grid container justify="space-between" alignItems="center">
          <div>
            <FormControl className={`${classes.formControl} mr-2`}>
              <InputLabel id="productType-label">Product type</InputLabel>
              <Select
                labelId="productType-label"
                id="productType-options"
                value={productType}
                onChange={handleProductTypeChange}
              >
                {productTypeOptions.map(item => (
                  <MenuItem key={item} value={item}>{item ? 'Plant' : 'Shipto'}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={`${classes.formControl} mr-2`}>
              <InputLabel id="productGroup-label">Product group</InputLabel>
              <Select
                labelId="productGroup-label"
                id="productGroup-options"
                value={productGroup}
                onChange={handleProductGroupChange}
              >
                {productGroupOptions.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={`${classes.formControl} mr-2`}>
              <InputLabel id="customerType-label">Customer type</InputLabel>
              <Select
                labelId="customerType-label"
                id="customerType-options"
                value={customerType}
                onChange={handleCustomerTypeChange}
              >
                {customerTypeOptions.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl className={`${classes.formControl} mr-2`}>
              <InputLabel id="deliveryType-label">Delivery type</InputLabel>
              <Select
                labelId="deliveryType-label"
                id="deliveryType-options"
                value={deliveryType}
                onChange={handleDeliveryTypeChange}
              >
                {deliveryTypeOptions.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl className={`${classes.formControl} mr-2`}>
              <InputLabel id="soldTo-label">SoldTo business</InputLabel>
              <Select
                labelId="soldTo-label"
                id="soldTo-options"
                value={soldTo}
                onChange={handleSoldToChange}
              >
                {soldToOptions.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Button onClick={resetChanges}>Clear filters</Button>
            <Button onClick={applyChanges} color="primary">Apply</Button>
          </div>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default LocationsFilters;