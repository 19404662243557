import { useEffect, useState, useContext } from "react";
import { Store } from "../store";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chart from "react-google-charts";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import LineChartFilters from "./LineChartFilters";
import "../App.css";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

function LineChart() {
  const [chartData, setChartData] = useState([]);
  const [chartColors, setChartColors] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);

  const [opened, setOpened] = useState(null);
  const { state } = useContext(Store);

  function createChart(data, timeout) {
    // if (data === undefined || data.dsDecompose === undefined) return;

    const dsDecompose = data.ds_decompose;
    const forecasts = data.forecast;
    const lineChartDisplay = state.app.lineChartDisplay;
    if (!lineChartDisplay) return;

    let chartData = Object.keys(dsDecompose.QUANTITY_KG).map((ts) => {
      let _chartData = [new Date(parseInt(ts))];

      lineChartDisplay["actual"] && _chartData.push(dsDecompose.QUANTITY_KG[ts]);
      lineChartDisplay["trend"] && _chartData.push(dsDecompose.TREND[ts]);
      lineChartDisplay["forecastHigh"] &&
        _chartData.push(null);
      lineChartDisplay["forecastLow"] &&
        _chartData.push(null);
      lineChartDisplay["forecast"] && _chartData.push(null);
      lineChartDisplay["updates"] && _chartData.push(null);


      return _chartData;
    });

    if (lineChartDisplay["forecast"]) {

      chartData = [
        ...chartData,
        ...Object.keys(forecasts.FORECAST).map((ts) => {
          let _chartData = [new Date(parseInt(ts))];
          lineChartDisplay["actual"] && _chartData.push(null);
          lineChartDisplay["trend"] && _chartData.push(null);
          lineChartDisplay["forecastLow"] &&
            _chartData.push(forecasts.FORECAST_LOW_20[ts]);
          lineChartDisplay["forecastHigh"] &&
            _chartData.push(forecasts.FORECAST_HIGH_80[ts]);
          _chartData.push(forecasts.FORECAST[ts]);
          lineChartDisplay["updates"] && _chartData.push(forecasts?.UPDATES?.[ts]);

          return _chartData;
        }),
      ];
    }

    console.log(chartData)
    const filteredChartDisplay = Object.keys(lineChartDisplay)
      .filter((key) => lineChartDisplay[key])
      .map((name) => {
        if (name === "forecastLow") return "Lower confidence level";
        else if (name === "forecastHigh") return "Upper confidence level";
        else return name.charAt(0).toUpperCase() + name.slice(1);
      });

    let forecastColors = ['gray', 'gray', 'green', 'red'];
    // if (state.app.lineChartDisplay["updates"]) forecastColors.append('red')
    // if (lineChartDisplay["forecast"]) {
    //   forecastColors = [
    //     state.app.lineChartDisplay["updates"] ? "green" : "green",
    //     "red",
    //     "gray",
    //     "gray",
    //   ];
    // }

    // TODO: this is janky i think trend, actual

    const colors = ["blue", "orange"].filter(
      (c, i) => Object.values(lineChartDisplay)[i]
    );
    chartData = [["x", ...filteredChartDisplay], ...chartData];


    setChartColors([...colors, ...forecastColors]);
    let series = {};
    lineChartDisplay["fitted-upper"] &&
      (series[
        filteredChartDisplay.findIndex(
          (fcd) => fcd === "Upper confidence level"
        )
      ] = { lineDashStyle: [10, 2] });
    lineChartDisplay["fitted-lower"] &&
      (series[
        filteredChartDisplay.findIndex(
          (fcd) => fcd === "Lower confidence level"
        )
      ] = { lineDashStyle: [10, 2] });

    lineChartDisplay["forecast"] &&
      (series[filteredChartDisplay.findIndex((fcd) => fcd === "Forecast")] = {
        lineDashStyle: [10, 2],
      });


    setChartSeries(series);
    if (!timeout) setChartData(chartData);
    else
      setTimeout(() => {
        setChartData(chartData);
      }, 1000);
  }



  // useEffect(() => {
  //   if (
  //     state.app.lineChartDisplay &&
  //     state.app.selectedId &&
  //     state.items.item[state.app.selectedId] &&
  //     opened !== null &&
  //     opened !== state.app.drawerOpened
  //   ) {
  //     createChart(state.items.item[state.app.selectedId], true);
  //   }
  //   setOpened(state.app.drawerOpened);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.app.drawerOpened]);

  useEffect(() => {
    if (
      state.app.lineChartDisplay &&
      state.app.selectedId &&
      state.items.item[state.app.selectedId]
    ) {

      createChart({ ...state.items.item[state.app.selectedId] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.app.lineChartDisplay,
    state.app.selectedId,
    state.items.item,
    state.app.lineChartDisplay.updates,
  ]);

  const customer = ((state.items || {}).item || {})[state.app.selectedId];

  const bestModel = customer?.best_models

  const accuracy = customer?.accuracy[bestModel];


  return (
    <Card>
      <CardContent>
        <div>
          <div className="inline-flex">
            <Typography color="textSecondary" gutterBottom>
              Time series history chart
            </Typography>
          </div>

          <div className="d-inline-block ml-2">
            <Tooltip
              title={`Bias: ${((customer || {}).bias || {})[(customer || {}).best_models]
                }, Directional Bias: ${((customer || {}).directional_bias || {})[
                (customer || {}).best_models
                ]
                }`}
            >
              <p className="d-inline-block ml-1">
                Accuracy: <b>{accuracy}%</b>
              </p>
            </Tooltip>
          </div>
          <div className="float-right mt-1 z-index">
            <LineChartFilters />
          </div>
        </div>
        <div className="chart-container">
          {chartData.length ? (
            <Chart
              width="109%"
              height="400px"
              chartType="LineChart"
              data={chartData}
              options={{
                colors: chartColors,
                series: chartSeries,
                chartArea: {
                  top: 40,
                  bottom: 60,
                  left: 60,
                },
                legend: {
                  position: "in",
                  textStyle: {
                    fontSize: 14,
                  },
                },
                explorer: {
                  actions: ["dragToZoom", "rightClickToReset"],
                  axis: "horizontal",
                  keepInBounds: true,
                  maxZoomIn: 4.0,
                },
                hAxis: {
                  format: "d MMM yyyy",
                  slantedText: true,
                  slantedTextAngle: 60,
                  textStyle: {
                    fontSize: 8,
                  },
                },
                vAxis: {
                  title:
                    (
                      ((state.items.item[state.app.selectedId] || {})
                        .gts_info || []) || {}
                    ).SUPPLY_MODE === "BULK"
                      ? "Units in tonnes"
                      : "Units in kilograms",
                  titleTextStyle: {
                    fontSize: 14,
                    italic: false,
                  },
                  textStyle: {
                    fontSize: 12,
                  },
                },
                tooltip: {
                  textStyle: {
                    fontSize: 10,
                  },
                },
              }}
            />
          ) : (
            <div className="loader">
              <CircularProgress />
            </div>
          )}
          <div className="asterix-container">
            <p>
              *Lower/upper confidence level - Historic and future uncertainty
              bands around historic demand and future point forecasts
            </p>
            <p>*Actual - Historic delivered quantities</p>
            <p>
              *Trend - Historic piecewise linear trend on delivered quantities
            </p>
            <p>*Forecast - Future point forecast with best model</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default LineChart;
