import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useEffect, useRef } from "react";

export default function Help() {
  const ref = useRef();

  useEffect(() => {
    ref.current.innerHTML = `<html xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40">
    
    <head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=ProgId content=Word.Document>
    <meta name=Generator content="Microsoft Word 15">
    <meta name=Originator content="Microsoft Word 15">

    <!--[if !mso]>
    <style>
    v\:* {behavior:url(#default#VML);}
    o\:* {behavior:url(#default#VML);}
    w\:* {behavior:url(#default#VML);}
    .shape {behavior:url(#default#VML);}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
     <o:DocumentProperties>
      <o:Author>Faycal Bitous</o:Author>
      <o:LastAuthor>Chris Kalas</o:LastAuthor>
      <o:Revision>3</o:Revision>
      <o:TotalTime>3</o:TotalTime>
      <o:Created>2021-07-20T07:51:00Z</o:Created>
      <o:LastSaved>2021-07-20T07:54:00Z</o:LastSaved>
      <o:Pages>1</o:Pages>
      <o:Words>347</o:Words>
      <o:Characters>1982</o:Characters>
      <o:Lines>16</o:Lines>
      <o:Paragraphs>4</o:Paragraphs>
      <o:CharactersWithSpaces>2325</o:CharactersWithSpaces>
      <o:Version>16.00</o:Version>
     </o:DocumentProperties>
     <o:CustomDocumentProperties>
      <o:ContentTypeId dt:dt="string">0x01010083149206521E1745A88ADB4BF3C7EA73</o:ContentTypeId>
     </o:CustomDocumentProperties>
     <o:OfficeDocumentSettings>
      <o:AllowPNG/>
     </o:OfficeDocumentSettings>
    </xml><![endif]-->

    <!--[if gte mso 9]><xml>
     <w:WordDocument>
      <w:SpellingState>Clean</w:SpellingState>
      <w:GrammarState>Clean</w:GrammarState>
      <w:TrackMoves>false</w:TrackMoves>
      <w:TrackFormatting/>
      <w:HyphenationZone>21</w:HyphenationZone>
      <w:PunctuationKerning/>
      <w:ValidateAgainstSchemas/>
      <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
      <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
      <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
      <w:DoNotPromoteQF/>
      <w:LidThemeOther>NL</w:LidThemeOther>
      <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
      <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
      <w:Compatibility>
       <w:BreakWrappedTables/>
       <w:SnapToGridInCell/>
       <w:WrapTextWithPunct/>
       <w:UseAsianBreakRules/>
       <w:DontGrowAutofit/>
       <w:SplitPgBreakAndParaMark/>
       <w:EnableOpenTypeKerning/>
       <w:DontFlipMirrorIndents/>
       <w:OverrideTableStyleHps/>
      </w:Compatibility>
      <m:mathPr>
       <m:mathFont m:val="Cambria Math"/>
       <m:brkBin m:val="before"/>
       <m:brkBinSub m:val="&#45;-"/>
       <m:smallFrac m:val="off"/>
       <m:dispDef/>
       <m:lMargin m:val="0"/>
       <m:rMargin m:val="0"/>
       <m:defJc m:val="centerGroup"/>
       <m:wrapIndent m:val="1440"/>
       <m:intLim m:val="subSup"/>
       <m:naryLim m:val="undOvr"/>
      </m:mathPr></w:WordDocument>
    </xml><![endif]--><!--[if gte mso 9]><xml>
     <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
      DefSemiHidden="false" DefQFormat="false" DefPriority="99"
      LatentStyleCount="376">
      <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 7"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 8"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 9"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 1"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 2"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 3"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 4"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 5"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 6"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 7"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 8"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 9"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Normal Indent"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="footnote text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="annotation text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="header"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="footer"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index heading"/>
      <w:LsdException Locked="false" Priority="35" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="caption"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="table of figures"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="envelope address"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="envelope return"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="footnote reference"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="annotation reference"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="line number"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="page number"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="endnote reference"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="endnote text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="table of authorities"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="macro"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="toa heading"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 5"/>
      <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Closing"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Signature"/>
      <w:LsdException Locked="false" Priority="1" SemiHidden="true"
       UnhideWhenUsed="true" Name="Default Paragraph Font"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text Indent"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Message Header"/>
      <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Salutation"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Date"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text First Indent"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text First Indent 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Note Heading"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text Indent 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text Indent 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Block Text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Hyperlink"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="FollowedHyperlink"/>
      <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
      <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Document Map"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Plain Text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="E-mail Signature"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Top of Form"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Bottom of Form"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Normal (Web)"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Acronym"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Address"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Cite"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Code"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Definition"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Keyboard"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Preformatted"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Sample"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Typewriter"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Variable"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Normal Table"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="annotation subject"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="No List"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Outline List 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Outline List 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Outline List 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Simple 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Simple 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Simple 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Colorful 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Colorful 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Colorful 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 7"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 8"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 7"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 8"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table 3D effects 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table 3D effects 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table 3D effects 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Contemporary"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Elegant"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Professional"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Subtle 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Subtle 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Web 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Web 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Web 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Balloon Text"/>
      <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Theme"/>
      <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
      <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
      <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
      <w:LsdException Locked="false" Priority="34" QFormat="true"
       Name="List Paragraph"/>
      <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
      <w:LsdException Locked="false" Priority="30" QFormat="true"
       Name="Intense Quote"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
      <w:LsdException Locked="false" Priority="19" QFormat="true"
       Name="Subtle Emphasis"/>
      <w:LsdException Locked="false" Priority="21" QFormat="true"
       Name="Intense Emphasis"/>
      <w:LsdException Locked="false" Priority="31" QFormat="true"
       Name="Subtle Reference"/>
      <w:LsdException Locked="false" Priority="32" QFormat="true"
       Name="Intense Reference"/>
      <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
      <w:LsdException Locked="false" Priority="37" SemiHidden="true"
       UnhideWhenUsed="true" Name="Bibliography"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
      <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
      <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
      <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
      <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
      <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
      <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
      <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
      <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
      <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 1"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 2"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 3"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 4"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 5"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 6"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 6"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 6"/>
      <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
      <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
      <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 1"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 2"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 3"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 4"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 5"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 6"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 6"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Mention"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Smart Hyperlink"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Hashtag"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Unresolved Mention"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Smart Link"/>
     </w:LatentStyles>
    </xml><![endif]-->
    <style>
    <!--
     /* Font Definitions */
     @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;
      mso-font-charset:0;
      mso-generic-font-family:roman;
      mso-font-pitch:variable;
      mso-font-signature:-536870145 1107305727 0 0 415 0;}
    @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-469750017 -1073732485 9 0 511 0;}
    @font-face
      {font-family:LindeRegular;
      panose-1:2 11 6 4 2 2 2 2 2 4;
      mso-font-alt:﷽﷽﷽﷽﷽﷽﷽﷽;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-2147483473 1342185578 0 0 147 0;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
      {mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-parent:"";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:8.0pt;
      margin-left:0cm;
      line-height:107%;
      mso-pagination:widow-orphan;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-fareast-font-family:Calibri;
      mso-fareast-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"LindeRegular";
      mso-bidi-theme-font:minor-bidi;
      mso-ansi-language:NL;
      mso-fareast-language:EN-US;}
    h1
      {mso-style-priority:9;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-link:"Heading 1 Char";
      mso-style-next:Normal;
      margin-top:12.0pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:0cm;
      line-height:107%;
      mso-pagination:widow-orphan lines-together;
      page-break-after:avoid;
      mso-outline-level:1;
      font-size:16.0pt;
      font-family:"Calibri Light",sans-serif;
      mso-ascii-font-family:"Calibri Light";
      mso-ascii-theme-font:major-latin;
      mso-fareast-font-family:"LindeRegular";
      mso-fareast-theme-font:major-fareast;
      mso-hansi-font-family:"Calibri Light";
      mso-hansi-theme-font:major-latin;
      mso-bidi-font-family:"LindeRegular";
      mso-bidi-theme-font:major-bidi;
      color:#2F5496;
      mso-themecolor:accent1;
      mso-themeshade:191;
      mso-font-kerning:0pt;
      mso-ansi-language:NL;
      mso-fareast-language:EN-US;
      font-weight:normal;}
    a:link, span.MsoHyperlink
      {mso-style-noshow:yes;
      mso-style-priority:99;
      color:blue;
      text-decoration:underline;
      text-underline:single;}
    a:visited, span.MsoHyperlinkFollowed
      {mso-style-noshow:yes;
      mso-style-priority:99;
      color:#954F72;
      mso-themecolor:followedhyperlink;
      text-decoration:underline;
      text-underline:single;}
    p
      {mso-style-noshow:yes;
      mso-style-priority:99;
      mso-margin-top-alt:auto;
      margin-right:0cm;
      mso-margin-bottom-alt:auto;
      margin-left:0cm;
      mso-pagination:widow-orphan;
      font-size:12.0pt;
      font-family:"LindeRegular",serif;
      mso-fareast-font-family:"LindeRegular";
      mso-ansi-language:NL;
      mso-fareast-language:NL;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {mso-style-priority:34;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:36.0pt;
      mso-add-space:auto;
      mso-pagination:widow-orphan;
      font-size:12.0pt;
      font-family:"LindeRegular",serif;
      mso-fareast-font-family:"LindeRegular";
      mso-fareast-theme-font:minor-fareast;
      mso-ansi-language:NL;
      mso-fareast-language:NL;}
    p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
      {mso-style-priority:34;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-type:export-only;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:36.0pt;
      mso-add-space:auto;
      mso-pagination:widow-orphan;
      font-size:12.0pt;
      font-family:"LindeRegular",serif;
      mso-fareast-font-family:"LindeRegular";
      mso-fareast-theme-font:minor-fareast;
      mso-ansi-language:NL;
      mso-fareast-language:NL;}
    p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
      {mso-style-priority:34;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-type:export-only;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:36.0pt;
      mso-add-space:auto;
      mso-pagination:widow-orphan;
      font-size:12.0pt;
      font-family:"LindeRegular",serif;
      mso-fareast-font-family:"LindeRegular";
      mso-fareast-theme-font:minor-fareast;
      mso-ansi-language:NL;
      mso-fareast-language:NL;}
    p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
      {mso-style-priority:34;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-type:export-only;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:36.0pt;
      mso-add-space:auto;
      mso-pagination:widow-orphan;
      font-size:12.0pt;
      font-family:"LindeRegular",serif;
      mso-fareast-font-family:"LindeRegular";
      mso-fareast-theme-font:minor-fareast;
      mso-ansi-language:NL;
      mso-fareast-language:NL;}
    span.mwe-math-mathml-inline
      {mso-style-name:mwe-math-mathml-inline;
      mso-style-unhide:no;}
    span.texhtml
      {mso-style-name:texhtml;
      mso-style-unhide:no;}
    span.Heading1Char
      {mso-style-name:"Heading 1 Char";
      mso-style-priority:9;
      mso-style-unhide:no;
      mso-style-locked:yes;
      mso-style-link:"Heading 1";
      mso-ansi-font-size:16.0pt;
      mso-bidi-font-size:16.0pt;
      font-family:"Calibri Light",sans-serif;
      mso-ascii-font-family:"Calibri Light";
      mso-ascii-theme-font:major-latin;
      mso-fareast-font-family:"LindeRegular";
      mso-fareast-theme-font:major-fareast;
      mso-hansi-font-family:"Calibri Light";
      mso-hansi-theme-font:major-latin;
      mso-bidi-font-family:"LindeRegular";
      mso-bidi-theme-font:major-bidi;
      color:#2F5496;
      mso-themecolor:accent1;
      mso-themeshade:191;}
    span.SpellE
      {mso-style-name:"";
      mso-spl-e:yes;}
    span.GramE
      {mso-style-name:"";
      mso-gram-e:yes;}
    .MsoChpDefault
      {mso-style-type:export-only;
      mso-default-props:yes;
      font-size:11.0pt;
      mso-ansi-font-size:11.0pt;
      mso-bidi-font-size:11.0pt;
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-fareast-font-family:Calibri;
      mso-fareast-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"LindeRegular";
      mso-bidi-theme-font:minor-bidi;
      mso-ansi-language:NL;
      mso-fareast-language:EN-US;}
    .MsoPapDefault
      {mso-style-type:export-only;
      margin-bottom:8.0pt;
      line-height:107%;}
    @page WordSection1
      {size:595.3pt 841.9pt;
      margin:70.85pt 70.85pt 70.85pt 70.85pt;
      mso-header-margin:35.4pt;
      mso-footer-margin:35.4pt;
      mso-paper-source:0;}
    div.WordSection1
      {page:WordSection1;}
     /* List Definitions */
     @list l0
      {mso-list-id:1454865757;
      mso-list-type:hybrid;
      mso-list-template-ids:1033690810 743465920 622502954 -1871424410 1690876758 1333816396 213703168 -1349081002 -1979442268 -1211100074;}
    @list l0:level1
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:36.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level2
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:72.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level3
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:108.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level4
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:144.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level5
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:180.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level6
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:216.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level7
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:252.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level8
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:288.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    @list l0:level9
      {mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:324.0pt;
      mso-level-number-position:left;
      text-indent:-18.0pt;
      font-family:"Arial",sans-serif;
      mso-bidi-font-family:"LindeRegular";}
    ol
      {margin-bottom:0cm;}
    ul
      {margin-bottom:0cm;}
    -->
    </style>
    <!--[if gte mso 10]>
    <style>
     /* Style Definitions */
     table.MsoNormalTable
      {mso-style-name:"Table Normal";
      mso-tstyle-rowband-size:0;
      mso-tstyle-colband-size:0;
      mso-style-noshow:yes;
      mso-style-priority:99;
      mso-style-parent:"";
      mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
      mso-para-margin-top:0cm;
      mso-para-margin-right:0cm;
      mso-para-margin-bottom:8.0pt;
      mso-para-margin-left:0cm;
      line-height:107%;
      mso-pagination:widow-orphan;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"LindeRegular";
      mso-bidi-theme-font:minor-bidi;
      mso-ansi-language:NL;
      mso-fareast-language:EN-US;}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
     <o:shapedefaults v:ext="edit" spidmax="1027"/>
    </xml><![endif]--><!--[if gte mso 9]><xml>
     <o:shapelayout v:ext="edit">
      <o:idmap v:ext="edit" data="1"/>
     </o:shapelayout></xml><![endif]-->
    </head>
    
    <body lang=en-DE link=blue vlink="#954F72" style='tab-interval:35.4pt;
    word-wrap:break-word'>
    
    <div class=WordSection1>

    
    <h1 align=center style='text-align:center; background-color:debug'><b><span lang=EN-US
    style='font-size:24.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US;'>Welcome to GRIP, the forecasting tool of Linde Gas
    Benelux.<o:p></o:p></span></b></h1>
    
    <p class=MsoNormal><span lang=EN-US style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>
    <div style='background-color:debug;margin-bottom: 10px; display: flex; justify-content: center;align-items: center>
    

  
      style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular"><a
      href="https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Flindegroup.sharepoint.com%2F%3Af%3A%2Fr%2Fsites%2Fmsteams_2b761e-SOPprocess%2FShared%2520Documents%2FS%2520OP%2520process%2F2021%3Fcsf%3D1%26web%3D1%26e%3DBjIJ2w&data=04%7C01%7Cchris.kalas%40linde.com%7C34752479de2a49ea715b08d95cb48773%7C1562f00709a44fcb936be79246571fc7%7C0%7C0%7C637642752703127709%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=hkG%2Fe1cbf8cD9yp21EYwd8A9MlWn%2FO19%2BpDnSXW65ZE%3D&reserved=0"><span
      lang=EN-US style='mso-ansi-language:EN-US'>Sales &amp; Operations Planning
      Benelux </span></a>

      <a
      href="https://lindegroup.sharepoint.com/sites/LGEMEACPODatabase/SitePages/BLX_Dashboard.aspx"><span
      lang=EN-US style='mso-ansi-language:EN-US'>REW Product Portfolio Management </span></a>

    
    </div>
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US;
    mso-no-proof:yes'><!--[if gte vml 1]><v:shapetype id="_x0000_t75" coordsize="21600,21600"
     o:spt="75" o:preferrelative="t" path="m@4@5l@4@11@9@11@9@5xe" filled="f"
     stroked="f">
     <v:stroke joinstyle="miter"/>
     <v:formulas>
      <v:f eqn="if lineDrawn pixelLineWidth 0"/>
      <v:f eqn="sum @0 1 0"/>
      <v:f eqn="sum 0 0 @1"/>
      <v:f eqn="prod @2 1 2"/>
      <v:f eqn="prod @3 21600 pixelWidth"/>
      <v:f eqn="prod @3 21600 pixelHeight"/>
      <v:f eqn="sum @0 0 1"/>
      <v:f eqn="prod @6 1 2"/>
      <v:f eqn="prod @7 21600 pixelWidth"/>
      <v:f eqn="sum @8 21600 0"/>
      <v:f eqn="prod @7 21600 pixelHeight"/>
      <v:f eqn="sum @10 21600 0"/>
     </v:formulas>
     <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
     <o:lock v:ext="edit" aspectratio="t"/>
    </v:shapetype><v:shape id="Picture_x0020_8" o:spid="_x0000_i1029" type="#_x0000_t75"
     style='width:454pt;height:241pt;visibility:visible;mso-wrap-style:square'
     filled="t" fillcolor="#ededed">
     <v:imagedata src="image005.png" o:title=""/>
    </v:shape><![endif]--><![if !vml]><img width=454 height=241
    src="image006.png" v:shapes="Picture_x0020_8"><![endif]></span><span
    lang=EN-US style='font-family:"LindeRegular",sans-serif;mso-ansi-language:
    EN-US'><o:p></o:p></span></p>

 
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>GRIP provides a demand volume forecast for the rolling
    next 12 months, based on the historical data and the recent volume trend. More
    than 20 algorithms are trained with the h</span><span lang=NL style='font-size:
    16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif'>i</span><span
    lang=EN-US style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>s</span><span lang=NL style='font-size:16.0pt;
    line-height:107%;font-family:"LindeRegular",sans-serif'>t</span><span
    lang=EN-US style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>o</span><span lang=NL style='font-size:16.0pt;
    line-height:107%;font-family:"LindeRegular",sans-serif'>r</span><span
    class=SpellE><span lang=EN-US style='font-size:16.0pt;line-height:107%;
    font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US'>i</span></span><span
    lang=NL style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif'>c</span><span
    lang=EN-US style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>a</span><span lang=NL style='font-size:16.0pt;
    line-height:107%;font-family:"LindeRegular",sans-serif'>l </span><span
    lang=EN-US style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>d</span><span class=SpellE><span lang=NL
    style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif'>ata</span></span><span
    lang=NL style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif'>
    <span class=SpellE>from</span> </span><span lang=EN-US style='font-size:16.0pt;
    line-height:107%;font-family:"LindeRegular",sans-serif;mso-ansi-language:
    EN-US'>the </span><span lang=NL style='font-size:16.0pt;line-height:107%;
    font-family:"LindeRegular",sans-serif'>past 5 <span class=SpellE>years</span></span><span
    lang=EN-US style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>, before putting them in competition based on the
    accuracy criteria. The best performing one is then selected to be displayed.<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>GRIP is brought up by Product Management Benelux and
    has been internally developed by the Linde global digitalization team in Germany.<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>If you have any question or feedback, please contact Product
    Management Benelux.<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-size:24.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-US
    style='font-size:24.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    color:#0070C0;mso-ansi-language:EN-US'>Data Flow and quality:<o:p></o:p></span></b></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US;
    mso-no-proof:yes'><!--[if gte vml 1]><v:shape id="Picture_x0020_51" o:spid="_x0000_i1028"
     type="#_x0000_t75" style='width:218pt;height:227pt;visibility:visible'
     o:gfxdata="UEsDBBQABgAIAAAAIQCxgme2CgEAABMCAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRwU7DMAyG
    70i8Q5QralN2QAit3YGOIyA0HiBK3DaicaI4lO3tSbpNgokh7Rjb3+8vyXK1tSObIJBxWPPbsuIM
    UDltsK/5++apuOeMokQtR4dQ8x0QXzXXV8vNzgOxRCPVfIjRPwhBagArqXQeMHU6F6yM6Rh64aX6
    kD2IRVXdCeUwAsYi5gzeLFvo5OcY2XqbynsTjz1nj/u5vKrmxmY+18WfRICRThDp/WiUjOluYkJ9
    4lUcnMpEzjM0GE83SfzMhtz57fRzwYF7SY8ZjAb2KkN8ljaZCx1IwMK1TpX/Z2RJS4XrOqOgbAOt
    Z+rodC5buy8MMF0a3ibsDaZjupi/tPkGAAD//wMAUEsDBBQABgAIAAAAIQA4/SH/1gAAAJQBAAAL
    AAAAX3JlbHMvLnJlbHOkkMFqwzAMhu+DvYPRfXGawxijTi+j0GvpHsDYimMaW0Yy2fr2M4PBMnrb
    Ub/Q94l/f/hMi1qRJVI2sOt6UJgd+ZiDgffL8ekFlFSbvV0oo4EbChzGx4f9GRdb25HMsYhqlCwG
    5lrLq9biZkxWOiqY22YiTra2kYMu1l1tQD30/bPm3wwYN0x18gb45AdQl1tp5j/sFB2T0FQ7R0nT
    NEV3j6o9feQzro1iOWA14Fm+Q8a1a8+Bvu/d/dMb2JY5uiPbhG/ktn4cqGU/er3pcvwCAAD//wMA
    UEsDBBQABgAIAAAAIQAXNvXIXAIAAOYEAAAOAAAAZHJzL2Uyb0RvYy54bWyklG9v2jAQxt9P2new
    /L4NUBVoRKimok6Tug113Qe4OA6x6n87GwLffmcndO2rTR0SkX2Of36e811Wt0ej2UFiUM5WfHo5
    4Uxa4RpldxX/+XR/seQsRLANaGdlxU8y8Nv1xw+r3pdy5jqnG4mMIDaUva94F6MviyKIThoIl85L
    S4utQwORprgrGoSe6EYXs8lkXvQOG49OyBAouhkW+Trz21aK+L1tg4xMV5y0xfzEis+vrjmrK341
    XVzzYr2CcofgOyVGLfAOKQaUpZNfUBuIwPao3oHySsQ9SqLRqKT/KItG/0EbIeafGAbwee8vhDMe
    oqqVVvGUUz6KsoetElscFIpvhy0y1VT8esqZBUNXTcvJA6MIJZiUl+mtYQ8kTw9OPAdm3V0Hdic/
    BU+3RTVE+88hRNd3EpqQwgQp3lLy9I2OWit/r7ROt5DGo2O68L8XlmtbJeTGib2RNg7VhVKTeWdD
    p3zgDEtpakku8UtDOgVVdiSnHpWNQxUFFI9kI1dUiCij6JKWljSN8QLKl4Vs4I/m5C54SmTdf3UN
    gWEfXa6oY4smcUgjO+YaPqVnPkYeIxMUnC3mS2oJkkVrs+VyQr+cNCjP2z2G+Fk6w9KAXJDSjIfD
    Q0iaSdv5lXSatqyv+HJ5k6lA7Rl+Ed54ykCkXnl+6saKty5lPctJgA2Ejh2Aei44rZpBp1GRWl0r
    Q8ik7SxO23SWzM1KMtJEWSvxR9f0rNZ7fAQ6bzFP3tJiwF19p3HgZ9CZ9LItO3lFzHlOmR1LiBKd
    Xxl7PjXq6zmNX3+e1r8BAAD//wMAUEsDBAoAAAAAAAAAIQBGyZ9VZkQAAGZEAAAUAAAAZHJzL21l
    ZGlhL2ltYWdlMS5wbmeJUE5HDQoaCgAAAA1JSERSAAACOgAAAlEIAwAAAHul7WsAAAABc1JHQgCu
    zhzpAAAABGdBTUEAALGPC/xhBQAAAeNQTFRFAAAAAGCf////EFCP////C1WV////CFCX////DVOT
    ////C1WV////CVKW////DFST////C1GV////ClOW////DFSU////C1KV////ClOW////C1KU////
    C1OV////DFSV////C1OV////////C1SW////DFOV////C1OV////C1KW////////DFOV////C1SV
    ////////C1OW////DFOV////C1OV////C1OV////C1OV////C1OV/////v7/C1OV/////v7+/v7/
    C1OV/////v7+/v7/4u746fL68ff8+Pv9+Pv+C1OVD2/GFXLHF3TIGXXIHnjKH3nKJn3LK4DNLYHN
    NYbPN4fPOYjQPIrRPovRP4zRRI/TSpPUS5PUUpfWU5jWV5rXWpzYYqHaY6HaZ6TbaaXba6bccard
    dq3ed67feK7feq/ff7LhgLPhgbPhg7Thhrbih7fih7fjirnjjrvkj7zkkr3lk77llsDmlsDnmMHn
    ncToo8jppcnqp8rqrM3rsNDstNLtt9Tuutbvu9bvvNfvwdrww9vxx93yyN7yyt/yzOHz0uT00+T0
    1ub11+f12ej23ev34Oz44e344+745O/55/D55/H56PH66/P68Pb78vf89vn99/r9+/3+////wepU
    pQAAAEx0Uk5TAAgIEBAYGCAgKCgwMDg4QEBISFBQWFhgYGhocHB4eICAh4eIj4+Xl5+fp6esr6+3
    t7i/v8fHz8/X19/f5+fo7+/w8Pf3+Pj+/v7+/ppWs3sAAAAJcEhZcwAAFxEAABcRAcom8z8AAEG0
    SURBVHhe7Z2PXxtH3t9B/DDGGGMbG4IxYNcYY2pMjQnGggAF0nCAFcJBQ87H8UCPcCXl2lCupQ9H
    9NCSI+X0PEeOXsPRyN8/tTO7X0kraXZ29oe0s7vzfr0SS9qVWLQfZr4z318VCoUiRHTivwqFTd7e
    xgcKhS2uzz/HRwqFLR7MT1XhQ4XCDq/m5z/ChwqFDSqn5udf42OFwga35wnX8YlCIc4TKp1H+ESh
    EOctlc4oPlEoMlTeKqQGjyDXqHLm59XWjkKj5ta9zievXo3rsiji9auXnZ13b2mnPtBfUls7kafy
    1sPnb3Q1WDP+6lnnx/pDtbUTZapudb7QDBdHPMBPUUSLa7c7X46iBhzyMX6UIkLcyEw67riBH6eI
    CHefuhxtsjzBT1REgJqWF1N43z1gtBI/VhFyrj/0ZJoycBc/WRFmqh46X0qZ8gI/XBFebjzzcJ7K
    MVWwz6wIGy2v8VZ7jtraCTPXHnm1oGKgtnbCy+3neJNLhNraCSeVHwn7ppyitnZCyUclnKmQ8Zv4
    sxQh4m7JR5z5+TfX8IcpwsPNV3h3S8krFXYROq69wJtbUp7iT1OEhqonLvb/3r561VnAi1dMB4ba
    0wkblQ/NgkR5vCWCabmlB5AyuXmrpbPzVU6T4yo0OWy02F5Wjb/svC1otNDcPZ23akMnZNTYczlM
    vXp0184i6S6+b/61cl6FjBY7c9WrB7ZHjqf41ucqUCdc1LzEO2vN1PMWJwtrjNtQhZlCxm3RIWf0
    qcMQreva26fu4VNFOKh6pt1XS948cm7garl7yvcQMm6JLayeubrvdEL8WPkeQkXlI10ZfEY73a2L
    6NL8pfI9hIqbIp7O1y14tmNuK99D2Lgm4HdwN1PpPFFV4EIHlp4wxe1MhXysfA/hg+soH+/0ZgOv
    UpWACyFVnByrJ8pnoOBw3czceaGGCgWfFpRKPq85URQM6tv77+BDRXRgbCa/teNvqG3tHQOAsWp8
    rogMlYXhFqPiUXyx5u4RIhu4TJ5DN76miA7X8r2fL0S3fRs7Bqls4GRndWFhDaZr8XVFdMjGYRHG
    xbaO69v6J6hsUvvrCzrHatiJIloRdY2XAgvy6vu9cSqbi8PNRdQNYRWmG/AERYTA3Kuph/jcnNrH
    w1Q2V8ntZdRMhmPox3MUEaJGi7x4LbCV00N0c7r7HuViZCkNatiJILeIcoSiP3thP4FaKeQABvEk
    RZR48EbMQ94NO6iUIsiw04xnKaKEoKezC/ZQKcXswTCepVAUwZNO4lINOwpTeNJZ2IGxGJ6nUBTQ
    DgeoEwaJC2jH8xSKAlrhCHXCYkMNOwoz+NJZSEEXnqhQ5GMhnQ3lBVWY0AzHqBI2J8oLqmDTBKco
    EjarathRsLGSjvKCKkywlM7qnPKCKlg0Qgo1YsaBGnYULOrgAiVixlIamvBkhSKHtXTIsOONF7Ru
    YExl6IQIAekseuIFjXXMAEwr7YSHWrhChZizA8Ou3RFNIwBHR0o7YQIABWJO4tKtF7S6F+B8bWFB
    aSdMCEhnYdulF7R1EtK7Whyr0k6IEJHOQsrNsFM/BHCSybdQ2gkP02BIvjJjAyacuiNijwEuN/Fz
    CEo7oSEOhQlYLFIw0NXVRLA7cd2JAxzkiVNpJyyISWeV5vkh8f6uJsEyGLX9AKnCJC+lnZAgJp2F
    93t7e6eEtB39tE/C1S5+gAGlnXAwAit4R0VZ3tg7vdL1083zjDYS8/h4Cd+Uh9JOKBiENbyhtljZ
    3NdGoHh3PX5QAdXdxDzOlM0oRGknDDiUjsbq4SVRz0gXQz3NY2Cekqy0EwrcSIewdkjnrpGOfLun
    bgDgjDsRKu0EnwEwm1VE2TgiM9dEV0481NN5tYVHzVDaCTy9YHWTrUlspgziaaSeTqZ5nIfSTtDx
    QjqEjYx4Mp5Oa5R2Ao5H0iHiOaPiact6Oq0h2qnDq1AEj8a4V9IhJvMp3ew5Ed4nWgEwWdkrpIf6
    Js+tzRJh1o5PDZ5OK06hB69DETSoQcvZfCkxmzCmsgODiTbksApQlofFz6AVr0QRLPwdchYWDlWV
    y2AS6/Z1yFlYeA/TykYOItWD/g45CwlVuSeY1A3DhZ9DDk3Qiat6YQGkIQ4poQCvkrGUBrWTHECa
    JuFUIJa9lBxDH16MIkDcn4Gkn2YOwUWGhcI/2oFX77YsJC6hDa9GERy6wLwrRLk4gCG8GkVw6IA5
    G16m0rAKqlBY8LgPsIE30D/OVFXU4NE04/9s5br4gcIHGiZ9t5AXFpZmVb+bwFEXhyTePx9JqrKW
    gaN6GE593s8hrKsa3oEjNggpn/eQCYlz6MALUgSFHrjw12+lsedBXUJFeWmCtL++co2VOWjEC1IE
    hFic16mxbKhI9uDRDSn/TeSFLRizLMqjkItGSK/i7fMRFckePGIjUkxXKpI9eDyWYrpSkeySUdul
    0UrrixJYN6cRQILpSkWyy0Y3TfQuZmxQp4/IagT28fb5yTaMqC0dqYjD4R7hiNYXJVygdPI4l2C6
    WthVziu5qIMrpi6W13Q2iax2PaxI4JyltIrwkopWi27TEqFaQcpFnwTBW4KoYUcuJmRYPAmihh2Z
    aLBuwCgPKyqeXSLa4QjvSxBQ/fYlYtC7UoBlQKXRyENsGqRYeIuics2loQlSeFOCwSpMq6gLOXgs
    QXaMLU5V2IUkDLvu8FBmtmEAL13hK9WQlsE7ZYMllUsjB/fhFG9JYDhV9VGkoFeK4D9bbKtIQSmI
    g8MEma/+3z/gow34kHkowLfwA/m/vffkswSgZiz/qYMrvCF2+ernv36hP7Ilg3d/hm/IPxbveff1
    +S/xIYMTaMfLV/hHm+OAi69+1oYPgi3pfPVzmorC4j3fgnaWCVuqpJcE9MM23g+7EOlo44dN6XwL
    f6T/uJLOYhpUFyzfmRBrb8+ASke/v3ak8+7P+rmupEPWWPfx+hV+0eg84IJI50d9ysrI4N3XFwAf
    /kRbL2Zu/ef/R/uHGDjaWEPfpVtIWel8+c808Pl32mP6/p8Of7Xw+d/1eGicEIvZV/XgfKfLecAF
    kc43SU0RKIPP/6LfcPoaPUhPwunpq58zY4z+jpx0vs69hxzT+MZaOhtqee47Axa9xDlQdXz+dyoA
    XQZkZPnbbxYWVr7TTKCkdt/Ja9og863+D5GX9gYCSod8yF/XFt5p7yGC+eGLhS//ib5uMWEtwwT+
    Agq/aJ0W6grNQhtYNqgmdBlk5ZHUX6P3fkMbRAzzlXa+/kCTjnYuSiwzUlEspLNwpexk36ntJcbu
    gZNSXfqdpqOLJgNy9/HGa0/JEKId1cwhXJATknkn0bMy81f6l5khimIlnRNVh1ICGvoBrkSbQxvQ
    pUMVk9FKFqoKqikimX9PZyh99iJ8/veMInTpZG0g7QH5xA/f/0p7biWdfXiMl6/wk6ZBgEvb2zs4
    vxB5/EcqA/I0C9UDHUeokUz+00cgykZGQ8XSoad8eUze/D0deayks6ECLySheQTgwmYNdpQOucua
    VjJPMxC9/Bc64mjSKpqvmKMOgRrZVF1W0lF2sjy0jgGc0R0ZYTJaIVMWlUHWbMlAJUVVQJfcONbk
    bB6UTnY8yknlW+2AlXSInaw8oLIQa58AOLGRy5cdZsj9Z91tclwbSTL/EnSnuYYuHaKr3ApLe52c
    rn9Y5j0mnEITXrjCf6ofk5V6UtgvkZuhsiPIh9+T+//rY/2mZ+SQkwU1qbUHBPJu+ipZvRPb5ksy
    cJEj3xEb+V1SUyB5/QfUEptjJR2pqO0BmDsQ3ObJSWchqQ0RX2Z2k3Fo+RaPb2RmslyYBoHOY+Q4
    ndYo5BSivcxD/bB2gglHKrZdMurISn12T2ibxyCdz/+vNtC8+wOtzPPj//yN9uLCV/+Cg83/xrkH
    vRI69GT6/l9rPiztLb+lD/+mu7Pe/XeyYvvpH7XHLA6VdKSjga7Ud6SPdN9TleEk5M4wWan73mfP
    AiUdObkfB0jJnZ21pWq2S0r7GMCpBA0hTNmCXrzUoFP1YPQGPgwH1V0TAMcS9KExYT0knoiaR+Pz
    88/wSVio7Z6GOafBPCVnDYbxOoPMtWdT84SpGnweGuqG5a28s/ILiAc97uLGc6obygN8JTx0SZwZ
    unEJMBTkHeWbr1A3hLf4WnjokDmpOLFzBTAQ1ApfNdkRR+MuvhwaWi2D3r/SnQ8/HnIdTgtf/fw3
    viPcEYv7aYC+IEaaVnZqNk6OF3ggNNy3lk4mwIsvjW957igXLB0CzHQHLvzi3igqJsc1PBQWmiwL
    qGCI1tfotDSjNKMOZfkYYLIrUBXibr5GuRh5ggfDQqOodDJxN36wegow1h6YhjUFRk6G0Uo8HhLq
    LHNDM9LJZtL4wXoKIB6QTOKWcdRKIR/hCSGhWlQ67/REKz35SntKY3YyecA0G0uP3/rjuz/QWIrf
    05PIablE49zJxkfCbJK3DN/Bq5aYKvaQQ/kYTwkLAHhvzNCls/KdHtOXL51sHnBOOh/ILaZohpEx
    0Th3suGRHXYuAQZl739+u9g8znETTwoJE1bVuHGF9UFfnOdJ5/OiPGAiIRqC+uXftWfkpFyice5k
    49vssLh3BdAv80q96hmKhM1zPC0kDIJFhk1ucU5vdZ50DFGEWenohhGREDmSl2icO9n4NnssHaQB
    eqRdqd98ixoxIWSOrG6r7WS0dX79F00VedIh/8uazvnS0dRBDqNE6Mu5k41vs8symeymH0u5Uq98
    hAox5yGeGg7uW5WLy6ywNLHkS4cqJD8POCMdMiflCqFQyMu5kw1vs8/qCcCEhCv1GtZeTgGjeG44
    qIdLvCcmZKSjiyNfOkV5wHnSIQrJQl/OnZx75IS1M4Ax2ULeb/LsY2S8M1xbO1Z2Mlc6hXnAhdIp
    sGkyJxsfOWHjHGBEKp/6RwUeKwajD0K2J2hpJ1tIh75OT2BIh/yvyHeRy/m0zP7ksn0J0/gLSEDl
    U5SHOW9a8NwQYWUnZ6RDhEDEoi+dNHMlIx39BIZ08LU8skI0PHKETDGE1mbO69CFXFCs+ljrt/gd
    rrCIJtJrC+++JmYMkY4hD5glHfJ/Q6Jx7mTD2xyzJ0+ZSksz5+PbeGbIaLCwk7PG7gdtuME04J8u
    iHSINDTotMSSTl6ice5k49sck5Km6FeLhZkzGjLflYFp4Ea2o3R+yqymf0vk8NPhkmbrGPKAmdLJ
    SzTOnWxMH3bIIkxLsj5vQYWYMPWoCk8MIX3Ba1xDNSpJbdyPUCImPA9bgFcezXCO9yNAHEmSVdyJ
    EmHzJmQOz0JiE2CjXJMkXMhRfIevnEdh28gpohf28YYEBkkKDXK3c8I+5FDuOO8i4Rdb0I8X7yfc
    EAtXQ05jkwmS+e5iY07b9PnGsQyd1njKGXU15PSQFSgb2XKpewI3Y8nQBYA3Wz1ztyKPwykb6bof
    NAVtxlqFOF66f3As5CmXm4DmLTePpSuSF7QZa8//0juc/Rx3kxXB3De0I13NoW7H7UH94dT3P767
    KBMGr12HkfbCDv6ihcgw3OZTOx2orZ1EGnwOMb1l7rd66n4zZ9j8blz5/ZsXEaxhx/eAixtmWXqu
    zRxKNae7+LF0rZ6CNewc+Bxwcc0082Hci/CKZk4yt0SxJhkCNeyk/PVCVL5BoRQx7km1SV7wnYRV
    8oI07PgdcPEChVLE2+t4hjuGwbx/kP9mXjEBGna2/A24eIBCKeKNNwEWsZk0p5K+hP16pBl2ltes
    aqoeQQdetB/cNFtcfexRTFcTpPAXZXEgYQ37bkji1fnKFjprCJe4+368p7OzpnMBPpYXrDIzkV96
    FQ3YBQf4XbCQsf97u2WRpnKwPAeDcZSOKX4GXLxEpRTyyrPYHH52kzyRtVlqJ63qFpSFU8NOey0G
    Gtzv0ukeRHycr8w8V17NVoRJ4HacSvk55jLpk8JM3oYxqYsG3kKlFPLWuxDkBq6pQ40dCeJNjDRB
    WoJuEctpufvi15hkXI17GLzeblFTVo4otxyxEdjFS/OTU+jDC5ITk2JvU172nem3aL0gWxPvLkhJ
    0JVvC8akLpB8D6VSwJSnyZ0TYDH8+7rALKL+UxkCdpZmQeoipWbTlaeFCKxLysrVTncQDvG6/ORE
    8unKJBbZ21LaViUAJAv3ug+Xgu2rS8me5NPVbZRKAa/wsEeYh3llkCncq/oTGZpibcGM1GWRq9jT
    1ajHpSXj1v4giWLbe2TYR17/heRtzdkJEFMe5+nxwrwyyBPu1Qhp/5s4rs5J3praZDPQ6+Z6zQI7
    szuyhHvFhiWodLH8meSdqSvZXk/Pu8Fa1iImSBPu1Q4Xvm/pLJ7L3l2YHaTzxvN6BLwwrwyyhHvV
    ToPvnfITZzAsXyFkI5VMG9nTXWSNauCFeWU4lcPY6fc/TCdxAnHJ2+yxC7F34lHvsO5sR9mTwi5s
    hiu/t3QWT2GiHi9HUmqYkYEfe18+hx/mlUGKcK/YGGzj9fjF8jnEJVdOxRMUSx7eT1cCzV40pAj3
    6rYIDik9q5/BsOxNYW8wBx3vp6uK2DQ/zCuDz9lElAbwO5Z9fQ4G5LaQCczsmRJMV5ZhXhkkCPca
    EppaS8g2SFe6oZgbKJY8SjFdVbQL3o8N38O92uDS3y2dffA1L0YQZiS7962na9sGQdCZuAww0uWn
    geh3JPvSCczI7bfSYA464x57PTXdACQF/5R30+RkH9XjcyT71iyMSZfJyIAZVeqp76q6tZ/q5nRb
    fJ8ksZn0UT13IO3jlg4ZcqBX6uwHhLmn42W7+4Y+u7rRQfUMlX9n2d9IdjrkBKBrOYG5p+Ndj6IG
    OuDY142Orp7hcovHz0j2wAw5FRWVrCpMnjW714Rz5CaNSevgXV7x2Itk394R2qoSJDhDjonL/BYe
    dAkVTvrQbf5b2cVjJ5L9/RnA1Z5n4lkJzJBDYMXpvMBj7tCEc+CFubm4c1lO8UwLl9lePACIk6Xj
    1Z5HVvWahMUazGBWKvViNzD22CvhUBJUPANlcui0gmBw4Ca5qMexiiYiHo9+1ffyFTUz5WNUixEv
    ciAaRwA8Ew4lsXMFk2346SVGTDvLxKAd0vcOPBPPsnTFf025jmrJg7W8irW320hTqO4BOPM6aZKu
    PfBWCVHd2uXUbBDQTmJ3DiZyG75UPHC86XZhFiDpsGqiMJdX7eSbGeluxGcW3BmDdCk2RrauYFrQ
    sVPfPkSu2LEnzFI7a+eFBq0mnnRyE09wRoCkwzKSWYNO7SdwQvfnJnrvW/4lV/eSIac0OSiLR0ID
    z51urfrV6azz2A2+duh1jBR9dl37MPmpV0cuApqXJKvVYM5NVIsR5kayVsRz/ZD2yIXBdu7NK9WQ
    o7N+CTPcgIza1r5p7QZuLi7suDA6edrZmiWjHzOUpq6D2HhwebRjHbzPBgA/SXZYaXss71VDpjrR
    ys4ZVc9It+mfM5nZTkpZyYj+wZvGsdR30GkKzg90OyuRchH3Y6qdFfIVDJgbfvVdVD3kIpK7a/ZN
    n6BIh5UHMc4adAYNOx2LW8kr8s1M9LWypq5uKLn7Z2MWBlg/urlHm6ZOdnLKXXeT5s/WTmIfYMyi
    YEl9Ww81fAjnp6f7e3t7G2tra2Lrr6BIh1Wg4CkeM9JcGPe0fkCsRGJ2FE5dsT6Yc2cnirB6WRSz
    W9vaT6epy6OCNc6xm0RSlnbWyZzdLbRwa2jtHqTXlGFWaA5Lg/QxpRqsqiiMHPNYnBGhtbJzSr+Q
    eLfB41I9KPgFuWQ5BXFD+aaGDmqewvl+8XbAytyMizpPRdpZOiZ/L4LLTI36piZaYHRgcHBYLLb2
    QrY2hGwqGeEWb/CYkQ6T33pxU5u6pvta9TGgbgQuyhMKvngKk7q1FWvuGaO6Od5mG1gHrtJuC7Sz
    MwsTTqM+Y2NCcYcBkQ6r/hsjDYJb5/49Tl0d9RUNY5Dycv+YRyJJAzBr23Ca2jC1RxOfucokNWqH
    ejr7nZtO7ULRzgGRDmu+YpQq7bMoObq8c0LVE5+EEy/DDywg1qo2TaX2+QPdFsTdWA9Z7VBPp6tw
    CDLtWxWlIpyDjf1y/2CsrxjuK7IwtxxLFjePLgGO7K9FXbANkD4WCCFzs0AnoHY2dE+nG4oWGywk
    7K7CgBXOzqg4OSzyx0J4X+5Cn6ti2yarMO3K4061Y/B0ukCkWk8wpMMI8poq3tRplaDGjDuSLlPh
    iHbA6Ol0TLPA+B0M6bxCvRgojvGKfSJFaw03LKVdFmIm2ul1NXBlGLDOYDyBAISXspbmxT1hu+EE
    f6ngsus2fqrBoxrgAmajXEWjTWAVDyxaX9V/Oudz0r4HJC7KdD/uaA2GWKDnot8yMzAQ0mEk0RTv
    B/b7nbTvCRswVpbtfaPfoQDdxm6YsSqfEQjpMEJLi/LM70hRq9w9p/AYf6NSUg+XejvFIs4AQ2Mt
    O9AeZs6UmBqUi5HCHJqY6MJcdsgCvQybtOaFfbPlfGunLcpwylELjw8jFaJoad7ue2ErrzgoR9WV
    DtN9m7WsJ82qVlgQpMOISi5cmtdOClSqDQZLn5Vh0dtvuo+xmI0brbbwggZBOoxKXoVLcxkqTntF
    sgzDzgiYRmRfQmZjiIzkvC91tyxWmTsYAe2FmXv18bI6NEvJfhl6u8QgjT+tmNxOX2yMaz9uyV8F
    rgrlYqDYC1EXh1QotHME06Wfr3iFEg3FEfml5jyWTimq+jE2BBn5V7XDkCpljHp5SCRh0k5on0Na
    OVUONg2K4C5bvZXO3flH+MhDGL5PVlRy9TBcBF07iROYKEc30W6OJFYNvhBuFfhNLzs10qLGz1lp
    Cq5ghHkVO7AIsQG4DLYrYvEM4mWJvBvgNCNJwDSeRRjmbNF7WeqiSrNnX3tcFZK1l8yeF2P9MBtk
    7SymYKQ8MZtjwNl5N0b/8bygHkqnEkMj3npr8DDc5oxYHZ1emPO9t49jli9gWCj3xTXVcIk/k0XS
    GCLdb24VeSidrJNy3NNm44wKF+ZF4LphLqgxOyv/DgbLo5yKO9zwlLz9GrMmAomNg3PPpPMR3lcK
    0xZxCGOBxWm41yVaKls2VmfL12aBX4k+v/58L8Pb9X5Py8kWLeVhxa28ecXDfh8t+JEGeMu4Ngik
    H3RtFnrLlkzZw/3zWoYRPI9SmJ+0snNM64jA0GOvwkuvFeQseKedh/iJBriDWjanJEisz5Vza3aY
    H4pzldeUshvO8PWFpc0jrYDISE+zd1NrZdEyyLPGDYw4L74tdWfGRllPOdj6RVldidPAdfidgXFX
    kgw71HxMrB+kqGwm+tq8XQUyKvB71QGY8dHX8ZAJdyYtMvlkY6u8rV3q4Rx/MJuCGK52SL3f1bL2
    YaDD8w1LxqTimXYY2RBVeMiMhkk4CZAjfQ/KG6pp1cC9oHl7TMuUh+HHpfCtsSqYEF56srFc7Dcf
    xyPmNATJkb4PMxZFcDymy6Ku0PuCVfd9iAsU13PEdVYBfoon2ineERSo7R8gR3pZXOV5mMd56eSi
    vUqN7n9g8hJPcQEj5EKkQntQHOllcpXnwYnz0slFe5WYKlYtbMS9vcPYTBb60GA40svlKjfCi/PS
    KV9eZxWzraKOa+0wNpPF1v1BcKSXzVVupCG3UWPCvruKG3aoZJW/Qdzu7zCkI7jdKL8jvXyuciNt
    lttenkbiWMFscqbjcl/ZuXSsHOkb8OEf9Edf/QzfaP/87Zf6C6JYvCP3E5iUz1WeBy/OS8cY7VV6
    mDs7Ou60w5COeLNPriO9SDrfgvZPjndfn/O1VPSOfPjSsesqv+NRsuWAZd5RIm2I9nKD2CW3MKpR
    IK786AzpMIoymdEFYFrh1nrU+RbSfOm4GXVWbLrKO4Bdg9k2E2C5bZFyWasFeQzQL/Ir3jbVzpSb
    TnkMx7kN6VS0m2+6F0mnCEvpWMCVziH02VBOrBcg7YllVMuN89JJggeblMTWJJdcWDaayQ2zncH5
    cQunEw+GdOw0im0yX074LJ19Ox7P6iFIb65cwIT7PaB2gTJEux5EgNQNw9XG6gWMiQxg10z3Bt9a
    eZ3MYUjHziDGiWoqkM4GkD8SqpSvLwB+OvzVwud/13w38AM95ct/Jo/Of5c5+8u/kNe/Ji/p2nr3
    9Y/08H/7YuHdb+mJPx1+QV/mSic/oopPQxwu35MV2Smto+ucpvZeWkrVuufjOjlrpK+jyYV+Gsa0
    ytRLZzAtMoLVmG4OvnbsknApnT5hWycjnST5l/BNnnSoSih/1M/+X/TxN1mxUSFpfEMPamg2EFc6
    yzCG12hJ8yRujScOwWE7AFQNIXUo4BnW69u70M/9GTjTAuIT5PsUGV7NNwef4xm2cTlhxc033emd
    z0InLG1+IoL54YuFL/+J3vPshEUE8de1hXffaedRdZBn/4EMLPoJ5C0ffk+Hnu/Jqd/9nrxOlEZF
    xpXOwpXodn87wHHGst0RtDyN1GvNrwgpOx2MVrcOMvoR7UuXgyxPkhmF7oGQVVdpqh2nS3R3ZnI1
    XOEvUAxbOtoIhGSlk4S/0hno3Z/pv1RH2nyUOSFZrBD9DXzpnIgVZ4/1gHHS3ZiFYTvGcmO31rjI
    lmpyZPQz1ttsQ7CxvrymP5tpGBL4K8lk1BRjZ6ww4E46vNj/ggkLhaDLQycjHTKs0EGEvkUXl/4M
    T8geNaC/ky8dMTu5ehDSeZHEopYnIdbcS2Ntrgqb4NhE75Aw3Y/9NSypHSY2Pb5XY/Uyr8eKGabO
    0ClnyyxGdwgb+0QdHJuQLR365MP3v9Jez0jnq5/xTO0Bnk3RTjA8p3x5rJk71tIRspPr43BVUCBc
    0PLE5knnB14UHlrZ1aJLhzsEinlTm77AAbSUgmmBIdZ0nfXGkansxhHBD00xkQ659+RL+p6OPAzp
    kBOLpWMUCLGHNKylI2InN03CeZH/X8TybOidIRdxtmMRXmGDpW36vcCAlQSaZxitWxLkvQIuVdPI
    L5E4myIYPT8N0qlh9BgxEudkyJpJh/yNkftP11UM6ZAHXOl8C/An8meuv5MvHQE7uQ3Y0Y67FpZn
    K20OebzFyQ52RGLzKA0Q7+BtancYDGQj+yCyV3TTbF9Z3PmU4xq+1wCmYd16+OLtx/wNo2q4wCtn
    YC4d+pAey7xArBldLCiVfOkYbR3yWNsGEpKOpZ2cbyAb4VmetY+JgXO1X5pgpcXtC2L29JrNW9RA
    NjER6CVbO1JMYpUdeSQY0nlWcb3l6Wv66K3FoFMPp3jhDHjS0UcSXUCEwhVWnnTI0ez7UGTkRAHp
    WBQerh4A2MZTizC1PJvIzYPzrRJG9a/TxlCDTGtL2/TG04p4fwVxa1OJsSzSGHVQBQPfamA0MyNa
    KaeiibfpbiKd74iN/E5XA1m+/6CttsgDYvx8+efMvk6+dMgLf/sd3df5ly+ouNa0E/FAdrXGYI87
    /deNwBXHwmVanrFWuhJPemEY81g+uCLr9eJ5Cze9zVhOwaR1+CGj6KgGo4mVFfhOBpbKqWjmJWSx
    pUPGDQ1tDtJ3lskxYsJo0FeLpLOwgVvI5HXcLErqkxh9f+bcYnZ5xi41kLk2LsPybC3hTJVPYpus
    1ycLGqhnN73NWBQyllltySn2dwYZXdR0xq2LsbTy8vJNRh3NCUVHEcK7/05E8dM/kke/1nxYv6Ev
    FkuH2NXkvA/Uh7Xw279ovq6v/kKPvPvDBUc6WxynQtsMnFrFRhRYnneGSzxT5bNGdDBmnHHpprfV
    Tz8A6MHTzWFUrKVMMdoE8zFb6wsoh/w2MqefG6v2FVANIOBq2prLBYs1DAJcmppGJWHtDGA4MwMV
    bHqbsT1n3UXLbFvZ3u5Ozd1HJsu1KZECUF1SV73IFUYvZliofX/W8qwjxvHVXtlGnAwbZLk1qFnr
    RZveJrwXaTRotq3MKiHJhC6+8T3FTAmVf+qWutjOKqe2kUgbU4JueVZ3z5A/ea93cYTYuQToq2Nt
    erMRa296zWRrUMCZdb3libb4NkVMORW9nHKL/pNfyKaAXkjiaVwWyUq5d5JYGeUwjlks7qVhhlxB
    8aY3i20YEXKjMtqCUARW6NeemEYc6gj6Uvul7hyxCHG8Tgbclu1GiOUJp+Xug2tk6ZBcgViKv3gr
    d5MUG5F04qoH5pOVuO980CpF1l+MZWaL6BDttbOZ9Lt84krSOvBQ44Bj3eVTXLBJR+zO3zOds4Sj
    LgbBr3FcCAC8ThaxeECrIpqzMjcjGC5CbBb28kh0U/kme4Uv7goblLvVEW/CovuZAn3qA8WxnehY
    E4+EcLjptafF4rOxq9gntXSW+NKpGBAIQA8S6zBmJ5HMJBtdvLJy1UMXBS57XY75WZd5SVi2KDxM
    FuhSz7c2SYgtzLNUsq3dt3Y2BluMJpPwthDlsbnrWQiWdCyziYWxLHdu2bk1UOzYzWQ3Cd6x18Hm
    VtbosVd0pculI4IlnYzfyj3rVgVvaz+R21azxdJn1i6IAhg9iQhCfgQD155pErRZrqfVpbVQWuls
    WboCw9MM11k7XPYy6TUeFaamc9R2ccJWl1VwSyudbcsI49C04KZeFwdNuGvYYRM2UmKQynt2A+Ob
    nBsLWvrwf9WkY0wGJmLS+CH/ZUfsWVdLDkvjf6et/xlJDYRRRwkS9mjkBZjyePdnXSGZsEANmgxs
    kI7xZUccCJRL7heJZAgAGzAm5LwqhB0zaD/qyzZ1Tm2FJMB32YjSvGRgw4SV97IDREqR1H86J3tB
    RBESFw6rirNX6FNet+tjMG1dhojFBsaF5tk6GNteaOvgyw5IGdrdmdItUNNEfnYdl5hj+9BtbdE4
    Y8jZ6jYjhzzpoGYKpePYbE4IVbaOfWJRIDsILKWdlwljr7K87fTIwrrkIguiGH0SykjHkAxslIrx
    ZfsU1tM3oQ3OA+/KOnJREpXtB/WgmLsFrY6WWNnBBh8Yk4EN0sl72T4FXTxMCf4CnSzMBSsdsHiE
    asnHYfULcep56Z+mFIw6ecnAOenkv2yfI8GCfY1isaYSk3LVs6mSubkj0CjEJQLlOoshS3Mt/VeX
    DvmfIRk4K5WCl+1zLjr/9wWuNVw+mxB3tDDPwCh2Qij5sDPoKDo5U2wpIx06a2EyMJGK4VjuZdss
    cmMEjdTOCMaaykniUjSq1AxmzF/Jh51uRw7Qr36GD7//AivA5ScD04W7lk1c8LJt1mAIr9GSLsuu
    DjKzL7Yc4HAD1ZJPqYcdq45zJmRTgenIkp8MTIYkSmGOsG1ErWRCbCzAC/SlOff1u5lB7qUedhod
    2clk3a0lCv8nfVLKSwbOZRPnv2yXIxu2Y5BjTW1FlZrAdoOWdthpkLeD7Jadjp9DgV2gr8Kki4V5
    ho9QLXmUdNhp/LfsElNSsAP5nX051H0SWOm8B0/6sjFza0o47NyZkbprNRl3RGqYEltnKMCOrF2Y
    9KCzBaMuYCmHnfufSp5SsDEnZgf0woUjL64cHMOInUQIE5iurFJ5slpB6hIplPU5q/BkSjukpU5i
    tSBx7iC0tAjmAt1RYVNrOiAA2ZOrs9Z1+5s+DbjvfHlWcGLmwhp2ShO30wVzQci7Xb20aqhGTOSg
    Z/Ktg9v9ZAJz2HHbWZZFL6Rlro+SY/mCXxM20CZyBmIqC0S1WcAadkoQpdwLs0HJYFpOcZvHBttE
    zpD0wFRmDjuumoMyiA3AVXAchospTrPzgJvIGRIpD0xl1rDj8fq8egAugvR9L56Y1qJuDLqJnIGY
    ym5idjSYw46bvrJFVA/DRbDS/BMnMMl0EcaGQ5JLQwMFpl2bO6xmazbzgbnUDUMqcNbBEUyyErK7
    QhCXnOHIdewFMztiyjtDuS4OZwG0Kw9hptjV0/BpiMoVLF4Ku+xMYSVleWYoN3wCJ4H8Q92DT4sc
    6UMBjyzNZ9O9D51V+sJ28QITGmR2lfPZKQrCaIfLEKzLcxy7XmVVsRJrXDTRNyC5q5xPoSO9bjok
    q6sMS7OCOSDmsMIF7ZVqMqH500AvSDbn8qpBDIoV3Q4Q2/aKCTJgrc9H8ZgbAuAq55PnSG+Fq5DU
    R8lxKhIowIW1PhcvTGlGO6dpXUB4P5vt6Vk7GbqyyQsLK2nbReEKYOVkud7a6QpCkIUVq7MZR3qf
    09JAUrPrMpuvopLRAWLc5dZOT1Bc5XwyjvTmcBW+zWC3AG4xrB597masXpgNg3KIds41R3p4PBD5
    rDqs7pWFFaTsasZqC5KrnM9iitiSzSG0kXWOHdUUNPAG9WLA1RpLsPNYIKBjengqlxbirmAK4SHq
    xYgr93lPaDZBtmE4Fr7OIjncZoPWoFyMuKoPVzsNfrYT845F2m0svIOOB8MOY2vH3a5gWAqb06Lm
    YR503A87rG5Hrmas2EjgNwQpq3PT9aEedNwPO6wZy10h5XD8qdI/yXAPOu6HHUYbdJeRF2EobK4V
    NQ/3oON+2GGVvajCY85o+DTwyQOJc2gjg04q1IOO62HnGsrFyD085pDgdx7Tuo0NhyxMpxi3ww6j
    tqBLF2h10AubL6WhsaIBLvFpeDl2l1nD2BV8i4ecIlbYfO3g/LK8EltJXR5tClyZVtS8J+BBRyJs
    WDTMteA66sWI23oplgbm4lbySiseWc6F/Nqs9iNPdix84do4HpuEkHqvjFy6i9thZEaIdzBn0wSz
    nO99ZeeM3sKR7qYOKGP1iM056GvoGKI/+vyAt+V9RmOTnbUmCBp77sIFGX2sXZfa6TdNP1k/uKA3
    b7Bdy0G8PwNHZVrG7IC+nqht7ZsmF3B1tGmS5rCpxSMMhd5IpizDtJsoZcaG8jgecgy7sPnSVjJN
    7tpE7/3s9TZNwmlZMlUOwRDcdKc7TvV7usPYRdCLmteHfDsww4mrfD7W8vwaHnNMcWHzlV2cphrx
    FJ2GOKRKH4eXOIbp/ASS+nacugrzOvWi5t2Br8MkxqZ4rXoWDGPHfi/ZAgoKm28catPUQHtxFZK6
    YbgodXjY4hlM5CuWUt3aN0Eu6iq5ZRj39KLmsU8gjHGlxZAx1k0Nd4ax475uQc4BtLR1TKepsd77
    7JDG6gGYLW0g/PqlafWTpu4Rqumz7NSl77DeD2UwO4sDVzvKjMIFHlTaGdL2RVZ3U/TWjDwu/qPP
    EushZkfpfBeLRwBDnF3T+vZBeokXBzSoek1PyB6ATf3NoWfFVZByFerFiDs3FqUB0puHl/Sm9LdZ
    1Xq+MwbpXfxdvIYMOdNFe6bNXXn8q389TFdd6eRWCv4Nef44IkYy5Qx69C/BDG7qBMPY8aB2OxlL
    6DTVLCLq6l4yaZRi4NGGnKLJKjZDr41HOPMgWGzib2wOb9fwOerFgAfNz2vjw4/FTTAy8Mx5P/Aw
    hxw6Il7sMfgf3//44Uh7FIZoNVE2td/YlFNuxhajXEqJ6m9z0AYeb5daS8whh9AWqoI5pWWT26X4
    FurFwBs8VE7IwAPH3oln6WCOOeQQnPXOjiar3K76lagXI97XULamupsYqx6JhwoH+k2W5AOOOpFG
    kwS/Nyqjy1Hp2+azqKXiOXVfuG9ZE46ppTXmpP9xVEmByR+gBiP33PV+skO8EM8ysXE4wqmojUAU
    l3ckuTXAGAHKpWgYIYYuHqNzwB4bFsIhRlUI2j2Uj11udjqjbMFLPOQHmnjg2Il6No5oCBlXODTN
    MDrbNu7Z4FatZOwne1EZzjm17cNEAXbVo+tmpIs3N1N6Qlioq3Qswwh+b0wYTYjduyLcUYfqEY24
    WtV0E++20g1hmBVMpDDjCnjhYIzUc3+WWHno6hErf5CYpboR272ehsh4qLzgDDi+a1Yd3NI2Phel
    rl8wMWEN4mK6oRGA5/gmhQiH3EhCxhLLbWi7RzQLRs7sicedNEciYt07drhfLWOJ5d/qPI9qSONv
    wOdEvK1lRwQyrLxkDQbwm2PBWGKV3wHKRtCmneXacnn0RyLZwTsWYQK/OSbFS6zStcy3R4+Qq3KV
    v4LMYwRKF5IYSi6Bl5pe7MVynVDjEWK5dDviuWgxwSlQkeEEinuE5WCszv3e2EHq4Ap/Ax7HhZ2I
    zGkIScm68nHAjfZiZEVIsLGjEReZXy7BKv45S6uK87LJJndI70S9GPC0h6wLegXcBss2SjaoOC+7
    8KO9GBs7foVdFNIu0JZty0ZjORXnZRd+tBcjF8vrhvlOaYAL/BXMOeLOxvmMRaH6ibec86K9GG3V
    PEiK8IYJ63t9Lp7+Wq3ivGyT5O23Morguqra7iXWW3gWm1Z53Alg+3W/OeJGe6FeDHjZL98VHZaR
    WRvcrfJ8mgEgGZW0YC94f3iFpYlMQL0YeIVHfKfJciPmIL9HMJ82Wh3l6jAcrSxKzfIeLVES7+J5
    eeT1RFTEpq0SGFL2SuLVddHKTBd7PtZBefeHH8klnP9Oe/L5P/9MnsCHP/1Ge0pMix/I/7/SXvzp
    /Hdf6K+Wn8VtWhJpoocXrkMozjt3W8fUOwYtltNk8Wi3WkNjDy2tc7btk9nznzVZEL6nuvj87/gM
    /qrJxCgdwoff0xfLzmaS/vA+65boxU3V5JFOl0WUxJpWiMsu9/voV+OL2fMdwN/ICPPuO0j/kjwl
    0vkj+efXx6D9m5PONwsLK18TBZF/y4xm4MBQm0g4QnGvCFn8n9bhXjbCvPKo9sns+RYlsrDw9T/S
    /6N0qGY0KRmlox2lT8tIxsAR9O0w2ozgEf+phjQ3mPhUPMyrED/MniItZKWzAR/+gfyTLx2iNE1Q
    ZULQwDHAKJSCRyRgGHjpoIm0eJgXg7KbPd/qAslhkA5j1CmrdIQNHAMM1zkekQC+x5LvnROhvGZP
    Eq3hLIYJSztSIJ2i80uFHQPHgNTSuc8N99qBHjzPOWU0e979OWPpZEDpUDNZE4svto5NA8cAQzqu
    iyd7Bj/cy0aYFw/d7DkreYpWdozJQl7RwWW4UTrvyAqrYH4rCYfkx9sxcAxILZ2KOC+2/Uo8zMsC
    ava4r9BiQVY6ZPgBzYzJSCczL2Wlo/NBn7dKyrJdA8eA3NLp5bSwWXHXmSefwdJLJzthGaVDXslZ
    z3nS+fGwHIaOnVi5QuSWTisn3GvLXXeVfAbKIp2s7aIvqXTpkP/jsFNgJpeD8EqHF+5lJ8zLkt4y
    1No2LLaN0iFPcDhS0vGSCfOeDReuuhwUIBII7RaiicywkycdIhl9ygq+dGrwkAz0m97SZRthXtaU
    QzpEGoAezXzpkH81TQVfOnhECswLcfGLTtmlLNKh9jENsHj325/zpEOmMk0tPkjHzbaq5NJpNA33
    OnDXNbeA8hT80tZWOn8ilnFWOuR1KiUfpOMs+EBHah+WFu6FhecLuXDX+bSArrJIh+4ck6X3h/PD
    X9EnWemgGRQw6cjsOadojYeY2A7z4tEV0fy+MEunHlvsFOPloGMZVRZW3EjnNeolh781TH1CScc+
    Mscml5GoSmfdRjpSIcXSkSaZppzwPB5hZttF4Mo4CiaHn/XafaMpMv1i89l3vsXB6GskTfZnOakT
    qI0QRvg9RLgw+uRLUsK0zADglxkt+GW1uTAa8UlT6aKsxCPSG7+AC26lSS73UC8GZKmvU17KEOsl
    I/xWe1wYVb3u4aFoURb/p3RYdJ/hInEtwfISzY0dfkF2PpJHepWPaG7sbLnY1mHUTcYjESOaGzt7
    LiJXigtdRNKFFdWNnSPn2zqMol6v8UjUiOTGzqnzbZ3rqBcDsnSmKTcjUezuyG8gwoVRNjmam8kV
    FX0RXJ27CWp/gHox8BAPRQ2R4vBhw00W5FPUiwE5en+WH5Hi8GGD33qGDyO89DoeihyW9VLDR8pF
    FmRx6dspPBI9BiPX33HRhQeL0fpTmqrJZafLsjh82Fh3EZh8G/Vi4Dkeih7NcIZfaVTYs1PsvoCH
    qBcDUV1gWddLDR8uKsBWvEC9GIjqAovAr5caQmw0+i6iOB0iugssGrKzi99pNHBTqoBhJUd3gSXa
    Hjs82Gj0XQTDSo7uAotuCl5FythxUwGWYSVH1YOlEY9UY9lE2rnvk2Uly9Ju2BceR8qNteViV4eR
    +SlNf3xfaIzU8vwE2vD3tg+j27A8DY18YaQMdUxlYXHOxXzFiLiIZKmCHF2QxC82/LiarximziM8
    FFHqIzRjnbqYr1imToT3kjWiM2MtwbTzrWSGqSNVyWQ/iM6Mte0ieY9l6kSyoJcRMmNFJN7r1E1H
    KEb2XiRL6+QxFJHg9mU381XlFOrFQDQrFRjpgBP8csPNrpv5ipFHM1+Fx6JLD5zjlxtuDmHI+ajD
    KFQQZd+nRvUARGSJtXIF8Xr8rW1THNEebd8noW4ErqIS7bWcgsk7+Hvb5CbKxchtPBZRGifhYgW/
    2fCzeAIONwUZNZmmKvFYNGmdgdNIZWIdgLPqOh+jXgxE24H1GOAoYmHt2wAD9o1lRomLCCdDVFTE
    +iGCvWnWrmDEdsPvRygXI9GsBKdRNwxXUcv9pKycw4TNDj+VjPVVVGsyERrG4CKC1XUIi6cwY88j
    wdoPjO58dX8Gzpbwu4waiUOAbvwehGCE6kQ3A6sDIBkxA9nIDkC/eEPDGob/6g0eixqxPohoL6wM
    G7MwLGwsM7JoItoaoqJ2CNLRCUhms3oBY6LlKItL3kZ1vmqIw2U0DWQji2cwLVYFl9GOJqLzVfMk
    pCLZkaaARBLECqYwnObRXF+1AxxH2EA2sgvQZ20ss4K8ohiVHOuByBXyMmczDUOWiVmMRkZR9F9V
    D0I6io2MzFi9hLhVVcri1uZRzDWvj8PVe/zWFJSlFEzzy3yxkmiiF2/RNAnnykDOJ3EM/ELKT1Au
    RiKXClE9AyeRK5NszT4AJ3SQ5fmMXue92CeRq5IswlKaV4SbkbkXxdS9VrhQy/IikrwaccxB5wEe
    jBLDEQztsmIVpjnrc9agMxXFVPNGSEc10MKUFK+fI3PQeYoHo0VfJBvF8tiCOGdDmTXoRLQKXO10
    FPvucUhc8qq3Mwedl3gwanRFrikEn31unS9G9lV06zHFxiJUOtCa5TnewpwVHRjhojrNcKkW6FmO
    uTHKrI3kSK7MkaGIh5YaWYNJzsKcOehEOV24wXqBvnZ0FfhgsMQJHG9aja8prvuKOehEurxFD7+j
    yMr+BRDOA+7tOqG/xNURt4jHDgxzFubsQSfSlSdrJ8E08GJpO0W+8YmeO8NwGmiT6BAmmrvi5He5
    2Dct5LH4Gc/vqQYdBu2Qwi+vgM1j+qfaR4O9a+OB7na0C9NUFY09E+QXSm2zR9AD6Ne+DzZq0GEQ
    G4Ft/PYMvD+8Il/zUBuWgaifDHAg6iZka5Xe76N/DknGjsTq3DSvxhcrmD3yhbwYC/TlPWrgxLsM
    WW2NM4H1la7NGVMdqtuGyO92dVg4S/MX5qwMmsgPOoT+vBFlcfuMfLcTPQUZbc0Q0PCeldnCMIo6
    3ezZMy4bN2CMYyNXstL21KBDFuifprPG42aSfKu6gVNAG8wFMZB56QIGikWhmz1nWbMncc4tDcd0
    QahBh9CNRnCBgVPAY5gNXp3BRAqG2b9OntlDFub4MosbLBtZDTqU6k9gnWXgFNALF0HbGkycQNz0
    F8qZPUuzwMs4ZyXQzI+qQYfSDudMAyef2EDQtEOUM8FNrEKz55S7MGdl7UXae5XHMPkCWQZOPtVE
    O0EK8Fk8tVAORTN7eFGlNYzWV/Pzb6Nd7DZHw4CJgZNPrB9mg6OdxZR1MqfGfe5fzXMUSz5R75tm
    n16YC0o76+Vzjp0jDqMVPiHCZScd0w1zwdjfWbkwW1vZovItiiWfSPeldkoXBKJh1uqsk2LaxTDd
    nvMv8KjCFm1BKM29NmunsqQ5rPIEajfQMa3yV61cn+MlcYpTyegGQVALc6fcmZG8mcT2L+AxXqs7
    2NNV5LumueDOJJzL65RYTAIvh9MGLaiVfKaUjeyC+mGYlTUNZ/Ucxmx2fTDhOtN3Nf8IDyscQasQ
    Hko5aW3PCVQEFIIdaqH2kV1zfxpS8k1aiSPwyMxh94IgRLxToxfUj8g3adEWRfxigOIwixPMzz/H
    wwoX0N4SB1JNWltksvLA96Bxk23oqFgLb2ibgTN5Ji06WXV7sQ9IqWE7INR05RUNIzC3J8nAs3EJ
    k2JtHkR4hVIpIJqFmEpCdS/AuQy+9OUTgEHHLe+LYEYjz8+/UasrD2kcATj2u7BcYm8Oxuy1YeTC
    jrSYn7+JxxXe0DEJs7t4D/1h/YJYOV74yZFrzMDAyHZMKyG1ZKl1zi0FUFKWyFw1JNoITYQqttNz
    /rWarrznDpm1jvyZtRK7czDBS6KyTaWJiTwV2d6wJSXWMe1TmtY5QK83jgek8iVKpZDoNaApE3X+
    VPBehQkv5yqCif9BpeyVjn7wY5GegAn8+R7BrGlBUIZO6RgDX4ydc/BuN4fADu6anx+/hicoPKca
    rvBmlpdjXpVs25hsBSoHRClp9Kl8975HEYEarPb3GmpHp4S0+VT3awP68Arcww4oJUSwMWwZ6WHV
    lSsDKzCCV+CaeyiUIt5W4RmKUjBkXgm1tKTBo0CL2+wInfn5UWUil5QJ8Km2corX2MEGpsoZVxkQ
    JaUWLvBWlpsj8CRKx1Q5U2pxVVqafausvMOtPSoKu/4S5R6eoSgRHbCPt5LD1rq96jzLayb1sY2s
    wwBegwseoU6KUUnCpaZPoLPWIRDOT/d21iz3nd9v7CVpjTpIWYavLsEYXoNjKs28DypfrwwMg6Xf
    fBWm+2nhR43T5N7e3u4aIbMwW6RP1nb29vZPtZYmhPhgXMCnegkuo7yqzHzl8/PP8BRFyYhBGm+k
    Oada1fS6po7uwTHUhimDfV3N1CPeBJeWI9QJty+INddMIrsIUe3sWU4azJqU5MgviN50v6urq3uQ
    kBmI4vRJH3m1vckQf5NfSp7JAbePlSU3TNJmCMpbXgbuQxJvpBmJS0eL6LpPLbsib0MPnuyEWyZx
    yIQ3ahO5DDy2tEl2YQjPtcdjOMFPMGPV4SdrtJht58zPv1WbyOVgwCrOayntcNM39olVX5METOO5
    9jF1latN5HJhGeeVdFyhrRUuLBbojqO9Kp+iTBhM3cKTFCXFMs7rPbcgOp9hsMjychrtdZ3Z/EFH
    uR/KRCOc4m00gd/Nl49lV+Q9Z9FeLeYGslJO2Wi3WEJvQdxFZEQfHOHnsNngdgcxocp8B5nYOUo5
    5cIizivxmasA4tpp/gLdSbTXTXa1Nx1lIZcPizivA5ceyg6LDUf70V6clZVSTlmZAN4iaHnOZTRW
    bIzvXD2z+fk1JqnBOmo/p4zUwzneRCb8br4iFHdFzuOQ27mziNsc+1gpp7zw47zWYcx1UvggNxzI
    VrRXpVmWns5rVSywnPDjvNwszDPwF+h2or2u8+zj+fnnyuNZVvp4vgJ+N19RMl2RmSwKR3tVPjT3
    WVFUZFeZGYEdU1Nk6TOX4TQ6tZNgXvxpLQ1iU+Jd8wALypSKQy43wwBXRybL8wMn+3UM2s0W6Mv7
    F/y+r1mum0cDaoyrUoFlp7aNtiy+2GO0sl6dm/amCHZsmLXtuLhNI5jjjwV+RhXfPJ6ff6OWVr5Q
    /5h2DD8rymA48STVhUIW6IUfvpkkP3OiV6gPzUejqBAzXqjALt9o6p0mdzKZZzHnR5W6oyDWdPXw
    ivy4gftCn3/TPP4YeYhnKnwh1jpA7ublYdbjlLgA76oZ1386l/3g5d1z8pNG2sWs4xp2r3ID4yo6
    x3dq20fIPT3f1c0ebxbmGTIL9MWtU/IzxroFA7ysFuQEtQ8oB/U9NFfmZCtBo0q9rBFZrcWarh+l
    yYqqT3jF38JfkFOeqH1AaWjuI2ZP+ujEw7JJlDY4P7gkshxsFU3aq3xgLRy1myMX1a2D5B47jypl
    Q/cARjqEV/s1j7iOTp3XqpK2dNR1DXrY8UOjcbBHfAa89szSxiGoKoGKAm6alc7O443aQFbkc5cb
    zJXlkbKPw4yDdXMLP7Aiw6jazAk1lXZb/F7rtHI5IM+U5yHcdL7FB0JUtXDS8vJQVk7YuTlvQzq3
    hdZUlKmHysoJOVVvhaVz66ngREV4qeIrQs+zeSHpVN57JrD5l2FUbR+HH1p631I6NS0vROcpjSfK
    PA4/NXQo4Uqn8vYTsYV4lhdqrooC2r6euXRuPhTb+DPwWq2rIoGeGM6Uzo2WJ7ZlQz7qLr5dEW5u
    6BZMoXRuOVINYfyBWpBHg0o0YrLSqbz10aNX4gvwAsY7lXUcFTK5L6O37nV2vnxl0xouYFSNONHh
    Ft50L1DCiRI1jmemIt62KOFECaFALRFet+AnKqKBae9fmzxT+zgR45otz4IZo50qvSpyiAbd8PhY
    mTgRpBPvvnNGn6gUmShyE++/U6aeqYrZ0aTSOmGTx0s1UUUWqxJKPF5+pCzjCHPT4fJq/Pk95aaK
    ONwq/CaMPlUBFQoM8RJn/MUD1dVBoaEFloqhZKPI4y4Kg8/Hz5RsFIXw+pxR3j5/eEutwRUMMiGC
    xXz8qvPBLbWSUphStEIfffXq0cNbyr2gsCS7Qn/V2XJLVTNR2CCzQlcV2xQ2yazQlXQUdqEJ50o6
    CifoK3QlHYVt9OgLJR2FfbQVupKOwgE00lRJR+EEskJX0lE44dq4ko7CGfeUdBQOeaako3BGZaBy
    xisq/j8YY7XulAx9SAAAAABJRU5ErkJgglBLAwQUAAYACAAAACEAMdxPFdwAAAAKAQAADwAAAGRy
    cy9kb3ducmV2LnhtbExPy27CMBC8V+o/WFupt+IQHqpCHFS1Kr1C4ANMvCQR8TqNHRL69V16KZfR
    jkYzO5OuR9uIC3a+dqRgOolAIBXO1FQqOOw/X15B+KDJ6MYRKriih3X2+JDqxLiBdnjJQyk4hHyi
    FVQhtImUvqjQaj9xLRJrJ9dZHZh2pTSdHjjcNjKOoqW0uib+UOkW3yssznlvFeTx8DPdnDb1brE9
    z76w6OPrNyr1/DR+rBjeViACjuHfAbcN3B8yLnZ0PRkvGgW8Jvwha/PZkumRj8U8Apml8n5C9gsA
    AP//AwBQSwMEFAAGAAgAAAAhAKomDr68AAAAIQEAABkAAABkcnMvX3JlbHMvZTJvRG9jLnhtbC5y
    ZWxzhI9BasMwEEX3hdxBzD6WnUUoxbI3oeBtSA4wSGNZxBoJSS317SPIJoFAl/M//z2mH//8Kn4p
    ZRdYQde0IIh1MI6tguvle/8JIhdkg2tgUrBRhnHYffRnWrHUUV5czKJSOCtYSolfUma9kMfchEhc
    mzkkj6WeycqI+oaW5KFtjzI9M2B4YYrJKEiT6UBctljN/7PDPDtNp6B/PHF5o5DOV3cFYrJUFHgy
    Dh9h10S2IIdevjw23AEAAP//AwBQSwECLQAUAAYACAAAACEAsYJntgoBAAATAgAAEwAAAAAAAAAA
    AAAAAAAAAAAAW0NvbnRlbnRfVHlwZXNdLnhtbFBLAQItABQABgAIAAAAIQA4/SH/1gAAAJQBAAAL
    AAAAAAAAAAAAAAAAADsBAABfcmVscy8ucmVsc1BLAQItABQABgAIAAAAIQAXNvXIXAIAAOYEAAAO
    AAAAAAAAAAAAAAAAADoCAABkcnMvZTJvRG9jLnhtbFBLAQItAAoAAAAAAAAAIQBGyZ9VZkQAAGZE
    AAAUAAAAAAAAAAAAAAAAAMIEAABkcnMvbWVkaWEvaW1hZ2UxLnBuZ1BLAQItABQABgAIAAAAIQAx
    3E8V3AAAAAoBAAAPAAAAAAAAAAAAAAAAAFpJAABkcnMvZG93bnJldi54bWxQSwECLQAUAAYACAAA
    ACEAqiYOvrwAAAAhAQAAGQAAAAAAAAAAAAAAAABjSgAAZHJzL19yZWxzL2Uyb0RvYy54bWwucmVs
    c1BLBQYAAAAABgAGAHwBAABWSwAAAAA=
    ">
     <v:imagedata src="image007.png" o:title=""/>
    </v:shape><![endif]--><![if !vml]><img width=218 height=227
    src="image007.png" v:shapes="Picture_x0020_51"><![endif]></span><span
    lang=EN-US style='font-family:"LindeRegular",sans-serif;mso-ansi-language:
    EN-US'><o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
    normal'><span lang=NL style='font-size:16.0pt;line-height:107%;font-family:
    "LindeRegular",sans-serif;color:#0070C0;mso-no-proof:yes'><!--[if gte vml 1]><v:shape
     id="Picture_x0020_2" o:spid="_x0000_i1027" type="#_x0000_t75" style='width:455pt;
     height:198pt;visibility:visible' o:gfxdata="UEsDBBQABgAIAAAAIQCxgme2CgEAABMCAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRwU7DMAyG
    70i8Q5QralN2QAit3YGOIyA0HiBK3DaicaI4lO3tSbpNgokh7Rjb3+8vyXK1tSObIJBxWPPbsuIM
    UDltsK/5++apuOeMokQtR4dQ8x0QXzXXV8vNzgOxRCPVfIjRPwhBagArqXQeMHU6F6yM6Rh64aX6
    kD2IRVXdCeUwAsYi5gzeLFvo5OcY2XqbynsTjz1nj/u5vKrmxmY+18WfRICRThDp/WiUjOluYkJ9
    4lUcnMpEzjM0GE83SfzMhtz57fRzwYF7SY8ZjAb2KkN8ljaZCx1IwMK1TpX/Z2RJS4XrOqOgbAOt
    Z+rodC5buy8MMF0a3ibsDaZjupi/tPkGAAD//wMAUEsDBBQABgAIAAAAIQA4/SH/1gAAAJQBAAAL
    AAAAX3JlbHMvLnJlbHOkkMFqwzAMhu+DvYPRfXGawxijTi+j0GvpHsDYimMaW0Yy2fr2M4PBMnrb
    Ub/Q94l/f/hMi1qRJVI2sOt6UJgd+ZiDgffL8ekFlFSbvV0oo4EbChzGx4f9GRdb25HMsYhqlCwG
    5lrLq9biZkxWOiqY22YiTra2kYMu1l1tQD30/bPm3wwYN0x18gb45AdQl1tp5j/sFB2T0FQ7R0nT
    NEV3j6o9feQzro1iOWA14Fm+Q8a1a8+Bvu/d/dMb2JY5uiPbhG/ktn4cqGU/er3pcvwCAAD//wMA
    UEsDBBQABgAIAAAAIQBBvYwyVwIAAOMEAAAOAAAAZHJzL2Uyb0RvYy54bWykVF1PGzEQfK/U/2D5
    HS6NRIATF1QRUVWiLQL6AxyfL2fhr66dXPj3HfsuKTy1og+J1rv2eGY9e1fXe2vYTlHU3jX80+mM
    M+Wkb7XbNPzn0+3JBWcxCdcK451q+IuK/Hr58cPVEGo19703rSIGEBfrITS8TynUVRVlr6yIpz4o
    h2LnyYqEJW2qlsQAdGuq+Wy2qAZPbSAvVYzIrsYiXxb8rlMy/ei6qBIzDQe3VP4JTOezxRln67yu
    llei3pAIvZYTFfEOJlZoh4uPUCuRBNuSfgdU0DJtSQENUY3fRAvRf6BNIPafMKyg5204kd4GkfRa
    G51eSscnUm53r+U9jQzl9909Md02fM6ZExYPjWqWwOa5veBd5z3jCZEV3Xn5HJnzN71wG/U5BjwV
    ngXHDykiP/RKtDGnAVK9RSnLNyzWRodbbUx+gxxPevHaf3eV7zot1crLrVUujdYiZSDdu9jrEDmj
    Wtm1gkb62oKnhK0ThAbSLo0eiiQfIKP4KSZSSfaZSwdOU74S9bFQBPzhnNXFgDauh2++BbDYJl/8
    tO/IZhxwZPti4JejbdU+MYnk2flisbi8BC3U5mcYjVkxNi48HA8U0xflLcsBVIBpgRe7u5g5Y+th
    S77NODY0/OLiEkBMCsxm/IXABnQgYVKen/rJ787nrhfVGWAlYs92AgMXvdHt2BqrE+bcaAvIzO1A
    zrh8lyqTChp5oZ1T9Ni3A1ubLT0I3He+gKBCNtJmfWNoxC9AB6TjsaLkFWLpc+7sZCE0umyZJj6P
    6es14tffpuVvAAAA//8DAFBLAwQKAAAAAAAAACEA/4gJk5mWAACZlgAAFAAAAGRycy9tZWRpYS9p
    bWFnZTEucG5niVBORw0KGgoAAAANSUhEUgAABXQAAAJiCAYAAABn3UrKAAAAAXNSR0IArs4c6QAA
    AARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFxEAABcRAcom8z8AAJYuSURBVHhe7f19jGTnfR966o8L
    mJBmZC0QC9pca6Eb6A8CXCC6a+FCAC8uuMQ1QhC7K8lxDyX2CCSNXC91jWxGG1ybgBFo4o1FgexR
    d8t0qMQvExmKaUZXHnZTAe0Yytww4KWWspaQhCuullEox3JISeaL3jiiXnq2ft3PU/PM4VNVp95O
    18vnA3wx01WnTp06XX2qzreffuo1bfz0bdtvOHl6+/aTp3fv7uWiiMiQ3H9yc/tM77jxlnQIAQAA
    AKALr711+20nN3cfOHl697KIyLg5cXrn8ROnt9+VDikAAAAAzMPhiNzN3Qu1gkZEZNwcFrvv/ei1
    6RADAAAAwKzEn0mfPL3zVK2UERGZNCc2d1983eb2DelQAwAAAMC0osw9cXr3mVoZ87f/4b/40dk/
    euy7n/ni1/468vIrP3r24ODgP4uIRD7/1ee+FceG337kyZd+/jc++UrtOHJyc+fS60/v3pQOOQAA
    AABMKqZZqJW5/90/euCHUdLUChwRkUGJgvfv3rt3qXlMiVI35udOhx4AAAAAJlGbM/eX/umfvFwr
    akRE2uYj+5/7dvPYEr88es1t569Jhx8AAAAAxhGj5ZqFizJXRGaVmIaheYw5ubl9Jh2CAAAAABhH
    c3RuzH9ZK2VERCbN//g7f/b98jhzcnPnWaN0AQAAAMb02tvue9NVJUsvMfdlrZAREZk0f/XC9577
    G7f/1kF5rPEBaQAAAABjOnHrzp1lwRIfglYrY0REps2rRume3r0/HYoAAAAAaOPE+3bPlwXLhz71
    2e/UihgRkWnzrz//1efL400vT6ZDEQAAAABtnDy9e7EsWB576uumWxCRueSF7116rjzenNjcfTEd
    igAAAABo48Tp3WfKguU/fuPFb9SKGBGRWeRv/r37f1Iec9KhCAAAAIA2moVurYAREZlVrv0Hv/fj
    8piTDkUAAAAAtKHQFZEuo9AFAAAAmIJCV0S6jEIXAAAAYAoKXRHpMgpdAAAAgCkodEWkyyh0AQAA
    AKag0BWRLqPQBQAAAJiCQldEuoxCFwAAAGAKCl0R6TIKXQAAAIApKHRFpMsodAEAAACmoNAVkS6j
    0AUAAACYgkJXRLqMQhcAAABgCgpdEekyCl0AAACAKSh0RaTLKHQBAAAApqDQFZEuo9AFAAAAmIJC
    V0S6jEIXAAAAYAoKXRHpMgpdAAAAgCkodEWkyyh0AQAAAKag0BWRLqPQBQAAAJiCQldEuoxCFwAA
    AGAKCl0R6TIKXQAAAIApKHRFpMsodAEAAACmoNAVkS6j0AUAAACYgkJXRLqMQhcAAABgCgpdEeky
    Cl0AAACAKSh0RaTLKHQBAAAApqDQFZEuo9AFAAAAmIJCd/nzxBNPfOuuu+76bs6oZT784Q9/u7bM
    ceXpp5/+xqjtl9WJQhcAAABgCgrd5c/HPvaxF3vfyijGDjNqmTe/+c0/ri1zXPnTP/3Tvx61/bI6
    UegCAAAATEGhu/xR6MoyRaELAAAAMAWF7mT5+te//tyjjz76rdp1XUehK8sUhS4AAADAFBS6oxPl
    7c7OzkvXX3/9Kz/1Uz910Ntt/fIxJ66L+V+ff/7552rrmGcUupPnS1/60jdjTuEbb7zxB7XrZfZR
    6AIAAABMQaE7PHt7e8/3dlO/bMx561vf+qPrrrvuh83LX//61/+k6w/2UuhOlvg+Hcf9rnsUugAA
    AABTUOgOT1mEvvGNb/xJbZqFGMF7xx13fD8vFzl79ux3msvNKwrdyaLQPZ4odAEAAACmoNAdnnGK
    0JiWIS8biaKyttyso9CdLArd44lCFwAAAGAKCt3hGbcIjakY8vIxare2zKyj0J0sCt3jiUIXAAAA
    YAoK3eEZtwgtp154+9vf/sPaMvFBXFFiRmpTOJR5+umnvzFq2VkXuuV9RmrLTJO8/vx44v+jtr/M
    yy+//GzcJuY3jlI2El8/8cQTQ/dlTtxvLH/rrbe+XN5vXJYT21i7bc6027DOUegCAAAATEGhOzzj
    FrrlqM9By5dF4qh1tlnfLAvdKDJjruC87KzmAv74xz/+4vXXX/9KfGhcXveg1G4fBWo8hlhH7TY5
    cX0Uq7V15MTjr922TOz35u1muQ3rHIUuAAAAwBQUusMzbqHbZoTuoha6UVjGNuflZjFlRKyz+YFx
    kSg9I+X95dTWE6Nqm8vF9BaxjlpJ/OCDD75QW09k0kJ3ltuwzlHoAgAAAExBoTs84xa611133chC
    dFEL3Xe/+9397brxxht/UFtmnESZW+6PuN/ayNW4LC8TaV6fc/PNN196//vf/72YsqJ5XYwsLovj
    KFhHTZtQ7tth91tm1tuwjlHoAgAAAExBoTs84xS6MSIzL/tTP/VTB1//+tefqy23iIXuhz/84W/n
    66OEjTK2ucy4KdcZ9zmo3Gxb6LZJFNF5PR/4wAdeNcq2zCSFbpuMsw3rGIUuAAAAwBQUusPTttCN
    OWLzn91HmRtf15aLLFqhGx/sVV43i1Glzz///HPlXLy1Ea05syx0Y7/n9cRo2toyOfMqdMfZhnWM
    QhcAAABgCgrd4SmL0Cgoo3yMRBkYiT+/L4vLGN0ac63W1pWzSIVulLe5iI5/n3jiiaHb3jaxX/L9
    DZpLOGeWhW65rvi+1JbJmVehO842rGP+zx/8o4NT5/Yvi4gsUJ7s5eLVefj+jXN7Zw9z70N33nLv
    wze8c/vCG3rHdgAAgOOl0B2eZlk6LGfPnv1ObR3NLEqhG6No40O98uUxUrd5u0lTzp0b911bJmde
    he6odXVR6M5yvauSU/UyRURkObK1/2Lv36PCd+uhu6Lo7R3rAQAAuqPQHZ6yCI0RrFEC5sRcqddf
    f/0rMcVCXibK0lHF6KIUujEdQL5sZ2fnpeZtpkm5T0aN+p20AI15fuO2sY9in8b3olzPqHWV+3bU
    soMy7TasY3Ipko85vX0EcKw27rnwtihmy5w6t3cmj9CN8vawxD0qc19d8h7lYiyr4AUAAOZOoTs8
    tSK0mRjpGoVoXJ+XjXKvtmxkEQrd+LCu/PWsP7grPgwurzsy6MPhcsYtdGP52IdlaRyJwr0cGRyp
    3T5nmkJ3Vtuwjsnlh0IXWEYx7UKUtjENw1HZezhFQ7PcjfQu3ztzy/aFt6SbAgAAzIZCd3jaFLo5
    UeyWpW4UhrXljrvQbZaQo+b8HTeTFLRtlo/9G6Oi83LxOGJfxojo/EFu49z3JIXurLdhHZPLDoUu
    sCpu++DFa37x3N5Np849fHfv+Bbz8V5V7m5s7T9+WADf8+k3pZsAAABMTqE7POMUupEoR/PykVpZ
    etyFbqznjjvu+H7+OkaV5jJyFvn4xz9+VXncvL6ZNgVoFKnlyNf40LXayN95Frrz2IZ1TC44FLrA
    qopRvKe29t/VO9ad3zi3dykf9yK9ry+cuveh90QJnBYHAAAYj0J3eMYtdCNvfOMbf5JvE6Vf8/pF
    KHRj7te3v/3t/XIyPhwtCsvabcdNs9SexZQL5bZGGV1bJjLPQnce27COyaWGQhdYB1Hcbmzt3X5q
    a/+RstiN+XhjyoYof9OiAAAA7Sh0h2eSQrf8k/woAZvXL0KhG5dF0Rr/z5fHdkfR27ztuIliOK8z
    Mu2HopUF8agRv/MqdOe1DesYhS6wrmLKhZhXt5enFLsAAMDEFLrDM0mhO6qwXZRCNxJFZRSU+bph
    I0/HSUzjkNcZUzDUlskZVYCePXv2O/m6WkFeZl6F7ry2YR2j0AV4zWvSlAxX5ttV7AIAAG0pdIdn
    kkK3/NP8m2+++VLz+rIcjOkZmteXmXehGynnvI18+MMf/nZ5/SS5/vrrX8nrq+2DMqMK0HK+31GF
    87wK3XltwzpGoQtwxS33PnxD75h48apid+uhu9LVAAAAr6bQHZ5xC92YsqAcnfqBD3zgu81lmgXq
    sLlruyh0IzHXb7mOvb2955vLjJMHH3zwhXJ9w+bRHVWAlgX4tOVwmSiuy2WHfR/mtQ3rGIUuwKu9
    qtg9t/dUXJauBgAAuEKhOzzjFrrldAqRKPeay8ScsuUyw0bEdlXoRsq5f2MahlFz347Kdddd1x+p
    PGx+3lEFaJTL+boY0Txsnt9xytRyvZHYR7XlIvPahnWMQhdgsF88t3fTVXPsnts/H3PvpqsBAAAU
    uqNSFqHDirynn376G+9+97uvKnNj1Gtt2Ug5JUGM6K0Vv3FfbebbnVWhGyNU47pyuWEja0el/CCx
    yKBSd1QBGrd561vf+qN8fYyQHfR9GKdMjcdbzh8c35NBo3TntQ3rGIUuwHC3ffDiNTHtwsa5vUuH
    x8yt/Rfjw9TS1QAAwLpT6A5PsyyNRPEXRWuMno3/l+VsTpSXtfXlfOlLX/pmWSZGYj0xRUMk/l9O
    3RCZd6Ebie0q7zdG2Q4qLtskpl5oPo4oRmP/xVy08Tib+6G2nub+inI99lNMXxEFamRnZ+elsnQd
    tK4yzWkXYltjm2KKheay89qGdYtCF6CdW7YvvOXU1v4j+bjZy0WjdQEAAIXuiMQI1Sgeo7zr7a5+
    STco8YFoUWLW1tVMTGlQfoDasMTo30Hz2s6y0I00pyIYNWfsqMQ+bPM4Y7tqRWpO7K/Yltptm4ki
    OsrV2nqaiftslspR1teWndc2rFMUugDjSdMwPHt0/Ix/996RrgIAANaRQrd9oqiNoi+SR9HGtArx
    dYzSnHTO2RjZGeuIdcU6Y3Rvvp+4z2Ef1hWJwjSPEI2MWiamQqgtUyYeS7nOUdvQJjEtRZTF8bii
    FM37LkbJjrPvYqRsFKVx2yjby/0V34e4n9rthiXWmbct1j1qHfPYhnWJQhdgfDEyt3fs7H9oWkzJ
    kK4CAADWjUJXRLqMQhdgchvn9s7m42hMx/DO7QtvSFcBAADrQqErIl1GoQswnVvuffiGPAXDxtb+
    M7+wtX9tugoAAFgHCl0R6TIKXYDpXTUFw9b+ixv3XHhbugoAAFh1Cl0R6TIKXYDZuO2DF6/pHU/P
    xzF149zepfjwtHQVAACwyhS6ItJlFLoAs7Wxtb+dS92Nrb3b08UAAMCqUuiKSJdR6ALM3sbWQ3fl
    4+upc3tn0sUAAMAqUuiKSJdR6ALMR4zOjVG6cYyNgjddDAAArBqFroh0GYUuwPzEPLpXSl3TLwAA
    wEpS6IpIl1HoAszXqXsfek8+1vqgNAAAWEEKXRHpMgpdgPnbuPehO+NYG6N1b7n34RvSxQAAwCpQ
    6IpIl1HoAnRj49ze2cNj7tb+ixv3XHhbuhgAAFh2Cl0R6TIKXYDu9I6354+Ou3vPbtzz6TeliwEA
    gGWm0BWRLqPQBejWxrm9C+nYezFdBAAALDOFroh0mVzo/tf/08cPE38SLCJynIkPEYt5ZnNu++DF
    a9LbpJXwzu0Lb9jY2n8mjr3xeNPFAADAslLoikiX+cWtvcNCV0Rk8bP3VO/fi6fOPXx37/9nlnke
    2t72v2Pj3N6lSPw/XQwAACwjha6IdJn/5tc+8ZM8OtcIXRFZkMSUBBev5LDIrRS8kb1nNz6y/8DG
    vQ/duWxz0sZjjccQo3Vj1G66GAAAWDYKXRHpMtf+g9/7cXnMSYcigIXzC1v718YUDBtbD90VJW6U
    uc2C97AMvveh9yzLNA29bY7S+nC700UAAMCyUeiKSJdR6ALLLKZdOHVu70wuRvvZ2n8xRsAu+sjX
    GFWci+mNrb3b08UAAMAyUeiKSJdR6AKr4pbtC2+J0btXT9EQZenembTIQjq1tf+uvK2mXgAAgCWk
    0BWRLqPQBVZRTM1w6tz+k7nYjXlqozhNVy+cNG9wb1sfvj9dBAAALAuFroh0GYUusMpiPt3DMjcV
    u708GWVvunphHI4uPrd36bB8vufC29LFAADAMlDoikiXUegCqy4+IC2mXYh5dXOxGyNiF216g5jz
    N5fO6SJYCK8/vXvTyc3ds71c6L1XuLisObG5+2Lv3/57HhGRCfNkL3Fcuft1p3fe89O3bZsuCVDo
    iki3UegC6yIK3ChN80jYmGv3F7b2r01XH7sonq+MJl7seX9ZDydPb9+uBBURGZHNnUu9f+9+zW3n
    r0mHT2AdKXRFpMsodIF1EyVulLmHxenW/ouLNAVD+QFpUfCmi6FTr73tvjed2Nx55Mr7g52nTpz+
    6PbrNrdviKTFANbSa2/dflscC3vHybtOnN55vDxWntzcfUdaDFg3Cl0R6TIKXWAdxWjdU+f2Lx6V
    p/uXN+596M501bGL6SBSqWuULp2L6RXyqNyjf7dvT1cBUBEl7mGZm86nouhNVwHrRKErIl1GoQus
    s1PnHr4/l7rx/0UYFRsfipYKXaN06dTRyNxc5u48El+nqwAY5rbz1/SOnXfncyp/zQBrSKErIl1G
    oQusuxide2Ve3f2Li/BhaRtb+4+nUtcoXTqTp1mIf9NFAIwhRuceHkdP7z5jTl1YMwpdEekyCl2A
    17wm5tGN+XRzqXvcI2PNpUvXYmqFeB8QI3SNzAWYXO9Y+mQ6t7o/XQSsA4WuiHQZhS7AkVu2L7zl
    Sqn78LGfhPW248lU6hqly3zddv6aPNWCOXMBphMfmnZyc+dSHFPj/+liYNUpdEWkyyh0Aa6IkbpX
    pl843iLVKF26cuL09ruO3gfsPJUuAmAKJ963ez6dX92dLgJWnUJXRLqMQhfgajGn7lGRun/5F8/t
    3ZQuPha9bTgapXvvQ+9JF8HM9V7/7z98H7C5ezZdBMAUXn9696aj8yu/KIO1odAVkS6j0AV4tVPn
    Hr77sEjd2n/xF7b2r00Xd25j66G70nb4kCrmJn8Ymk9ln5sYYf8XvXyvl5+JC47Zom0PNK3EczSO
    qzGdTfoSWHUKXRHpMgpdgLqNc3sXokzd2Np/5p3bF96QLu7Uxj2fftPhNpzbuxT/TxfDTOXzj5++
    bfst6SJmS6HLsvsvennt0X87sRLP0Tw3efoSWHUKXRHpMgpdgLqYt/ZU/4PJ9i+mizsX9320DT4c
    jflY4vcA/1UvL/QS217LK738N71M6sZe/qSXadYRFLqLoc3jjuLyzl7+vJfyefQHvVzXSxfy83rQ
    dubHMez5/eZe4rlbPoZf62WSUnbU9szDahS6qdtJXwKrTqErIl1GoQsw2C3bF94SH0p2WKhu7b8r
    Xdypja292+P+N7b2H08XwUytyHuAWRdAUezt9zJtKRwWrZxaibJsAqMed5Sg8cu7+J6/Ly5I4vKP
    9tLVHNPTFrq/1Ev8PEcJnUfd54J3kudy3F/sly/20tXzZSWeowpdWDMKXRHpMgpdgOFiZOzRCNn9
    J2PUbrq4MzHdQ0y5ENtwnPP5srpW5D3ArAsghe7qGfa483Wz+H5Pa5pC97/tJX6WLxx+tbxW4jmq
    0IU1o9AVkS6j0AUY7mjqhTRK95imPdj4yP4Dh6N0z+11NUKMNbIi7wFmXQApdFfPsMedR7UuwjF2
    0kJ3ls/Z47YSz1GFLqyZZqH7Vy9877laCSMiMov8jdt/66A85qRDEQCFPO1BFLvHMUr31L0Pvefo
    /o9vLl9W14q8B2hbAP2dXsr5UZ/qpfzz+pBHOTbznl6yGC0ff4YfxVu+vrauME45lUu55v1leV2f
    76U5H2qbxxYGbU++79p2DrrfuE3c72O95PuN/C+9xJyzcf2Hesn7KcrG3+tl0FyuMW9xHOfyegY9
    hhD3W+7/UfPcDnrc+fJ5FKHnehl3tOykhW5+3taeG9MYtN9CfL9+v5fyeReJ7/+waYqac/zGdA7l
    8sPuc2kodGHNnDi983h+UxX5/Fef+1athBERmTYvv/KjZ8vjzcnNnUvpUARAw6n0AWkbWw/dlS7q
    TEy7cHjf5/YuHUehzGrL7wPSl8uqTQGUR2H+k16i8Iqy8e+ny5ojM3O5WSv54rpP9lJ+yFQuLmvr
    GrecGjZaNJd2zevGeWyDtic/5tp25ts0y8JcPpbzq5b7Loq+ssCNcja2qVZyNh9DiNI2Lot1lPKy
    sZ7y8db2WTbocc+rCA1dFrrlPpmlQftt0HbE9yIed2xL7ZcSsb/j8cX+zgV8FMPl93jQfS4VhS6s
    mZObuxf65Uov//Lff/nFWhEjIjJt4hdG5fEm3nSkQxEADfGhaLlU3bjn029KF3cmF8q33PvwDeki
    mIn8PiB9uaxGFUC5JKuVXVE+1UqpQYXuIKOKr7blVN7WWsFY29ZxH9ug7Zmm0B10edt9MewxRFFZ
    PoZJvjdh0H3nInTZC91cog4rtScxaL8Nujzk65r7dNhtSm2XW2gKXVgzvR/4u/ObqsjmzqdfrhUx
    IiLT5kOf+ux3yuNN/EIpHYoAqDh1bv/iYam7tb+dLupM3Odx3TerLb8PSF8uq1EFUC7tBo0YbBZh
    x1noDrrvvJ5mSTbuYxu0PbMsdEfdR/OxDXsM+T7yY8jrnnWh23wfnAvSnHHvL0xT6Jb3XUu5PXm/
    xuWLVOg2r8vPx1H7ZNi6l4ZCF9bMyc3dd/TLlV5ifkvz6IrIrBPTLfyt9//zn5THm5Ont29PhyIA
    KjbuufC2w1L13N6lmAYhXdyJPEK4lyfTRTAT+X1A+nJZjSqAaiNVs9ro0LaFbvyp+FYvj/US+zAy
    TvE1SC4Zy3KuVs6GcR/boO05rkJ31L7O6yofQy5b436HzZtbGrRNgwrdrO1zIeR1DcuowjXv00HP
    l/w4avuwzfrHNWi/Dbo8jNrXteK+NGzdS0OhC2so/+DnnDl/8Xu1QkZEZNJ8ZP9z3y6PMzF/7mtv
    u6/zPyEGWDYbW/uPH5a69z50Z7qoE1EgR5Ec9911mcxqy+8F0pfLalgBNKqQq5WSbW8T18eHcf3t
    XiYpvgapbVOtuJ3ksQ3anryu2nbm2zSL29r6w6j7KLc3XxbPwWFpPobyw9Pyh7ANM2ibclHefAzZ
    qH08zDQjdAc9X/LjaG5PLrkXudDN26jQBVbTyc3tM/2SJeVTn/3KC7VSRkRk3Pxv/+mvv/k3/979
    V43OPXH6o/6EF6CFKHLTSNkoEzoV93l431v7wz49HMaS3wukL5fVsAJoktJz2G3GLTEnKaea95/X
    0bzPSR7boO3J66pt56D7n2WhO87+yWKEdFnsDisKB23ToMeQjdrHw3RZ6I4aaTypQftt0OVh0HUK
    XWDF3Xb+mpObO1d9+nxMvfCvP//V52vljIhI20SZe+0/+L0fl8cXo3MB2osPRMsjZbv+cLQ8j+6p
    c3tn0kUwtfx+IH25rEYVQFEkjSo9yyJsWIk3qJSapPgappx2IW9jbfTluI9t0PYcV6Ebhj2GNvK+
    GlTKhlHfn0H3vyyF7qiRxpMatd+GPV+a15XP6WGGrXtpKHRhTb1uc/uGKFn6hUtKfIhRzH1ZK2pE
    RIbltx958qXmyNzDbG4rBgDGcGpr/5HDQrfjaReujA5++P50EUwtvx9IXy6rUQXQsLk7a3PTLkKh
    m28XBd3f72VQoTjuYxu0PcdZ6A57DG202cfDlhlWNC5LoTvNdg4zaL8N25+DrsvPx1H7ZNi6l4ZC
    F9ZYfEBRv3ApEh9kdPaPHvvu57/63LdqpY2ISM5//MaL34gi91Wjcq9EKQAwpo2tvdsPC91ze+Oe
    qE/llnsfvuHwfrf2H08XwdTye4L05bIaVQDVRqpmtdGhi1Dohrxtf97LoJGX4z62YdtTWz7k28yr
    0B20nrYGbV9p2OPO19Ue+7yK0kEmLXRD28J0HIP2W5v9Oc5tSm2XW2gKXVhzJ27duTO/yRqUmI7h
    53/jk6+sU/7xg//rj/7osf/vj7/wtW/+5JUf/fgysBoODg4u9nJ/77+39xJv5gAWzi3bF95yOFJ2
    a//FdFEnYoqHoxG6e8+mi2Bq+Zwifbms2hRAeRTmP+kll37DRmaWy5dy4RaFWjmfdVw+TYlVkwu6
    QduYjfPYhm1PWayWHzL2f+ylVrgOKmIH3cewcjQea+2+Y57cOObl5WPdf9JL3vexzkEle2nU9+HN
    vcR8vLFt74sLkrhdvnzRC92Qv+8P9/KWuKAnHlvss7x87LMP9TJqn4VB+23Y/hx2Xe059nd6+bNe
    8nNo2O2XhkIXeM3rT+/edGJz98X8Zmud89Zf+Z3Lf/aFrx0WP8BqOzg4eKqXd6RDIcBCOXVu76nD
    0bL3XHhbuqgTUSLH/b5z+8Ib0kUwlfw+O325rNoWQFEcxWjXeLyRp3opy7umXHpFyuIrCrKP9hLF
    VL4+8r/00vzQwmnKqXzbNmVi28c2anty+Vc+rljXH/RSFq1hloVuqO3XuO9f6yWvP9YR092Uj/WL
    vYz6sMg234fauiP5+1o+xnmZttAN1/ZSfg9j2XIfDvq+1Qzab8P256h93XyONZ+rbb5XC0+hCxz6
    6du233Byc/fsOhe71505f/ml7/8gVT3AOjg4OLjU++eGdCgEWBjH9QFlMd1Cul+/8GIm8nvt9CWw
    +vIo3knnLKYFhS5wlcNiN+bW3dx9YN3K3Ue//JdHDQ+wVg4ODp7p/WMkGrBQ8jy6vZxPF3Ui7u/w
    fu99yIk4M5Hfa6cvgdWWR0m3GZ3LFBS6AD0HBwfvOap2rnji6Wcvv3f704cjd/MbURFZ3sSUKjf/
    5qcuf+LRL6ef8it6x4C70+EAYCHECNlU6Mbcip3ZOLd3Nu43/k0XwVTy63D6ElhtMd1COScxc6LQ
    Beg5OPqQpL4YrZvffIrI6uV3P/PF9NN+pHcMeDIdDgAWwnF9QNnG1kN3KXSZpfzam74EVlt8gNyF
    o/8yTwpdgJ4oc1Kvc+jd9zz0qgJIRFYnMVq31DsGXEqHA4CFsXFu71KUq7d98GJ8gEsn8lQPMYdv
    ugimkl9705cAzIBCF6AnypzU6xz62V/+2KsKIBFZrTSlwwHAwtjY2n8mytVbti+8JV00d8c1dy+r
    K7/upi8BmAGFLkBP6nP68htPEVndNKXDAcDC2Njaf/yoXN17R7po7uLD0BS6zFJ+3U1fAjADCl2A
    ntTn9OU3niKyumlKhwOAhXHq3P7FKFdvuffhG9JFcxf3lQrdTj+MjdWVX3fTlwDMgEIXoCf1OX35
    jaeIrG6a0uEAYGFsnNu7cFiubu2/K100dwpdZi2/7qYvAZgBhS5AT+pz+vIbTxFZ3TSlwwHAwjh1
    bv98lKsxr226aO4Uusxaft1NXwIwAwpdgJ7U5/TlN56yXrnx7IOXf+aO+6rXtcl1Z85ffuuv/E71
    Olm8NKXDAcDCUOiyCvLrbvoSgBlQ6AL0pD6nL7/xlMXKu+956PKjX/7LfmrLjJOf+9U/uPy7n/ni
    5Ze+/4P0nb8i1n/nP/s3IwveM7//bw+XvfTDH6dbHvnKX71w+bcfeVLBu8BpSocDgIWh0GUVKB0A
    Zi+f06QvAdZT6nP68sFRFitRsJZqy7TNBx987FUlbM2vfeLfVW8fRe2ffeFraanhlLqLmaZ0OABY
    GApdVoFCF9bOL/USP/NnD7+azDW9/EUv3+vlZ+ICrpbPadKXAOsp9Tl9+eAoi5VZFbqffPwraQ1H
    nnvp+5fP7X/ucP3v3f705Q/98Wcvf+LRLx9eXitjY9TuF772zXTry4fFcIzGjRHEN//mpw5H7cbI
    37h8FiOJZT5pSocDgIWh0GUVjCh0/4teXnv0X6BhWX8+5l3oLup+6XS78jlN+hJgPaU+py8fHGWx
    MotCN8raUpS7486bG6N7syhtY+qG2nI/+8sfG3idHH+a0uEAYGEodFkFQwrd/6qXF3qZ1Qi8G3v5
    k17+m8OvmLc39xL7O763kVd6+YNeruuF9gY9b4f9fCz6c32ehe6sjxuz0vl25XOa9CXAekp9Tl8+
    OMpiZdpCN0bbltMsxOjZccvcWL5cx6ApGWTx05QOBwALQ6HLKhhS6EZhE8+zL/YybQESI+P2e4lS
    UaE7f7m8iu9dLnDje/ChXqYp8dbNsOftoJ+PZXiuz7PQneVxY5Y63658TpO+BFhPqc/pywdHWaxM
    W+jGtAjZX3zr24cjaGvLDcuNZx9MazhiBO7ypikdDgAWhkKXVdDRHLoK3W6d6yW+p+85/IpJTfK8
    XfdClySf06QvAdZT6nP68sFRFivTFLoxsval7/8g3fLy4Zy5teVGJebHLdWWkeVIUzocACwMhS6r
    QKG7cuzr2VHoDqbQHSGf06QvAdZT6nP68sFRFivTFLoxNUIpRtrWlhuV5nomGeUri5GmdDgAWBgK
    XVbBiCkXBhU2MUfo7/fy573EbXP+l17e1Uvpv+2lXCanOXo01hnP63z9U728r5eaPEdpXra2jc05
    ZONPrZvbFv5OL+XjGHS/UdTFso/1kpeNxGOOaQ3i+pjSIKY6iMuj0Pu9XpofwvS/7+XOXmr7blbz
    27YZoRvbEdv7Dw+/Gm7V9mWb59qo523t56Ptc73tfgptnuvDNL93kbxfa4Vu25/DQceH2uW55I71
    1Z6T+Taf76X5PZ7VcWHY8SyMcz/x/cv7MFKdmzqf06QvAdZT6nP68sFRFivTFLoxX24W0y3UlmmT
    925/Oq3lyB33PVJdThY/TelwALAwFLqsggkK3Xx5cwRilDaDisRc6AwatZhHDP6TXnKhE6VJXBZF
    XqmcH7ZWzIQo1mKZKIhy0RKFTXNdzfuN7fz76bJm2VW73/JxRUFXlo5RBsV6Lhx+dUW+z/LyKCY/
    1ktcPqyEbStva2xXWUrF9palV5sPSVu1fTnOc63cpubzdtDPx7DbhGn30zjy9y5uX36foyyv3d84
    +2bU8aF5eV53rUTORfg02zNqXw3arjDO/eRl4zlXfv9e9bjyOU36EmA9pT6nLx8cZbEyTaEbJW72
    iUe/XF2mTZofrPbcS983SndJ05QOBwALQ6HLKpii0K0VI/m65ki7YSVXLmKaZV2I8qR5m7x8bTRf
    GLZ9pWH3G8V02/vNl7fdT2UhVBpVBI4rj8CNbYv7K9P2PlZtXw7bztpzbdj3ZNA2TfpcH2c/tTHs
    e5f3W1lCjrtvBq1/0OXDHsuwx952e0btq1Hb1eZ+xvoZzec06UuA9ZT6nL58cJTFyjSFbik+HK22
    TNuUH64WotS9+Tc/VV1WFjdN6XAAsDAUuqyCORW6zeuGFSG5XKqNpsxlS614GlTc5NF+tYKmNOx+
    ayMGB93vuI853++gAql5v5OKEYaxvbG+mILgb/cyViHVs2r7cth21p5rw/bXrJ/r4+ynNmqPP5vF
    vhn0+MfdL3n55mMcd3tG7atB2zXO/eR1tPr5yec06UuA9ZT6nL58cJTFyqSF7s/96h+kWxz54IOP
    VZdrmxiR+4WvfTOt7YqY1kGxuzxpSocDgIWh0GUVHHOhO6z8Cnl9ZWE3qrgZVtCUaqMCs3wfbe53
    3Mect69WQuZSb5ICr5T/pD7WU/6p/aj93bRK+3KS59qw24y7rWFW+2mUUY8177dcVE6ybwY9/kGX
    h+b9hlqRPcn2jNpXte2a5H7ie5ifV0OnK8nnNOlLgPWU+py+fHCUxcqkhe6773ko3eJIrKe23DiJ
    UveJp59Na7yaYnc50pQOBwALQ6HLKliQQjfuf1jKkmZUcZMLl2El5Kgip3Yfg+533Mc8rISctMAr
    DSuFRz3uplXal3kdsfywlNs07LFN+lyfxX4aZdR9DSp0m/uimXI7Bj3+QZeH2uOpldyTbM+ofVXb
    rkkfd7z+5usGfgBfPqdJXwKsp9Tn9OWDoyxWJi1044PLSrModHPiQ9Jqo3XDNHP1yvzTlA4HAAtD
    ocsqWJBCt7auQUYVN+tc6JYFVTniMRv1uJtWsdAd57k27LFN+lyfxX4aZdR9DSp0x9k3gx7/oMtD
    c7vyss3HN8n2jNpXte2a5H6y+GDAsth91c9IPqdJXwKsp9Tn9OWDoyxWJi10bzz7YLrFkTO//2+r
    y02TKI1rxa5Sd3HTlA4HAAtDocsqWIA5dGsj9IYZVdw0y6pBht1vvo+yKBx0v+M+5mElZB5dW7uu
    jXyfsY5aCTvs+1Czavty3OfasP016+f6OPtplFHf59r3ddx9M+jxD7o8K+87P77a82vc7Rm1rwZt
    17j305Qfz6vuN5/TpC8B1lPqc/rywVEWK5MWum/9ld9JtzjyoT/+bHW5WSTK4ks//HG6pyMxire2
    rBxvmtLhAGBhKHRZBQv+oWg1o4qbtqXosPutzes56H7Hfcz5fmvbV5Zdk8j3OehxjSr6mlZtXw7b
    zpph+2vWz/Vx9lMbtcefzWLfDHr8gy7P8vXxmP5+L4Oei+Nuz6SF7rj30zTw8eZzmvQlwHpKfU5f
    PjjKYmXSQjfy0vd/kG41/1GzUeCWYuRubTk53jSlwwHAwlDosgpyofva2+57U7oom6SwGXTdsJJr
    3NJq0uKmKa+nVgbWRu0Nut9xH3MukJr3O2x/x8/7F3sZ9niyXAzW9s+w70PNqu3LUc+dpmH7a9xt
    DbPaT20Mu23eb9OUx4Mef5vnTH6sf97LoPsbd3tGLT+r50RTXu+rbp/PadKXAOsp9Tl9+eAoi5Vp
    Ct1yOoQ/+8LXqsvMMnEfpZ+5477qcnJ8aUqHA4CFodBlFfRecy8evvZu7r4jXZRNUtgMuy6XSP/k
    8KurRQmZS5XyA4Zinspnexm35CqXyev7O738WS/lbcptypfXyq4w6H4HPeZRJWS5njf3Ej/TsWxz
    pGAuaCNtRxF+qJd8H+X+jG3N99MsGwdZpX0ZxnmuhfJxlSZ9rs9iP7UV36e8f8rHOuj+xtk3gx7/
    sP2Slc/p5jaUZnlcGLZdbe8n1vEnvbzr8Kuj52WU0/E4XvVcOzqu7lxKXwKsp9Tn9OXCRxYr0xS6
    n3z8K+lWlw+nRPjZX/5YdblZ5YMPPpbu7ch1Z85Xl5PjS1M6HAAsDIUuq+DE+3bPH732bjefx5MU
    NqPKnFw01gqQKOI+2ksUK3mZp3r5tV7KgqZtyRXri/KlXNf7emmK0itGCo5abtYlZKR8rIM+LT/W
    Hz/vox5v07W9xOMv7yPfTxRS46xrVfZl1va5ltWet9M816fdT+Nofu/y/f1eL2/ppantvhn0+Eft
    l5CXafOLhbbbM02hG9rcTzwP7+yl/N7FyPlc8Pb99G3bb4njavwFRLoIYD2lPqevLH1kcTJNodvF
    B6OV+bVP/Lt0T0diHt/acnJ8aUqHA4CFodBlFZy4defOw9fezd3an4Eze7mEtL+nZ1+ykHrH07Pp
    uPpAughgPaU+p68sfWRxMk2hGymnXXj0y39ZXWZW+e1Hnkz3dPlw/t7aMnK8aUqHA4CFodBlFeSR
    ZPGnwSfe+9EY1cl8KSFnx75k8dx2/pre8fTZOK6+7vRObdoPgPWR+py+svSRxcm0hW7zw8piFG1t
    uWkT8+X+xbe+ne7l8uUnnn62upwcb5rS4QBgYSh0WRV52oUTp3ceTxcxP0rI2bEvWTgnTn90O53P
    PJkuAlhfqc/py4WPLFamLXQjz730/XTrI3fc90h1uWnyiUe/nNZ+JLa7tpwcb5rS4QBgYSh0WRU/
    fdv2G/KIshObO3eli5kPJeTs2JcslPhwycNzmc2dS6+9dftt6WKA9ZX6nL6y9JHFySwK3et//Q8P
    p0DI4gPSzu1/7lVz3L77nocOp00oL4vEcnH7uM3Nv/mpw9G4cXn8G7eJ0bilP/vC1161DlmMNKXD
    AcDCUOiySl5/evemw9fgzZ1LlQ9IY3aUkLNjX7IwosyND0E7Oo7unk0XA6y31Of0laWPLE6ahe4o
    tXVEoogtS91hYpqG8rYffPCxdM1oX/mrF3wY2gKnKR0OABaGQpdV03v9vT+/Dp/Y3Hnktbfd96Z0
    FQA1MWfu6d27+8fOmLqmd1m6FmC9pT6nLx8sZbEyq0I3EkXrw3/+H9KSdTE9Q3NKhih4yw9Xq8mj
    fvPoXVnMNKXDAcDCUOiyimJ07onN3RfjtTj+jfkg/ekwwNUOP1Byc/tMf1TuUe5W5gIUUp/TVxww
    ZYESJWyMrm2b2jqaue7M+cNRtzHv7aNf/svDfz/0x589nD6htnxOrD+Wi8S0CnHb+H8UwLHO2m1k
    sdKUDgcAC0Ohy6qKkbkxQrf2+rysiVFzRhzPxuGcy4fF/+Fz5KLIOiWOJb1/G8eYnadiyoX0IwJA
    lvqcvlcfQEVk1dKUDgcAC0Ohy6qLeXVPvG/3fB6xu9TZ3Ll04r0fvTY9NCZw4vT2u05u7j5wNM9y
    ZR+LrFs2dy+YcxxgiNTn9FUPpiKyUmlKhwOAhaHQhcUV5WN/6oj4k2ij5yYS02309uH9Jzd3ni3f
    p/Xy5Ilbd+406hkAGCj1OX2NNxMisoJpSocDgIWh0IUFdPQBRf0Pd4sRpTFFQLqWFmJu0BObO3cd
    /hl58d4szRV6t5HOAEArqc/pK99YiMhqpikdDgAWhkIXFsvRaNKjEvJodK4/hW4rz4vb23cxT2j/
    /djhB+O9b/f86za3b0iLAgC0k/qcvvJNhoisZprS4QBgYSh0YXHEp83nuV3jQ4uMIm1n4Ly4m7sX
    Xnd65z0+sR8AmFjqc/querMhIiuZpnQ4AFgYCl04fjGy9MTmziPFe4i7lZDDxXzCvf10f+XD7syL
    CwDMTupz+hpvPERkBdOUDgcAC0OhC8fr9ad3b+p/WFfv3/g6XUVDzIt7cnP3bJoHt/9+y7y4AMDc
    pD6n72fuuO+q4kdEVi9N6XAAsDAUunBMjj747O78niFG6BpV+mqHo5dv3bkzpqDI++pof5kXFwDo
    wMHBwVOp0zl0/a//Yf8NiYisXuKXNk3pcACwMBS60L0YSdovKGPe183tM+kqkpj79nBe3Mb7q7jM
    vLgAQGcODg4upE7n0If++LNXvzkRkZXKHfc9kn7aj8QvddLhAGBhKHShWydPb99+Zd7Xnadee+v2
    29JVa2/QvLiHHxB3686dMVo3LQoA0I2Dg4Mzqdc5dOmHP778wQcf679REZHVyXu3P335pe//IP20
    H+kdAx5IhwOAhaHQhW5EGVmOOI3pAowyHTEvbu/yuD4tCgDQvcuXL7/p4ODg2aNqB1gnvZ/9S71/
    fFAHsHAUujB/MfI0F5aHc7+e3n5XumotDZsXt/fv/bG/0qIAAMfv4ODgpqN6B1gnvZ/9O9NhAGCh
    KHRhvk5s7tzVLyxP7zy+th98dtv5a9K8uBfy/ugnz4sLALCoDg4O3tPLi6nnAVZYjMztxQedAAtL
    oQvzEcXtydO7F4vS8my6aq28bnP7hphewry4AMDSu3w0/cL5Xp45qn2AVRK/tOnlgd5/TbMALDSF
    LsxeTKmQC8w0F+xaTSFw4r0fvbb32O82Ly4AAADAjCl0YYZuO3/NyZgDNpeYm7sPrMsI1BiRHCNu
    e4/7yf7jP9wHO8/2/jUvLgAAAMAsKHRhNo5Gpe48lUrMSydPb3f2M3VsBs2LG49/c/eBdf/wNwAA
    AICZU+jC9E5ubp85KnHT3LDv/ehKT7k0aF7cXi5GkW1eXAAAAIA5UejC5KK4PLG580hRaN4do1bT
    1Stl0Ly4MSq5tw/uMi8uAAAAQAcUujCZGKWa5oc9nCf29ad3b0pXrYyYF/dw9PGAeXFfe+v229Ki
    AAAAAHRBoQtjOvrgs7tzuRkjdKP4TNcuv8PHt317Y+SxeXEBAAAAFoFCF9qLaQdijtxccMZUA+mq
    pRcjjGNe3MPitixyzYsLAAAAsDgUutDO0ajV/CFgO0+twnQDRwX1R7fTFApFiWteXAAAAIDWTp3b
    O5MKz85yTIVuh3n47nT3MJbDDz6Lkaup7Iz/L/MHn12ZF3fnqatK3M2dZ6PcNS8uAAAAwJg2tvYf
    r5eS88nGub1LG/dc6KzE2bjn0286tbX/Ym1b5pe9Z9PdQ2snN3ffkYvPGJ37utM770lXLZch8+JG
    Qb2KH+gGAAAA0Jlbti+8ZWNr/5lURj75zu0L5q4c020fvHjNqa39Rw734db+i10W1qyGmHIgF58x
    b+4yfvDZoHlxj4rd7duXeaQxAAAAwEJR6k5Omcs0DqckOPwgsDyKdfdsumopxJQJg+bFjakWlrGY
    BgAAAFgKSt3xKXOZxuGI1vzBZ1GIbu6+I1210OLDy45GFNfnxY0PP0uLAgAAADBPSt32lLlM7HCO
    2d37rxShuw/Eh6GlaxdSbF9Mm9Db3iujiY9KXPPiAgAAABwnpe5oylwmFaNX+yNbD+ea3b49XbWQ
    TpzeflcUzkfbeqXINS8uAAAAwAJR6g6mzGVSJ27dubMoRp9c1KkJYl7c3vbdH1Mo5AI3b7N5cQEA
    AAAWlFL31ZS5TCKmKzga0ZpGt57+6PaijWwdNC/uidO7z/T+vdu8uAAAAABLQKl7hTKXSbxuc/uG
    /kjX3r+LNNfsoHlx44PaYl7c2Pa0KAAAAADLQqmrzGUyJ0/v3n2lJN15ZFGmKhg0L27vsguvO73z
    HvPiAgAAACy5dS51lbmM63D6gtM7jx+VpDuXYiqDdNWxObm5+47e9twfo2+vKnFjLt9bd+40Ly4A
    AADAilnHUleZy7hiCoMrpenOU1Gkpqs6F8Vy7/7Ppnlw+yWueXEBAAAA1sQ6lbrKXMZx+MFn79s9
    3y9Ne/8/jqkLDrfj1p07+yOE8/aYFxcAAABgPa1DqavMZRxH0xnsPJWL08N5aDsW99nbjgfKEvcw
    5sUFAAAAYJVLXWUu44j5cfOHi8Wo2JjmIF01d0dF8qvnxY3tiFG6MVo3LQoAAADAulvFUleZS1vx
    IWInT+9e7Bepm7tn01VzNXRe3N7lXRbKAAAAACyZVSp1lbm09frTuzf1R8Vu7jwbI2XTVXMxbF7c
    3r/3z/v+AQAAAFghq1DqKnNp5bbz15w4/dHtfqm6uXthbtMa9O5ryLy4DxzHPL0AAAAArIhlLnWV
    ubRx4r0fvTZ/8FnMmRsjZtNVM/W6ze0bTrxv97x5cQEAAACYq2UsdZW5tBFFav7gs16ejHI3XTUT
    R2Xx7t3mxQUAAACgU8tU6ipzGSVGw8a0ClcK1o9ux1QI6eqpxIeqHRbFp3efzOs/vA/z4gIAAADQ
    pWUodZW5jBKF6uEHnqWSNT4ILV01uSvz4vZL4sPE6N/N3QdOnN5+V1oSAAAAALqzyKWuMpdRTp7e
    vTuXrSc2dx6J0bTpqokMmhe3l4snT2/fbl5cAAAAAI7dIpa6ylyGiblq48PHDsvW+OCzzZ270lVj
    GzQvbnywWqzXvLgAAAAALJxFKnWVuQwTUyFcGUG789Qkc9gOmhc3Td1w/2tv3facAwAAAGCxLUKp
    q8xlkJjuIKZDyOVr/H+sKRBuO39NTJsQUzP0C9yjEte8uAAAAAAsp+MsdZW5DHL4wWcxGjeK3M3d
    F2OUbrpqpPiQtMMiOIrbssg1Ly4AAAAAq+A4Sl1lLoPEPLa5jI15c9vMaRvz4p44/dHtNIVCUeKa
    FxcAAACAFdRlqavMpeZwnturp0e4O11VFcuf3Nw+k0fy9rO582yUu+bFBQAAAGCldVHqKnOpOZwm
    IX/w2ebOs6/b3L4hXXW1IfPixjQLsZ60JAAAAACsvnmWuspcXuW289ccTpXQL2Z3L9TmuB00L+5R
    sbt9e6wnLQoAAAAA62Uepa4yl6aY9/bk6d0nj4rcnUsnbt25M111aNi8uDHVQky5kBYFAAAAgPU2
    y1JXmUtTjKq9Mtp256kob+Py+PCyww9FGzAvbl4OAAAAAGiYRamrzKUU0ynEtAq5qD388LL4cLMo
    eE/vXuwXuEclrnlxAQAAAGAc05S6ylxKJzd335GnT0gfgHZ/77IHrozUTSWveXEBAAAAYHKTlLrK
    XEonN3fPlqVtJU+aFxcAAAAAZmScUleZS3Y4J+7pnccrBe7lE6d3n+n9e7d5cQEAAABgDtqUuspc
    sted3nlPmlrhSonb+zrmxX3d5vYNaTEAAAAAYF6GlbrKXEpXzY27uXshCl7z4gIAAABAx2qlrjKX
    phOnt9914tadO82LCwAAAADHLErdX9za+4socP9vH/7j537+H3/y+fj/373noR/9H/7v93/u5Ond
    iyIiRe6PD76LeZXTYQQAAACALrz21u23ndzcfeCNv/Tbl/+vd/9xjNI9zC/c89Dl//J/uL8/X6qI
    SC3xQXkxgjsdUgAAAACYh5++bfsNMRdqWczkUleZKyLj5rDYfe9Hr02HGFgct52/Jub97r3mne09
    V2sjzkVE5pYTpz+6bdoqAACmFn8mffL0zlO9N5nVYuZ/d9tvVS8XERmWE5u7L75uc/uGdKiBY5X/
    AqX2XBUROaZc9DoJAMDYosw9cXr3mcobzMt/+x/+ix+d/aPHvvuZL37tryMvv/KjZw8ODv6ziEjk
    81997ltxbPjtR5586ed/45Ov1I4jJzd3Lr3+9O5N6ZAD3bvt/DW95+L9r3puiogsSjZ3LxixCwBA
    KzHNQq3M/e/+0QM/jJKmVuCIiAxKFLx/9969S81jSpS6MToyHXqgM0d/gbL75KuekyIii5bNnWdP
    bu6+Ix2+AACgrjlnbuSX/umfvFwrakRE2uYj+5/7dvPYEr88ipGS6fADc3f4Fyibuy82n4uR+AuU
    M+cvfi//BcoL37v0XO25LCIyqzz21NcP/6olXiNj8ETt2BS/ADUFAwAAAx3OJdh4E6nMFZFZJaZh
    aB5jTm5un0mHIJivo2kWXjUy99p/8Hs//pf//ssv1p6zIiJdJv6q5f/yoU/9oHmcil9E+VBRAACq
    mqNzY/7L2ptNEZFJ8z/+zp99vzzOxJ+TGqVLF2offra58+mXzQUvIouWuz7x6Hebx6v4sGKvlwAA
    XCU+dKH5xjFGCdTeZIqITJq/euF7z/2N23/roDzW+IA05q32FyhR5taeoyIii5APfeqz32ket/xV
    CwAAVzlx686d5RvGmMer9uZSRGTavGqU7und+9OhCOai+RcoMV+ukbkisuiJqc/KY9fhX7UAAEB2
    4n2758s3jDEqoPbGUkRk2vzrz3/1+fJ408uT6VAEM/fTt22/ofF8u/ypz37lhdpzU0RkkVL7qxYf
    kAYAQF/vDeLF8s1ifOpu7Y2liMi0eeF7l54rjzfxYS/pUAQzd/L09u3l8y1G59aelyIii5jmKN0T
    pz+6nQ5vAACsuxOnd58p3yz+x2+8+I3am0oRkVnkb/69+39SHnPSoQhmrvkXKGf/6LHv1p6TIiKL
    mPiLgvIY1ou/agEA4Eiz0K29oRQRmVWu/Qe/9+PymJMORTBzvefXVX+B8pkvfu2va89JEZFFTPOv
    WsyjCwBAn0JXRLqMQpeu+AsUEVn2NOfRTYc3AADWnUJXRLqMQpeueH0TkWWP10wAAKqc8IpIl3Fy
    Sle8vonIssdrJgAAVU54RaTLODmlK17fRGTZ4zUTAIAqJ7wi0mWcnNIVr28isuzxmgkAQJUTXhHp
    Mk5O6YrXNxFZ9njNBACgygmviHQZJ6d0xeubiCx7vGYCAFDlhFdEuoyTU7ri9U1Elj1eMwEAqHLC
    KyJdxskpXfH6JiLLHq+ZAABUOeEVkS7j5JSueH0TkWWP10wAAKqc8IpIl3FySle8vonIssdrJgAA
    VU54RaTLODmlK17fRGTZ4zUTAIAqJ7wi0mWcnNIVr28isuzxmgkAQJUTXhHpMk5O6YrXNxFZ9njN
    BACgygmviHQZJ6d0xeubiCx7vGYCAFDlhFdEuoyTU7ri9U1Elj1eMwEAqHLCKyJdxskpXfH6JiLL
    Hq+ZAABUOeEVkS7j5JSueH0TkWWP10wAAKqc8IpIl3FySle8vonIssdrJgAAVU54RaTLODmlK17f
    RGTZ4zUTAIAqJ7wi0mWcnNIVr28isuzxmgkAQJUTXhHpMk5O6YrXNxFZ9njNBACgygmviHQZJ6d0
    xeubiCx7vGYCAFDlhFdEuoyTU7ri9U1Elj1eMwEAqHLCKyJdxskpXfH6JiLLHq+ZAABUresJ797e
    3vPXX3/9Kzlf//rXnxu2zLvf/e6Xm9eLNPPEE098a9Tzat3j5JSuKHRFZNnjNRMAgKp1PeH92Mc+
    9mLv4ccb48M8/fTT3xi2zJvf/OYfN68XaeZP//RP/3rU82rd4+SUrih0RWTZ4zUTAIAqha5CV2YX
    he7oODmlKwpdEVn2eM0EAKCqixPel19++dkousocd9G1TIVuc/91se+ef/7558r7jMR21JadNDEd
    QfM+vvSlL32ztuyodLG9bRL3O+p5te5xckpXFLoyj9x6660v955eh8f4mFqntsw4efTRR7914403
    /uDtb3/7D2Od8TpSW07WM14zAQComtcJb5ygxEnP61//+p/07qZfcDVz8803X4p5R2vrmGeWodB9
    8MEHX7juuusOT/Ca+amf+qmD2L+TFqC1xElkrDPWXbvPnJhPeNK5YaNk3dnZeWnQ44rEc+aOO+74
    /qjH1sX2jpvjKnRjv8bzJfbHG9/4xp/UllmUODldT+/cvvCG9N/OKHRlHonjbO/pdXiMn6bQjeN2
    ua4cha6U8ZoJAEDVPE54m2VpJEaelB8W1SzhotjtckTlIhe6sR9iH5XbNyix32rrGDe171kkf7+i
    JCwvj+/fXXfd9d3augYlTlJjP5brGZZhj62L7Z0kx1XoNvdHbZlFiZPT9bRxbu/sxtbe7enLTih0
    ZR6ZVaH7gQ984Lt5PfEaFV8rc6UZr5kAAFR1UejGn8MPWq4s3mIkZW25eaS5jYtU6L7//e//Xrlt
    zRGmUfh+/OMffzH+RHNWo5ub+yNGWDeXie9jczTR2bNnv9Ncrpbm+mMUbhS2UbLGCWyZGJ0b1w87
    sZ339k6a2Oby/hS6r46T0/UUhe6pc/uXuyx1uy504+f9wx/+8LfjuBbH8fwLpvh/XFY7TsnyZRaF
    brx253VE4hheW06WM/GebVbvz7xmAgBQ1UWhW1smp1mAdXVS09zGRSl0YzvK7YoTx9pys06b/ZET
    pWheLkYVjZoaYW9v7/ly3bEvR53oDPolQM48t3eaKHRHx8npesqFbpelbleFbkx3EsVe7y77P4OD
    Er+sitK3th5Zjsyi0I3nQF5H/GK7towsX8oppWb1/s1rJgAAVcdd6EZipGZetqtRum0KwXKZrgrd
    GHlbbldXc7+OU5DGCOFyDtxh37PY/nIUdky9MYvHNK/tnTYK3dFxcrqeykK3q1K3q0K3WebGL47y
    yNz4i4PanOFxLBz1iytZzMyi0I3nxbTrkMVLeSxQ6AIAMFeLUOiW88i99a1v/VFtmSjGoiyLjPqz
    1TbLtikEy2VGFbox6rPt9g1L/Fluvs8uR+2MU5BGyu0c9D2LNKePmNcUEbPa3nESxXSMPo51x/bE
    975ZyI/arrhNjOiJdUTRHCf58f+4rM2c0rE/Yx3lz1AkPxcjbT5cbpptGDdOTtfTlSkX9p/pqtQ9
    jkJ3WDlXjsqMRNGr1F2+zKLQnUfxJ8cfhS4AAJ1ZhEI3iqO8bPw5am2ZKJjyMqPK1TbLtikEy2WG
    3WcUZrHdedko9WrLtUlzu7o62W+zP8o0l68Vf3FZuV9izt/mMpNmHtvbNjGFQ/m4hqW2XVEERwFb
    jlyuJa4f9afZ5cnjoNROKme5DePGyel66he6vX+7mn5h0QrdSEzPECN48/LxS6/acrK4UejKoMzj
    ++o1EwCAqkUodMtRnPFnqLVlFrXQjcI1Rnzm5eK+a8u1TZTDeV2RccvheByxP2Obxiktxy1IY1Tn
    qOWb64wio7nMpJnH9o5KfK/L6UEiMaI1vueROHlr/ml17X6iTC2Xied8lN1RsMbJYDzXyuuHPafi
    ex23KZ+Dkbgsp/ZBcLPchnHj5HQ9lYVu+fU8S91FLHQj5fEryt34eawtJ4uZRSp0fdDeYmVW39cy
    XjMBAKhahEI3yqS8bBRKtWWiUMrLLFKhGyVYXmZWb97LgjtGgraZpiD+9L/clsg4IyvHLUjLUdWR
    WnlcnvTGSM/m9dNkHts7LLF8WZoO+lPptsVxPM9jmwaNwG5ub6y3tlzOuD9zkVlvQ9s4OV1PzUI3
    zLvUXdRCN44ncUzMt2k7SjduF7+giV8k5eNRvDbFsT8uH/Sz3Ez84rD2y5vYprgsXkObJXMUh/kX
    SHn5eO2O4/yoaV0isW1xTInbl+so1zPqOF4mti/WF4+9+TjyHMaxn+K1sXb7UYltieNq3k85sW/K
    X9zFZbXb1xLbnNdT/pVH3u85w17z4/sQ0+KU75si8fhjW9u+tuXnQHM9kXh8tdvkzOp5mFOuL38f
    Y//EvohtHPYLj1k/ryLxfY91lSPpY5/Efq+taxbf12HxmgkAQNVxF7rlsvHmd9Ab90UsdONEI18f
    JzJtT6RGJU5Q4sQmrzsSJ9JRqJWJk5hYriwGIvF1bNs4JzFt9keZsnQedPKXT8yGLTNp5rG9w1LO
    fRm3H3TCGt+XvFxknO9BM+WH5oz6ZUFzf9SWmSTjbEPbODldT7VCN8yz1F3UQjdSHpPajNKNAi6O
    Pfk2tcQxd9SIzeYv/gYlXkfyusrXulqixBr21yRR1tVuV0vsl9o6yjT/UmJU4jGP2r9l4rGUZd6w
    tP1+R+L1oLaOZuJ1pHb78ng8KMNen3JGPQfiPVTtdpFZPQ9zYp+MWl98v2u3nfXzKvZbreBupvlc
    mvb7OipeMwEAqDquQjfeEMeb8XzSFCeEw0bSLFqhW374VYxQGXUCNUni/pt/Sj8scdLT9iSqmTb7
    IyeK67JEHlT0leuLE8jaMpNmHts7KPFcLU/uh41Gm2WhG1NU5PXECW9tmZzm/qgtM0nG2Ya2cXK6
    ngYVumFepe4iF7pxHMm3iQybkiauyyP/4t/4ZV4+tsR64hdO5TFq2GtpvJbl5eL/8XoZy8drWBy/
    yvI21lm+BsVxPO47lmu+PsWyg0rTfByJ+4tiLa8jr6dZ0I7665LaY4h1xb6IbYj/N8vPNseveK0o
    bxf7OrY31hfrzR9CWRaQ4xS6sf64fXMdsV/z5ZHa+4myvIx9HV/H4411xvuRsqSNfTLstae5/+J7
    Ht+juO/4d9Br3Cyfh5HYn+Vrc96WuF1sS6wvCtZB65n18yreG+Rl8+PL64vbxves9jya5vvaJl4z
    AQCo6qLQjZOtSLzhjpOf8uQ3EqNMRxVfbUranDbLNrexdv/lMuV6ojTNJy1xMjJq26dJ3Fd5ghCJ
    +44ThTihK0+GYntr62iTNvsjpzzhj5Oe2kl8XFaub1CJGid0+flRy6A/UZz19g5LPG/z7UedvMdJ
    W142Ms1zI06Q83rie968vkxzf9SWmSTjbEPbODldT8MK3TCPUneRC91Ivk1kUNlU/kIqfgYHlW1x
    eX5dqpVOOfFalu8zjrG1ZZrHk1hvlFvN5aKgyvcZiWNlc5lIPIZR5V55n7HOYcfONo8h0jwe1x5D
    mfJYH68Vg15/yuJvnO93mfI5M+qXjM39HPuquUzs43J06aBRrZG2+6/MrJ+HkfK9Tfx/3MJzls+r
    +F7n5SKDvvej3j+M831tG6+ZAABUdVHoDkuUbW0KtjjpyLcZVNLmtFm2uY21N/nlMnk9sa35ZChO
    Dga96Z82cZLSHJ0bxXeMxImTmLxceWJWO8lrmzb7Ix57OQooMugEuSwCI22Lg2YGPaZZb++wlCfJ
    w/6sODLLQjfK/HJdtWVymvujtswkGWcb2sbJ6XoaVeiGWZe6i17olsfvQWVoFL15mVEjDMtCMkYv
    1pYp73NYmVcuN+wxlY89XrNqy7RNWfAN2v5I28cQKV8DhpWc8XqRi8hRr+1dF7rlcyAeT22ZSLze
    5OXiMQx6bzXO/suZ9fMwj66NRHk+6ejVNmnzvGq7j0dlnO9r23jNBACgqotCN/+5WZRhcfIQaZaV
    UeyWRWUzcZu8bJyM1JbJabNsm0KwXCbW0xwBM+yEc5o054WLk4JBxWB5YhbbW1umTZr7Ix5nnJjk
    lPcTiROw2M7auiLliWVk0EljFNfl/UTK2w16TLPe3mGJ2+b1xPO4tkzOpIVuFOCxfVHaN/dBTu12
    Oc39UVtmVKbdhrZxcrqe2hS6YZal7qIXumXRVCsbm6Mih71GRsrjbhwTa8uUx8ZBx+VI+efqwx5T
    +XobqS3TNuV0B8O2re1jiJTbN6xwLkvIUUVc14Vufg5ERr3Ol8+pQftmnP0XmcfzsNzO+L7XlplV
    2jyvymWm2Z5xvq9t4zUTAICqLgrd2jKRGP1XnjTGydagESXlSVmcjNSWyWmzbHMba8VbuUysp3zD
    P2qEyqQppweIjDp5K0/MRi07LM39MSzxPRv0fcqJ0TblbcY5QSpvN+gxzXp7B6X5OEYVtOMUurHu
    GDFc/pIgEifMcVJYlgaR2jpymvujtkwts9yGtomT07fc+c8v59xy78M3yOrn1Ln9820K3TCrUnfR
    C91y9GgUXM3ryz8Db7ve+NmN5aOAq13ftsxrW1pOeuyppXztHlaGtX0MkTbvB8rRuZF4b1JbLqft
    vhmW8jkz7LE2/0pi1GtQWUwPKrDH2X+RWT8Py/VFRu3vadPmeVUuM81I87bf13Gi0AUAoOo4C92c
    8g1wbZRSpM1JWU6bZZvbOKrQjZOT8oRvHoVunOSU99FmRGl5YhbbW1umTZr7I0ZTx2OMfRnbEUXl
    qBPJZsr1Dfq+1lLebtBjmsf21tI8mR41MqltoRuXlyOU4vsY210uH/8v11Xevpnm/qgt08yst6Ft
    4uQ0l3WyfmlT6IZZlLqLXuiWxWDttar8s/RBUzI0U/5M145X5WtGHC+b1+e0LS0nOfaUiV8qxXEz
    frHUtgxr+xgibd4PlMf5WgHZTNt9MyxtH2u8tuXl2mxb+ZyJ9xPN6yPj7L/IrJ+H5foi5XWzyrjP
    q+Y2tX2czbT9vo4ThS4AAFWLUOjGG++yyKyVeG1OynLaLNvcxrLEqi0T64kTg/I2oz6MY9yUo7Xa
    jhApT8xq+61t2uyPcVOe0A36s8tayu0Y9Jjmsb21NAvaeK7WlstpU+jGZeX3LZ5XzWXycuW6asvk
    NPdHbZky89iGtikL3f/+H/+r+PeirE/GKWenLXWXfYRuOa/nJKkdf8qf+2FlXtvSctxjTyRKypja
    pZzOpplhZVjbxxBp836gLE1r34dm2u6bYWlb/MUv2fJyg7a/TJvXrHH2X2TWz8NyfW1K6raZ9nnV
    /EuVeC7EOmvLDkrb7+s4UegCAFC1CIVupCz/4g158/o2J2U5bZZtUwiWy+T1lNMuxElDjKpt3m7S
    lCchtX1QS3liFttbW6ZN2uyPcVOe9I5z0lZux6DHNI/traX54W7xdW25nDaFbpT1+fphJ9Nx23Jd
    tWVymvujtkyZeWxD25SFrpNTRpmm1F30Qrd83at9EFP55/OTJI5HzXW2LfPalpZtjz0xSjMeT7wW
    5GXj//G4YzviF6TlfQ4rw9o+hkhcn5cd9H6gXKbN96/tvhmW8jkz7LGWz4E2ZXO8J8nLR2rvUcbZ
    f5FZPw/L9U0zvUFkls+rKL/Ln8mc2MYYwVu7TTNtv6/jRKELAEDVohS6ZVFaO+lqc1KW02bZNoVg
    uUxeT5w8lG/Y4/JJ52ctE+vI64zECJbacs3E/efbxPbWlmmTNvtj3DTXWSsXailvM+gxzWN7a4nv
    d3k/o0Zljyp0y+tjVHrzT1HLjFOmNvdHbZmceW1D2yh0Gdekpe6iF7rl8bs2z3hZRA06Fo6b8j6H
    lXnlfQ97TG2OPXGMKUcjx0jI2uMpX7uHlWFtH0OkzfuB+AVqXqZNCdd23wxL+ZwZdp/j3lfzmF17
    3R1n/0XKbZjF83AW+y8y6+dVJN6LRUFc/tVYTtzXsNfLSNvv6zhR6AIAULUohW45YiNGqjavb3NS
    ltNm2eY21grBcplyPTGKozwhipOIUW/yR6VZ6LY5yYqU2zHNiVab/TFumlNptJ1Ht9yOQY9pHts7
    KOU+jj9/rS2TM6rQLf/UdNRoq+aJeW2ZnOb+qC2TM69taBuFLpOYpNRd9EI33yZSO+aXr4ttXxNG
    pTyeDVtn29Jt1LGnWboN++uT8rV7WBnW9jFEynXG7WrLlL9Qjm2tLVNmFoVk2+Kv3LY2UxfNe4Ru
    m+VHpVzfoO/JqMzjeVUm3pPFvm9O3zDq+dH2+zpOFLoAAFQtSqFbzl1We8Nczl9bK3zLtDmBa1MI
    lss01xMnSWVZ2XaKhGEpT7Lalp/lbWJ7a8u0SZv9MUnKk9FI7eSymXL5QY9pXttbSzl6a1QBOqrQ
    LZ+bo54z45Spzf1RWyZnXtvQNgpdJjVuqbvIhW7zZ6t2rCtf92ZVDpWvGcPKubal5ahjT3l9HD+H
    /fKzbfHW9jFEynU2X8dzynlq20wB0HbfDEvb4q/N9pdpvgbV/oJonP0XmfXzsPyl4qj3c4Myj+dV
    LfGL6fJ7FRk2/ULb7+s4UegCAFC1CIVunHCUoyBi9EZzmeZJSu2DPnLanAA1t7FWCJbL1NbT/FTk
    D3zgA1ONXCmLw9gftW1qpjwxi+2tLdMmbfbHJIn1lMV3nOwM+95Fyu0Y9Jjmtb21NEc8xSei15aL
    jCp0y+2e5ejY5nNx2Fy/89qGtlHoMo1xSt1FLnTL16k4RtaKt5jiZdz1jkrbMq9tadk8FjevL0dR
    1l7by7Qt3sYpJNu8Hyg/FK1Nwdh23wxL+ZwZ9ljL/fvGFnPRl8+ZeF41r4+Ms/8is34eNl+vJvkL
    p3k8rwYlti/2fV7HsFG6bb+v40ShCwBA1SIUuuUb4EitMGtOSTDsDXybE7jmNtYKwXKZQeuJErdc
    z7ifiFwmysCy/GwzlUN5YhbbW1umTdrsj0lTju6JRIlYKy9yymUHPaZ5bm8tZdke+3zQ/Y0qdMty
    OL7Xw7Y7rivXVVsmp/nhbcOmhpjXNrSNQpdptS11F7nQLY/dg/4io/y5HlT6jpvyfoeVeW1Ly+ax
    uHl9+QGMo14f2xZvbR9DpM37gXjPkZeJxDGytlxO230zLOVzZthjLcvUyLDjdaR8vINGG4+z/yKz
    fh6Wr0GRSd43zeN5NSzlNBHDRnG3/b6OE4UuAABVXRS6g06OYpRGlHvlssOKqPINdZxU1JaNE49y
    5MagE7g2hWC5zKD1ROJkPC8X2xWlXm25NmmWnzEqZNDJSmxzOWoktre2XJu02R/TpDwBjsT+jO9f
    +dyI8joea7ncoMc07+1tJk5gy1Hksf1xot0s3NtsV/l8ief/oG2Py8t11ZbJie0of5Zi+4aN0p3H
    NrSNQpdZaFPqLmqh2/zFz6DXyObP9bR/BRKJY0Ne37Ayr21p2TzmNa8v72/Yn6pH2hZvbR9DpFxn
    3K62TOzn8rW09gF1Zdrum2FpW/w1t23Qa2JOud5Bz5dx9l9kHs/Dcn2jpv6pZR7Pq2Epf3nfttCd
    5HHVotAFAKCqi0I3Eick8UY3Ur4RLzPqJCpOKpq3jQI1r7c86ckZdALXpngrlxm0nkhMIVCOFmk7
    XcKgxPxyZXlYrjc/1vL+ckad6A1Lm/0xbaLAje9XeT+jMugxdbG9zcR9lHM958SJ6aDvSW27ms+X
    SNw+Tjpz4meh+XxurqeZ5kiunNoJ7Ly2oU0UuszKqFJ3EQvdOCaUr2OjPuiq/PP0+HkcNWXNqJT3
    HT/ntWUibUvL5rG4eX35y6Nhv7CNxPbkZYcVb20fQ6Rc57DX8fKXiaNGobbdN8NSPmdGlYzlYxj2
    5/7x3MqvscMewzj7L2fWz8NyfbGt476Gz+N5NSzlQIFhRW351zyxn2rLjBuFLgAAVfM44Y3iNU7y
    yjfcwxJvsIeNJiwTJxExUmJUMRgnLPEmf9BJQptCsFxm2IlgJG5flrBRlk1zwhO3jZOQWrHbTBSK
    MbJ3mvvrqiCN9cYJz6jvX+y/YY+pq+1tJp7b8fwb9X2Jxzjsz0BjPVHcxwlf7fY5cT/x89F21Hcs
    11znoBPYeW3DqCh0maVhpe6iFboxErc8dsT/2/xclb9Iip/XYfN4x8/1sHW2LfPiZz4vN02hW34A
    Vmz7sKK0bfE2TiFZrnPU63g5ajSWHfS60nbfDMs4hW7ss/I1M/Zpc5n4vpfPk3j/1VwmZ5z9V2aW
    z8NIrCOvb9j+rmXWz6v42Rw0Ur68r8iw1/Z431kuO2id40ShCwBA1bxPeONNdrypj8Qb3XhjHf/m
    y2q3aZN44x+3j9Iv1hmJE8u4bNhJxjImP9Z4fM3917YIX8TE9seJUv7+ReJEaRYnQF0kP7eb35Nh
    J5bN5JPeSP75yM/jSO02bVL+3MV91JbJmdc2DIpCl1kbVOoeR6GbfwESP0cxaj6OcfF1WRZGosBq
    e/yO5cryK8q9KOziPuJnNI6b8f+4n1wYDyrH2pZ5sa683DSFbrNojF/W1f5EPra3HAU5qHiLjFNI
    xvV52bhdbZmceO9Q7uf4f/wCL7Y3Hw9jX5d/3dBFoRsp93N8/2MKqtie+KVnbFO570aVo+PsvzKz
    fB5G4uejLKpj3fHL0His+b1B7Ov4f/O2s35e5ZG18X2J+43HEq+H5W2H3T4nXnubjynWE49p2L4Y
    FoUuAABVXZ3wiohEFLrMQ63U7er1rfwz6zaJ0mjcv6iI5aNMqq2vlloJFum60I1EkdUstCNRdsW6
    ywIsZ9i2zavQjcR+bpZ4wzKq4BuUcQvdSBSWtX1VJvbzqF8qTlroRmb1PMyJX+CW21NLlLW1287q
    eRXlcNymuWwzUaKP+gVpJMrg2n1H6V1bflQUugAAVCl0RaTLKHSZl95z6nx+bt1y78M3dPn6FmVN
    FD5RJJUjOHNixGKeiqVNKTQoMaox309ZGsX647KY+3rYyN/YhlguEmVsbZlIjCrMy8X91ZaJxPbk
    5SK1ZXKikIyytLZ/4rK4fRSAo0Yytn0Mkbg+Lxu3qy1TS9wuistyFGik7X4elfw9jMS+ri1TSzzP
    aoVzbFdsU5tfFIyz/wZl2udhmSigo2htritK1tjWUX91NYvnVfxMxjKxbFkwx/c/ngfj/uVQPPa4
    XX5M8e+468hR6AIAUKXQFZEuo9BlHn7x3N5NG+f2LqXn1vm4zOubrHKi3I1M+qf8shxR6AIAUOWE
    V0S6jEKXWauVucHrm4gsexS6AABUOeEVkS6j0GWWBpW5weubiCx7FLoAAFQ54RWRLqPQZVaGlbnB
    65uILHsUugAAVDnhFZEuo9BlFkaVucHrm4gsexS6AABUOeEVkS6j0GVabcrc4PVNRJY9Cl0AAKqc
    8IpIl1HoMo22ZW7w+iYiyx6FLgAAVU54RaTLKHSZ1DhlbvD6JiLLHoUuAABVTnhFpMsodJnEuGVu
    8PomIssehS4AAFVOeEWkyyh0GdckZW7w+iYiyx6FLgAAVU54RaTLKHQZx6RlbvD6JiLLHoUuAABV
    TnhFpMuUhe5//4//Vfx7UdYnG1t7t6eXn5GmKXOD1zcRWfYodAEAqHLCKyJdpix0Zf2ycW7vbHr5
    GWraMjd4fRORZY9CFwCAKie8ItJl4uT0LXf+88s5t9z78A2y+olStm2hO4syN3h9E5Flj0IXAIAq
    J7wi0mWcnK6nKHLbFLqzKnOD1zcRWfZ4zQQAoMoJr4h0GSen66lNoTvLMjd4fRORZY/XTAAAqpzw
    ikiXcXK6nkYVurMuc4PXNxFZ9njNBACgygmviHQZJ6fraVihO48yN3h9E5Flj9dMAACqnPCKSJdx
    crqeBhW68ypzg9c3EVn2eM0EAKDKCa+IdBknp+upVujOs8wNXt9EZNnjNRMAgConvCLSZZycrqdm
    oTvvMjd4fRORZY/XTAAAqpzwikiXcXK6nspCt4syN3h9E5Flj9dMAACqnPCKSJdxcrqecqHby8Uu
    ytzg9U1Elj1eMwEAqHLCKyJdxsnpeioK3Zy5lrnB65uILHu8ZgIAUOWEV0S6jJPT9dQodOde5gav
    byKy7PGaCQBAlRNeEekyTk7XU1HodlLmBq9vIrLs8ZoJAECVE14R6TJOTtdTKnQ7K3OD1zcRWfZ4
    zQQAoMoJr4h0GSen62njngtvS//tjNc3EVn2eM0EAKDKCa+IdBknp3TF65uILHu8ZgIAUOWEV0S6
    jJNTuuL1TUSWPV4zAQCocsIrIl3GySld8fomIsser5kAAFQ54RWRLuPklK54fRORZY/XTAAAqpzw
    ikiXcXJKV7y+iciyx2smAABVTnhFpMs4OaUrXt9EZNnjNRMAgConvCLSZZyc0hWvbyKy7PGaCQBA
    VfOE969e+N5ztTeUIiKzyN+4/bcOymNOOhTBzCl0RWTZo9AFAKDqxOmdx8s3ip//6nPfqr2hFBGZ
    Ni+/8qNny+PNyc2dS+lQBDOn0BWRZY9CFwCAqpObuxfKN4r/8t9/+cXaG0oRkWkTvzAqjzdRuKVD
    EcycQldElj0KXQAAqnpvDu8u3yhu7nz65dobShGRafOhT332O+XxJn6hlA5FMHPNQvc/fuPFb9Se
    lyIiixrTFAEAUHVyc/cd5RvFeONoHl0RmXViuoW/9f5//pPyeHPy9Pbt6VAEM9d7jl0sn2+PPfV1
    UwqJyNKkOU3Ric3dF9PhDQAAXj2K6cz5i9+rvbEUEZk0H9n/3LfL40zMn/va2+57UzoMwcyd3Nx9
    oHzO/d5nvmhKIRFZmjSnKTp5euepdHgDAIA46d0+c/Ubxt3Ln/rsV16ovbkUERk3/9t/+utv/s2/
    d/9Vo3NPnP7odjoEwVyc3Nw9Wz7n/u69e5dqz08RkUXM2T967LvlMSx+SZUObwAA0HPb+WtObu5c
    9WddMfXCv/78V5+vvcEUEWmbKHObH+pidC5deO2t228rn3emFBKRZYlpigAAaOV1m9s3RMly9RvH
    3cvxIUbxprL2ZlNEZFh++5EnX2qOzD3M5vaZdOiBuWpOKfRL//RPfPCniCx8atMU/fRt229IhzYA
    ALgifvN/1ZvHlBghEH/29U8f+f987zf+1f/6g//w7Avr/sEyMR3Fd3t5pZeDy8BK6P04X+zl/t5/
    b+/lmnRoZImdOL39ruZrWvyiofd9rh3bRUSOPfEBjvEXBeVxyzRFAAAMdeLWnTvLN5A5//X/9PHL
    p87tH+YX7nno8k3/r//5hz//G598ZZ1yy7n9H37uPzz7k8PmB1hpvZPqp3p5Rzo0ssR6r2FPlq9n
    UZT8y3//ZR+QJiILlyhzX/WXLaYpAgCgjdef3r3pxObui/mNZFnm3vRP/ud+qftf/g/3X3mzueK5
    7sz5yy99/wep6gHWQe/k+lLvnxvSoZEldeK9H722fE3LuesTj373he9dMqeuiBx7YnqzmGahOTI3
    8rrTO+9JhzMAABgu5umKTwj/P/1Pf3Apl7nX/j9+7/Ib3vfRyzd+8MG1K3Uf/fJfHjU8wFrpnWg/
    0/vHvIVLrjb1QiRGwp05f/F7MSquWbCIiMw78cGhUeS+6sNDc3rvxdNhDAAA2tk4t3c2l7lv+3/+
    i8/mEU7rVurecd8jh8VO6Ymnn7383u1PH47crd1GRJYrb/2V37l8829+6vInHv1y+im/onfSfXc6
    LLLEYpRb7cM/m6lNubOquftTn/3Rhf/3/+/HX/jaN00nBCuk97oVc8Kf7+XO3pfmhAcAWBdlmbux
    tXd7urjvtg9evObU1v4jh8ts7b+4cc+Ft6WrVk7vzXB8SFJfjNatlQAishr53c98Mf20H+kdA55M
    hwOW3MnN3Xec3Nx5tvZ9X6fELzDiF5PA6uu9hpkTHgBgHYwqc7N1KXWjzEnviQ+9+56HqifIIrIa
    ibKr1DsGXEqHA1ZAnlKoNq/uOsSc8LB+4nWsl5vSYRAAgFXTtszN1qHUjTfB6f3woZ/95Y9VT5JF
    ZHXSlA4HrJAodmMahhPv2z2/TqN2jcyF9dR7Pxs//G9Kh0AAAFbFuGVutuql7tHb4CtqJ8gislpp
    SocDWGq9p/LtR8/oK/7sC18zJ7zICiXPCf/Jx7+SfsqvODg42E6HAwAAVsGkZW62yqVueg/cV3vz
    LCKrlaZ0OICldnBwcD49pQ89/Of/ofr8F5HVyIf++LPpp/1I7xhgTngAgFUxbZmbrWqpm94D99Xe
    MIvIaqUpHQ5gqUWZk57Sh8wJL7LaiWnCmtLhAACAZTarMjdbxVI3vf/tq71hFpHVSlM6HMBSS0/n
    PnPCi6x+mtLhAACAZTXrMjdbtVI3vf/tq71ZFpHVSlM6HMBSS0/nvtpzX0RWK03pcAAAwDKaV5mb
    rVKpm97/9tXeLEt3ueO+Rw4/6OOl7/8gfUeO/MW3vn350S//5eVz+59r9eE+8aEhv/aJf3d4m0jp
    K3/1wuFlv/3Ik5ev//U/rN6+mQ8++Fh/XXG72jJlht3/F772zf56fu5X/6B6e5lvmtLhAJZaejr3
    1Z77IrJaaUqHAwAAls28y9xsVUrd9P63r/ZmWeaf+MTmKFrbGjY3ZPNDQkaJAnlUSfyJR7+clr58
    WMbWlsmJoratSz/88WFJ/TN33Fddl8wnTelwAEstPZ37as99EVmtNKXDAQAAy6SrMjdbhVI3vf/t
    q71ZlvnmzO//27T3r4hyN4rWKGdz8kjX5176/tACtCxfY2RvjPrN18WcklEex4jbchRwLDdsvslx
    Ct1yRG6styyfoziO+48SN8rc7Imnn71qHTLfNKXDASy19HTuqz33RWS10pQOBwAALIuuy9xs2Uvd
    9P63r/ZmWeaXmJagFEXusNGyUbqOmiahbfka0yJEOZw9/Of/obpcZNJCd9iyMd1Cef9RMteWk9mn
    KR0OYKmlp3Nf7bkv3ebGsw8e/kIyfkEZrwd/9oWv9X9JOewvTZrJ0/jE7eKvQGJdkfg6XjviF4W1
    29USy+ZtiHXWlmmmdv/xmhlf3/nP/s1Y9y+zTVM6HAAAsAyOq8zNlrnUTe9/+2pvlmU+iWK2HKUa
    J4iz+FT2ccrXGL1bipPv2nLzKHQjZaEd+6LN/MAyfZrS4QCWWno699We+9JN4tjenAu+Jn6JOazY
    jdekKIPbiL8IiXK1tp4yUcJmcZvaMjlR1EYJ3UbMD6/Y7T5N6XAAAMCiO+4yN1vWUje9/+2rvVmW
    +SRO/rIYqVpbZpKMU75GypPumP6htsy8Ct2YOqIstcvpIWR+aUqHA1hq6encV3vuy3wTI1nL17ZS
    /kDMZtE77K9OyvI1XivyVERRnMbrVfy/WbjGFD7DpiUap9AtX/tiu5tTIcX1zfnv47LaumQ+aUqH
    AwAAFtmilLnZMpa66f1vX+3Nssw+cTJaavtnn20ybqFbnnz/7me+WF1mXoVupLz/mFu3tozMNk3p
    cABLLT2d+2rPfZlf4i8soiAtxWtKvN7VCtb4Bd6o8rNt+RqjfMuiOKZFqC0XmbTQHfZ6Fq/h5S8n
    24wUltmkKR0OAABYVItW5mbLVuqm9799tTfLMvuUf0IaJ4Exqqm23CQZt9AtC9gY6VRbZp6FbsxD
    mA2bx1dml6Z0OICllp7OfbXnvswnUdjGyNgsXtdmUWqOU76WU/iEQVMIzaPQjZTrjXJ5lq/rMjhN
    6XAAAMAiWtQyN1umUje9/+2rvVmW2accSTRoVOykGbfQLf9cdNCopnkWuuWfy0bRXVtGZpumdDiA
    pZaezn21577MJ2WZGcb5wLNhGad8jbT5oM15FbpRapev7YOmMJLZpikdDgAAWDSLXuZmy1Lqpve/
    fbU3yzLbxHyBpUEnnZNm3EK3/DPRrufQjbQ5AZfZpikdDmCppadzX+25L7NPs8ic5V9ajFvolq8/
    g35BOK9CN1KOUh427YPMLk3pcAAAwCJZljI3W4ZSN73/7au9WZbZJv4MtTTrufbGOQGNAjeLYnXQ
    n4jOq9Btltvv3f50dTmZbZrS4QCWWno699We+zL7NKc6mOVxfNxCt3ytivnZa8vMs9Atp1OKcre2
    jMw2TelwAADAoli2Mjdb9FI3vf/tq71ZltkmRqGW4gNjasvF/H9x3aDUbhNpewL6c7/6B1eNzh32
    wWzzKnTL0Uxx8j3sk8lldmlKhwNYaunp3Fd77svsUx7zB5Wok2bcQrcsVAfNCT/PQrdNoSyzTVM6
    HAAAsAiWtczNFrnUTe9/+2pvlmW2KU8mQxSrteXiRHOY2m0io05Af/aXP3b53P7nripzR83jO+tC
    N0YCl3PnhkEfYCOzT1M6HMBSS0/nvtpzX2ab+CVc+Voy62kGxi10o0TNBm3LPAvd8vXPnPDdpCkd
    DgAAOG7LXuZmi1rqpve/fbU3yzLbNAvd686cry43y0I3PqCmPNHM4j7afHDLpIVurD8eb5kocsv5
    FqMMGDY6WGafpnQ4gKWWns59tee+zDbxi7jSrOdBL18vR5Wv8VpalsuDpn4YZ53jvPZFuW1O+O7T
    lA4HAAAcp1Upc7NFLHXT+9++2ptlmW3Kk8kwaIRuM825d2vLRGonoM0TzTjpHTZtQzOTFrqjxIfn
    DCq0ZX5pSocDWGrp6dxXe+7LbBOlaWnWc8KPU76Wrz2DpluIzKvQbc4lHHPE15aT2aYpHQ4AADgu
    q1bmZotW6qb3v321N8sy25QfRBZi9GxtuWamKXQj03xwzaSFbpwsR2kbl0ViPXEyHY/Zye7xpSkd
    DmCppadzX+25L7NN83Vp0C8Ky9eFmkG/2GtTvsYvRcspfOKXl8N+UTqPQjcedzk6OKY1qi0ns09T
    OhwAAHAcVrXMzRap1E3vf/tqb5ZltrnjvkfS3j7SdkTTtIVuc5TuV/7qhcPLytsOyqSF7qhl5XjS
    lA4HsNTS07mv9tyX2ab5ujSoSC1fF2rGKXRjmoe4PF6XmuuNOXRjjvbmespMWujGusvCOn4pGV+X
    y4RYru1rq0yfpnQ4AACga6te5maLUuqm9799tTfLMtvEiWspTi5ryzUzbaEbaY7SbTt3rUJ3tdKU
    Dgew1NLTua/23JfZpvm6NOgvL3L5mRMjWEvjFLpR2JbzsMf/4zWq7V+dTFrojhIjdGPeXGVut2lK
    hwMAALq0LmVutgilbnr/21d7syyzT5xEZk88/Wx1mWZmUehGyk8BjxG7P/vLH3vVMs0odFcrTelw
    AEstPZ37as99mW2af3HStlRtvp6NO+XCNPPVzrLQjb90ide5WGfb+fBltmlKhwMAALqybmVudtyl
    bnr/21d7syyzz28/8mTa40fanIzOqtBtfohNbEtzmWYUuquVpnQ4gKWWns59tee+zDYx2rYUc8TX
    lmtm2kK3OYXQsA9Ba2bSQtfr2WKmKR0OAADowrqWudlxlrrp/W9f7c2yzD5R4Jbig8Nqy5WZVaEb
    KUfpxp+JDjqZzlHorlaa0uEAllp6OvfVnvsy28RfeJTafhjYtIVuZNIP+lTorlaa0uEAAIB5W/cy
    NzuuUje9/+2rvVmW+SRK3FJ80EttuZxZFrrNUbqjCmWF7mqlKR0OYKmlp3Nf7bkvs0/5C8K2I2Vn
    UehO+kGfCt3VSlM6HAAAME/K3KsdR6mb3v/21d4sy3wSH+xSnozGB7u8+56HqstGZlnoRmLu3tKw
    Qlmhu1ppSocDWGrp6dxXe+7L7PO7n/li2uNHf/ERr2215crMotCNNNfT5oM+FbqrlaZ0OAAAYF6U
    uXVdl7rp/W9f7c2yzC8x/2CcAGfx/zhBrX1QWcxNWGpen9P2BLQ57UOMsqotF1Horlaa0uEAllp6
    OvfVnvsy+zRfS9rMyz6rQjdSjhCOX4yOKpQVuquVpnQ4AABgHpS5w3VZ6qb3v321N8sy38TI2DgJ
    bcqfnh0pT1iz2roi45yANqd9GDQHoUJ3tdKUDgew1NLTua/23Jf5pDzuxy8ma7+ULDPLQnfcD/pU
    6K5WmtLhAACAWVPmttNVqZve//bV3izL/BMjisoTx2Gi/I0/ca2tJzLOCWhzZFVMAVGbg1Chu1pp
    SocDWGrp6dxXe+7LfBKvJeVfm8QvIYeVurMsdCPlLz1jO2J7astFFLqrlaZ0OAAAYJaUuePpotRN
    73/7am+WpbvESW1MrRAfLBMnj2Vi1NEd9z1SvV2ZGK0UJ6yROGmuLVMm7i8vH/m5X/2DVy0zzjrj
    +rbLyvGkKR0OYKmlp3Nf7bkv88sHH3ws7fkjUZYOKmpnXeiO80GfbdcZUegufprS4QAAgFlR5k5m
    3qVuev/bV3uzLCKrlaZ0OICllp7OfbXnvsw3ZVmaRREal8ec8fmXgzGlUGnaQjcSvwgtxX3VllPo
    rlaa0uEAAIBZKMvcXs7fcu/DN8wrv7C1f226207VtmVW+cVzezdtbO0/M49SN73/7au9WRaR1UpT
    OhzAUktP577ac1/mn/hLkpi+p61YdtAHmY1TvtY+6LM2hZBCd7XSlA4HAABMK8rIosztJFEgp7vv
    xMbW/uO17Zhf9p5Ndz219P63r/ZmWURWK03pcABLLT2d+2rPfekmUaTGdD4x9UE5t24pRtS2mcIn
    itTIJx//SnWZMjE1UV4+8u57HnrVMuOsM6aRyMuO+rA1OZ40pcMBAADTihGzacqAi/PP3lO9f6P0
    PJ/uvhPpPiOVbZpHHr4/3fXU0vvfvtqbZRFZrTSlwwEstfR07qs99+X4ElMuRGrztItMmqZ0OAAA
    YJnE3LynjrHQTV8ulfT+t6/2ZllEVitN6XAASy09nftqz30RWa00pcMBAADLRKE7vvT+t6/2ZllE
    VitN6XAASy09nftqz30RWa00pcMBAADLRKE7vvT+t6/2ZllEVitN6XAASy09nftqz30RWa00pcMB
    AADLRKE7vvT+t6/2idAislppSocDWGrp6dxXe+6LyGqlKR0OAABYJgrd8R0cHDyV3gMfuv7X/7D6
    hllEViPxS5umdDiApZaezn2157+IrFaa0uEAAIBlotAd38HBwYX0HvjQh/74s9U3zCKyGrnjvkfS
    T/uR+KVOOhzAUktP6b7a819EVitN6XAAAMAyUeiO7+Dg4Ex6D3zo0g9/fPmDDz5WfdMsIsud925/
    +vJL3/9B+mk/0jsGPJAOB7DU0lO6zxRCIqufpnQ4AABgmSh0x9d77/umg4ODZ4/eBgPrpPezf6n3
    z7XpcABLrflaZgohkdWOKYQAAFaEQncyvZPgm9L7YGCN9H7270yHAVh6vefzVVMIndv/XLUEEpHV
    SPzVSal3DHgmHQ4AAFgmCt3J9d4Ev6eXF9N7YmCF9X7WL/VyJv34w0roPbXPHj3Dr7jzn/2bahEk
    Isudm3/zU5f/4lvfTj/pR3qva6YQAgBYRgrd6fTeC8f0C+d7eeborTGwSno/2y/GCW/vv6ZZYOX0
    ntemEII11fvZjymE3pYOBwAALBOFLgCsr4ODg/cc1TvAmjmbDgMAACwbhS4ArLeDg4MzabQesAZ6
    P+93px9/AACWkUIXALh8+fK1B0dTCJmCAVZQ72c7phC60Ms70o89AADLSqELAAAAALAkFLoAAAAA
    AEtCoQsAAAAAsCQUugAAAAAAS0KhCwAAAACwJBS6AAAAAABLQqELAAAAALAkFLoAAAAAAEtCoQsA
    AAAAsCQUugAAAAAAS0KhCwAAAACwJBS6AAAAAABLQqELAAAAALAkFLoAAAAAAEtCoQsAAAAAsCQU
    ugAAAAAAS0KhCwAAAACwJBS6AAAAAABLQqELAAAAALAkFLoAAAAAAEtCoQsAAAAAsCQUugAAAAAA
    S0KhCwAAAACwJBS6AAAAAABLQqELAAAAALAkFLoAAEArP33b9htOnt6+/eTp3bt7uSgiMiT3n9zc
    PtM7brwlHUIAmBGFLgAAMNRrb91+28nN3QdOnt69LCIybk6c3nn8xOntd6VDCgBTUugCAABVhyNy
    N3cv1AoaEZFxc1jsvvej16ZDDAATUugCAACvEn8mffL0zlO1UkZEZNKc2Nx98XWb2zekQw0AE1Do
    AgAAV4ky98Tp3WdqZczf/of/4kdn/+ix737mi1/768jLr/zo2YODg/8sIhL5/Fef+1YcG377kSdf
    +vnf+OQrtePIyc2dS68/vXtTOuQAMCaFLgAA0BfTLNTK3P/uHz3wwyhpagWOiMigRMH7d+/du9Q8
    pkSpG/Nzp0MPAGNQ6AIAAH21OXN/6Z/+ycu1okZEpG0+sv+5bzePLfHLo9fcdv6adPgBoCWFLgAA
    cChGyzULF2WuiMwqMQ1D8xhzcnP7TDoEAdCSQhcAADjUHJ0b81/WShkRkUnzP/7On32/PM6c3Nx5
    1ihdgPEodAEAgNe89rb73nRVydJLzH1ZK2RERCbNX73wvef+xu2/dVAea3xAGsB4FLoAAMBrTty6
    c2dZsMSHoNXKGBGRafOqUbqnd+9PhyIAWlDoAgAArznxvt3zZcHyoU999ju1IkZEZNr8689/9fny
    eNPLk+lQBEALCl0AAOA1J0/vXiwLlsee+rrpFkRkLnnhe5eeK483JzZ3X0yHIgBaOI5C953bF95w
    eJ9b+47ZAACwCE6c3n2mLFj+4zde/EatiBERmUX+5t+7/yflMScdigBoYWProbuOCt2H704Xzd0t
    2xfeEve5sbX/TLoIAAA4Ts1Ct1bAiIjMKtf+g9/7cXnMSYciAFrYOLd39rBc7f2bLpo7hS4AACwY
    ha6IdBmFLsDkNrb2t49G6O6dSRfN3cY9F952dJ/7F9NFAADAcVLoikiXUegCTG7j3N6Fw3J1a/9d
    6aK5u+Xeh29Q6AIAwAJR6IpIl1HoAkzu1Ln9J6NcjVGz6aK5y4VulMnpIgAA4DgpdEWkyyh0ASa3
    cW7vUpSrt33w4jXpormL0cBxn72cTxcBAADHSaErIl1GoQswmY17Pv2mo2J179l0USc2th666+h+
    H747XQQAABwnha6IdBmFLsBk8kjZrqc+2PjI/gOHhe69D70nXQQAABwnha6IdBmFLsBkNrb2t9MI
    3TPpok707rPzeXsBAIAhFLoi0mUUugCTOa5i9dTW/otxv+/cvvCGdBEAAHCcFLoi0mUUugDj68+f
    u7X/YrqoE8c1by8AADCEQldEuoxCF2B8Mc3C4ejcj+w/kC7qxC+e27spFcmPpIsAAIDjptAVkS6j
    0AUY36k03UJ8MFq6qBO5SD517uH700UAAMBxU+iKSJdR6AKMJ+bMPSpV95697YMXr0kXd2Lj3N6F
    uO+Nrb3b00UAAMBxU+iKSJdR6AKMZ2Nrf/uoVN3fThd1Jn8g2i3bF96SLgIAAI6bQldEuoxCF6C9
    +FCyjXN7lw4L3XsuvC1d3Ik8Mnhja/+ZdBEAALAIFLoi0mUUugDt9Ufnntu7kC7qzMbWQ3fFffdy
    Pl0EAAAsAoWuiHQZhS5AOzFCNkbnHqbj0bnh1Ln9i0cjdM2fCwAAC0WhKyJdRqEL0M6VDyTrfu7c
    +PC1PNWD+XMBAGDBKHRFpMsodAFG689fezg699NvShd35pZ7H74hlcnmzwUAgEWj0BWRLqPQBRjt
    VH+6g+5H54befZ8/zvsHAACGUOiKSJdR6AIMd2pr/12HZeoxjc6N6RZ62/Di4TYcw9y9AADACApd
    EekyCl2AwaLAjWkOokw9dW7vTLq4U6fufeg96f6fShcBAACLRKErIl1GoQtQd/hBZFv7jx+VqftP
    xtfpqk5d+TC2h+5KFwEAAItEoSsiXUahC1B36tzD96eRsc/esn3hLeniTr1z+8IbYqqHw0L3GKZ7
    AAAAWlDoikiXUegCvFpMr3BYoh6WqXvvSBd3buPeh+48LJW39h9JFwEAAItGoSsiXUahC3C1Xzy3
    d9PRyNyY5mDv9nTxsehtw5OH23LvQ+9JFwEAAItGoStd5/nnn3/uT//0T/86p7aMrG4UugBX/MLW
    /rWntvZfPCpz97fTxceitx3vOiqW9549rvl7AQCAFhS60nWixO099aLIO0xtGVndKHQBjsQ8uRtb
    +88clqgLMMVBf3Tuub0z6SIAAGARKXSl6yh01zsKXYDXvOaWex++IY/MjSI1PowsXXUsYt7eVOYa
    nQsAAItOoStdR6G73lHoAuuu/8FjR7l43GVu2Di3dyEVukbnAgDAolPojp8vfelL3zx79ux3br31
    1pevv/76V9761rf+6I1vfONP4v933HHH9++6667vPv3009+o3bZtYp7Zj33sYy+++93vPryP17/+
    9T+J+4n/x2Uf/vCHv/31r3/9udptm+lie8fJcRe6sT9i/9Wuk/lHoQusqxj5eurcw/dfKXMfvj9d
    daw27rnwtsPt2dp/0ehcAABYAgrd9nniiSe+FWVob7f1y8hB+amf+qmDD3zgA9+NYra2rkF5+eWX
    n42CNW5fW28zcR+19US62N5JclyF7oMPPvhCuT9qy8j8o9AF1lGMwo3RuFGcbpzbuxSjdNNVxy6P
    zj3uD2UDAABaUui2T4yY7e2yfhEZo1tj5GsUlJEY9RmXlWVsjIRtO5I2lrvuuut+WN5HXseNN974
    g1h3jKp9+9vf3l9mZ2fnpdq6IvPe3klzXIVuFOXHcb9ydRS6wLqJ+WmLDz97MebPTVcdu972vCtv
    18Y9n35TuhgAAFhkCt32aRaktWUi8Sf9ZTEbZWxtuTJRor75zW/+cbn+GDE7qFyNy6OQjRG9tesj
    89zeaaLQXe8odIF1ccv2hbdcmZv2ME/GZenqYxfTK+SieZFGDAMAACModNunbUEaiekOymXjtrXl
    cmLkbV42Rsx+/OMfH7p8m8xze6eJQne9o9AFVl2MdI3pC3KRezjFQu/rRfjws9Kpcw/fnYvmdBEA
    ALAMFLrtM05BGokPL8vL3nzzzZdqy0RipG253lmUuZF5be+0mbTQjfl947aPPvrot2rXj8osCt1p
    t0EUusDqOhzxem7vbExf0C9zP7L/wCKNys1+YWv/2iiaD7fxngtvSxcDAADLQKHbPuMWpGWBGB/G
    VVsmUn5Q17Dlxs28trdNYhqHWF8Uw3kqiRh5HCORm/ME124fefrpp78R66jNKxx5/etf/5O4ftQH
    uTWnsqgl1lO77ay2Qa5EoQusmhiRe+rc3plens1Fbi8XF7koPbW1/8hhmeuD0AAAYPkodNtn3IK0
    uXxtvtu9vb3ny2VitG5zmUkzj+1tkwcffPCFKDrLdQ1LbR0xAra2bC1xX1Eg19YTmbTQneU2yJUo
    dIFVENMnbGzt3Z6L0SJP/uK5vZvSYgvp1L0PvedoW/eeXbRpIAAAgBYUuu0zbkG6s7PzUrl8rSB9
    //vf/718fZSCk5aotcxje0clPsgt3z5G5MbXMUVBXlf825xiormOvNwb3/jGn8RI4diuWEf+gLiY
    7zceW1nUxgjaQdsbxWzc/tZbb+1PKRGJy3JiJG7zdrPcBrkShS6wrE6d23vHxtZDd21s7T/eKHEj
    F6PgTYsurJj+oT8lxL0PvSddDAAALBOFbvuMW5CWZe2gKQzKP+V/+9vf/sPaMpNmHts7LDEyN98+
    ytwYfVxbLorRvFyktkwkl6eDEtMclIXqqNHNMQq3zf2WmfU2yGwK3VvuffgGEZF55tTW/rtiPtyY
    A/ewrE3zzTbyZEy1EFMupMPTQjuc4/dKGX0+XQwAACwbhW77jFuQlmXtoA8ZK9c36w8im8f2Dkt5
    +2Ef7Na20G2TcrRvfKhbbZmcSQrdNhlnG2T6QnfAyDgRkbmnd/x5pvfv+RjZuiwlbinmyz16LHtP
    RbmbLgYAAJaNQrd9xilIy9Gqkdpo1fgz/3KZO+644/vNZabJrLd3WMrbxzQFtWVyZlnolnMQjxpV
    PK9Cd5xtkOkL3aJcuSgiMq/EyNwYoRvlbYzYXfYCNEYc9x7X5Rhp/Atb+9emiwEAgGWk0G2ftgVp
    FHxRaublBo3ajHlYy/XVPpgr0iwim4ntqt1u1ts7LNdff/0r+faDHkfOLAvdcdY1r0J3lo9nHTKr
    Qjd9CcAI5by5yzDPLwAAMIJCt32aBWkUeWWiMIwytFwmRmwOmoe1WQQOKmZnVehOu73DEh/oltcR
    H0JWWyan+bhrywxL3D4eW2x/Oc3DqHXNstCddBtEoQvQpXduX3hD75j5ZDp2mjcXAABWgUK3fZoF
    6bDEh4KdPXv2Oy+//PKztXVFvvSlL32zvE0sX1supmbIJWwk5qctb9e20B2WNts7KPHBYOW6Yntr
    y+XEYyiXry3TTDzmG2+88Qfl7Wqp3TZn2kJ3FtsgCl2ArsQ0Eb3jZUwhEcfNJ82bCwAAK0Kh2z7N
    gjSmGcgjU9/85jf/OL7+wAc+8N34kKw2o1xjmXJ9befQbc6927bQnXZ7ByVG5Jb3U1umzDiFbpTM
    5XQQ8f9bb7315djmWE85f+2odU1a6M5yG0ShC9CVjXN7F+J4GR/mFiN108UAAMCyU+i2T7MgrS0z
    bmJkbF7fzTfffKm2TDOTFrq1ZWaRcsRwPJ7m9c20LXSjbM7LxFQQ8XiaI4jHKYcnKXRnvQ2i0AXo
    wqlzD9+fy9yYQzddDAAArAKFbvvMoyAt5199+9vf/sPaMs0sWqHbLDRjCobacjltCtAYrZyvj300
    aJ3zLHTnsQ2i0AWYt41ze2cPj5Vb+y9u3HPhbeliAABgVSh022ceBen73//+7+X1xXQIbeawXbRC
    tzl1RMwNXFsuZ1QB2nx8wwrieRW689oGUegCzFMuc3v/Xrrl3odvSBcDAACrRKHbPvMoSJvzr8a8
    rLXlyixaoRspp46Ix1RbJmdUARr7IF8XI2Ob15eZV6E7r20QhS7AvPSnWTi3d+kXz+3dlC4GAABW
    jUK3feZVkMbcrHmd8YFbo0bpLmKhW04dMerD3UYVoOWo5fjwseb1ZeZV6M5rG0ShCzBrt33w4jX5
    A9BimgVlLgAArDiFbvvMqyAtR4NG4kPGasvlLGKhWz6GGK07rJQeVYCWH0T27ne/e2Zl6tmzZ79T
    LjtsG+e1DaLQBZild25feEPvmHjx6Ni496w5cwEAYA0odNtnngVpfCBaXm8UosNK3UUsdKMcjdHF
    +b6iEK0tFxlVgD744IMv5Oti9HLz+jLjlKnleiNx29pykXltgyh0AWZl455Pv6l3PHwyjokbW/vP
    3LJ94S3pKgAAYJUpdNtnngVpfJBYfChauf5BRe0iFrqR5v3FFAe15UYVoPEha+WcvDs7Oy81l8kZ
    p0xt7rdhUynMaxtEoQswCzGtQkyvkI6JT8ZI3XQVAACw6hS67TPvgjRK3Te/+c0/Lu8jSsUbb7zx
    B1GORmJ+2nIkbGRRCt1IOfdsJEYexzbHtsSo43gM5WjkSG09zdG0Ub4++uij3yqXif0V6y6XK6+v
    JdZTLn/99de/EttUG607r21Y9yh0ASZ3OF/u1v52PhZufGT/AWUuAACsGYVu+3RRkMbI0GZBOCxR
    ANfKyMhxFLqRKEKbo40HJcrd2joiMSq2WV4PS5SztfWUef7555+L5Zq3HTSaeB7bsO5R6AJMJqZU
    2Njaf/ywyD23d2nj3ofuTFcBAADrRKHbPk888cS38kjZQQXgrBIjP+ODxmJ0bszh2vtW9UvD6667
    7ocxWnRvb+/52m1zutzeZqKYjg8hi+3MI3KjGI3C8+abb74U18UUCLXblom5eaNUjdvEbXNRHEV2
    fB2J/RT3V7v9oMQ6Y9vy9gwqxSPz2oZ1jUIXYHyntvbfdWWKhb2nfPgZAACsMYWuiHQZhS5Ae4cf
    fLa1/0g+9pliAQAAUOiKSKdR6AKMdjRX7kN3xdQKh8e9rf0XN7b2bk9XAwAA60yhKyJdRqELMNwt
    9z58Q0yrkI93vZyPkbrpagAAYN0pdEWkyyh0AeoOP/TsI/sPXCly956KcjddDQAAcEShKyJdRqEL
    cLX4gLONc3sX+kXu4fQKD92VrgYAALiaQldEuoxCF+DI4dQK5Qeendu7tLG1v216BQAAYCiFroh0
    GYUusM6OPuxs7/beceyiIhcAAJiIQldEuoxCF1hHRx90tn8+plPIx7HDqRXO7Z1V5AIAAGNR6IpI
    l1HoAuviF7b2rz117uG7N7b2n+mXuIfZe6qXM+/cvvCGtCgAAEB7Cl0R6TIKXWBVxUjbU/c+9J7e
    Mep8s8SNr2NahSh50+IAAACTUeiKSJdR6AKrYuOeC287tbX/rihqe8elJ/PxqZ+j6RXOx3QL6SYA
    AADTU+iKSJdR6ALLID68LIrYnJgiIea77R1/Lh5NmdAobyNHc+JeOFz2ngtvS6sCAACYLYWuiHSZ
    WRW6IiILkCdPbe0/srH10F2nzu29Ix2mAAAA5kuhKyJdZtpCd2Nr//FKqSIiMo9cvJKH748RujFa
    1zy4AADAsVLoikiXmbbQBQAAAFhrCl0R6TIKXQAAAIApKHRFpMsodAEAAACmoNAVkS6j0AUAAACY
    gkJXRLqMQhcAAABgCgpdEekyCl0AAACAKSh0RaTLKHQBAAAApqDQFZEuo9AFAAAAmIJCV0S6jEIX
    AAAAYAoKXRHpMgpdAAAAgCkodEWkyyh0AQAAAKag0BWRLqPQBQAAAJiCQldEuoxCFwAAAGAKCl0R
    6TIKXQAAAIApKHRFpMsodAEAAACmoNAVkS6j0AUAAACYgkJXRLqMQhcAAABgCgpdEekyCl0AAACA
    KSh0RaTLKHQBAAAApqDQFZEuo9AFAAAAmIJCV0S6jEIXAAAAYAoKXRHpMgpdAAAAgCkodEWkyyh0
    AQAAAKbQLHT/6oXvPVcrYUREZpG/cftvHZTHnHQoAgAAAKCNE6d3Hi/Llc9/9blv1UoYEZFp8/Ir
    P3q2PN6c3Ny5lA5FAAAAALRxcnP3Qlmw/Mt//+UXa0WMiMi0iV8Ylceb+AuBdCgCAAAAoI2Tp3fv
    LguWzZ1Pv1wrYkREps2HPvXZ75THm/iFUjoUAQAAANDGyc3dd5QFS8xvaR5dEZl1YrqFv/X+f/6T
    8nhz8vT27elQBAAAAEBbzQ9GO3P+4vdqhYyIyKT5yP7nvl0eZ2L+3Nfedt+b0mEIAAAAgLZObm6f
    uapo6eVTn/3KC7VSRkRk3Pxv/+mvv/k3/979V43OPXH6o9vpEAQAAADAWG47f83JzZ2rPn0+pl74
    15//6vO1ckbGShTj3+3llV4OLgNLr/ejfLGX+3v/vb2Xa9KRFAAAAKA7r9vcviH+BLosdSPxIUYx
    9+VBvayUwYl5iH9w2P4AK6v3c/5UL+9Ih1IAAACA7sQHFDUL3Uh8kNHZP3rsu5//6nPfSmWlDM83
    evlJ6nuAFdf7eb/U++eGdCgFAAAA6M6JW3furJW6ZWI6hp//jU++IvV84WvfVObCmjk4OHim988b
    0qEUAAAAoDuvP71704nN3RdrZa4Mzx33PXLU7hSeePrZy+/d/vTl686cr95GRJYnb/2V37l8829+
    6vInHv1y+gm/4uDg4O50GAUAAADo1k/ftv2Gk5u7ZxW74+V3P/PFVO0cefTLf1ldTkSWP82f94OD
    gyfTIRQAAADgeBwWuzG37ubuA8rd0fnC176Zqp0j777noepyIrL8idG6pZhLNx06AQAAAFgG6cOR
    +n72lz9WLYJEZDXSlA4FAAAAACyD1On01QogEVmdNKVDAQAAAADLIHU6fbUCSESuJKYlibmmc2rL
    LHKa0qEAAAAAgGWQOp2+WgEkIldy5z/7N+mn5UhtmUVOUzoUAAAAALAMUqfTVyuARORKFLoAAAAA
    HJvU6fTVCqDjzM/ccd/lm3/zU/0s4oe2Xf/rf3jVNsY215aT1YhCFwAAAIBjkzqdvloBdBx57/an
    L//Ft76dtupqz730/cu/9ol/N1G5+4lHv5zWcvlw/bVlRuXnfvUPLp/b/9zA7QuXfvjjy598/CuH
    y9bW0TalKBJry8w7MWfsbz/y5OWv/NULh4V1bZl1ikIXAAAAgGOTOp2+WgHUZWJ0a1m6DhOlbm0d
    gxLrfun7P0i3PnLj2Qeryw7KdWfOp1teLcrd+ICsKHJL8XWUv5OO2i0dR6EbBW5JoavQBQAAAOAY
    pU6nr1YAdZkYCVqKMjRKxBiNG+VrjBaNka9RlL71V36nuo5Bids2xfpryw5Ks9CtlcqxrbHe0u9+
    5ouvWq5NSgrdxYhCFwAAAIBjkzqdvloB1FViLtrSHfc9Ul0uMs10CzFlQx6pO+60C81Cd1jBOYsy
    tKTQXYwodAEAAAA4NqnT6asVQF3l4T//D2krLh9OX1BbZtKU0y3ECN/yvsaZdmGcQjdS3s8XvvbN
    6jLDUlLoLkYUugAAAAAcm9Tp9NUKoK4SH7qVffDBx6rLTJpyuoUo5M78/r9NX4037cK4hW58uFtp
    3Ll0S20L3fggthjd/KE//uxhosCO0cnx/9ieGAldu12ZWEc8tphSohRfx+WRNuuJaTFi38d9R0kf
    iZI7tq/Nvojb5/uL1JbJiVHb5bJt93U8jti3Md1HuZ8G3X5QoRv7LD/WWGacbegyTelQAAAAAMAy
    SJ1OX60A6iJRfJWGTbcwScoPWoviryxmx5l2YdxCtzmNRJsStExpWKEbxWcUiWUpPkyUqsPmIG7z
    wXSjRlFHKdr8kLhS7PfYn7Xb5gwqT2uJ70Vp1Lrj+hg1PUiM6K6N3m5uU/xyYNh+j305yRQh80pT
    OhQAAAAAsAxSp9NXK4C6SBRepVmO0I2yOE+3UJaQZQnXdtqFcQvd5vLjFtWlYYVus2QMUdpGyRuJ
    Ucjx2EtRtsao0tr6Yv/H8s3CM76OyyMxorV220g5AjrE8nFZs3SO+YyHldzzKnTj+x33XXri6WcP
    H1Nsa5TNsX9qI2xr+zqWzfsl374U9zVoX3edpnQoAAAAAGAZpE6nr1YAdZWyYItyrbbMJIkSNSuL
    4ig5s7bTLoxb6EZxWJrXCN1myThoRGhsT1k2RvlYWy6nWZKOeryR5jQTtdtEsZtF2T5otPA8Ct24
    r3Lk8J994WvV4nbQ96q5TVFU1/Z3TL+Qf5EQomBvLnMcaUqHAgAAAACWQep0+moFUFeJ0ZGlWY3S
    jTlks7LkKwvAttMujFvoNsu/cedULY1T6NaWyWkWrsNK5mZJOurxRsoPghtWlEdpn8XcvLVl5lHo
    ls+zKLTH/Z6Ms03NOYjbjgSfZ5rSoQAAAACAZZA6nb5aAdRVmiMnQ5SDg4q5NomyLq8z/tS/eX05
    KrhN2TZuoRuFYTbJqOPSrArdSGnYNBDjFrpRDpeGzR1bFsvxfagtM+tCt/kci1G0zWVGZZxtak4l
    Muu5oSdJUzoUAAAAALAMUqfTVyuAukwUbM1SN8QHS01S7JbTLdRGi5Yf/tVm2oVxCt3yvsOoMrSW
    0iwL3XLahZj+oLZMZNxCt5zGIubbrS2T0yw7a+XqrAvdcvuiRB53dG5k3H1d/tJg2L7uKk3pUAAA
    AADAMkidTl+tAOo6UeyVJVgp/lx+0HyrtZTTLdRG4JajRNtMu9Cm0I2SsCwOw+9+5ouvWq5NSstQ
    6JYjkod9aFpO+aFrtSk2Zl3oltNBxHOjeX2bzGtfd5WmdCgAAAAAYBmkTqevVgAdR6IUjflHa8Vu
    fNBUmw8XK6dbGPQn/eUyYVRh2Sx0o5CMEjOnLCizKHcnGQkaKS1DoRvTWmRt5kCODyTLaqX3rAvd
    8vszabmq0AUAAADg2KROp69WAB1n4s/yowSLErfUptQtpzyIqRVqy0RGlYplmoXuMDFnbpvieVhK
    y1DoluX4sO3NKae8iO9D8/pZF7rl82jQB7GNikIXAAAAgGOTOp2+WgG0CIlityxew6gPGSuXH/bh
    V2d+/9+mpQaP5M1pFroxCjVKupy4n2lL3DKlRS90m3Pijlvo1ubcnWWhO8n21TKvfd1VmtKhAAAA
    AIBlkDqdvloBtEiJErc0qDyN8q4cLRq3K6dGKNOcJmFYadksdIctO4uUhhWQ8yoZV6nQjWkvSm22
    r5Z57euu0pQOBQAAAAAsg9Tp9NUKoEVKs7Qb9GfzzdJtHMOmXVDoDn+85ZQGbQrTstCNDyxrXj/O
    4xpV6EbKOZnbzPFby7z2dVdpSocCAAAAAJZB6nT6agXQIqU5CnRQQVZOtxDLRNk3LJ98/Ctp6eHT
    Lih0hz/ecT90LEZIZ7/9yJOvun7WhW45wrt2f22i0AUAAADg2KROp69WAC1SmoVubYRuOd1C/Btf
    N5dppm1xqdAd/njLIn3YB9HllNtRGzE760K3LO6jTG5e3yYKXQAAAACOTep0+moF0CLlxrMPpi09
    Uvuws7Jwi4Kxef2glH+OP2jaBYXu8Mcb68riPmrL5LTZl83vd62kzWlT6MYvAErD1jcoCl0AAAAA
    jk3qdPpqBdAipRxhGd76K7/zqmXKUaJnfv/fvur6QSnncx007cK6Fbo/96t/kJY6MmwbIvH9KD+M
    blhhWm5z7QPRIs0R2bUCP6dNoRsfjFYW95MUrApdAAAAAI5N6nT6agVQVxk1WrI5urL2J/3NQrFW
    +A5KlIWlWlm7boVupNyfUajXlikTH26W1T7oLBJFbbkNgz7cLlIuN2yahDaFbiTmzs3isQ0riWtR
    6AIAAABwbFKn01crgLpKfGBVlF/n9j93WM7l3HHfI1eNug0xyrI2N26MyM3GnSM1Rm+W5WVt2oV1
    LHTLgjb2z6jHHNMklPsxCtTyexX7sFxnbEvs+3IdZcrvaYgiv/m9j+K+LGrDoEI3ln3p+z9ISx09
    piiUYzRyXB/bEiXvoP2i0AUAAADg2KROp69WAHWR5p/2DxNlbpSGtfVEiZuNM91CTjmlQ23ahXUs
    dON7Uxa02bDCPArRsjQNsXxMrVD6yl+9MLB4LVN+X7P4BUBcHuuoGbbeuK65LTW1769CFwAAAIBj
    kzqdvloB1EVi1GSUXeX8pjUxerc2MjevozTOdAs5MRq41Cz01rHQjUSp2yxOhxW6ket//Q9fVeqW
    opAd9L2sJUbRDltfiOtjZHUebTssMRI3lh0kHm9tOgaFLgAAAADHJnU6fbUCqOtESZr/5D0SRV5c
    NuzP8iNRDsZykUEjeEcl7iOvI9Ic5dm8fpxCcpKU9zWsoI7rymVry5SJ/ZOXbTNCNieK0ny7toV5
    rD+K8vz9jNtOut/y44xStfn8iNRu0yZx2w8++Njh+t67/emhz5+u9vW80pQOBQAAAAAsg9Tp9NUK
    IBFZnTSlQwEAAAAAyyB1On21AkhEVidN6VAAAAAAwDJInU5frQASkdVJUzoUAAAAALAMUqfTVyuA
    RGR10pQOBQAAAAAsg9Tp9NUKIBFZnTSlQwEAAAAAyyB1On21AkhEVidN6VAAAAAAwDJInU5frQAS
    kdVJUzoUAAAAALAMUqfT9zN33FctgURkNdKUDgUAAAAALIODg4OnUq9z6Ppf/8NqCSQiy5/4hU1T
    OhQAAAAAsAwODg4upF7n0If++LPVIkhElj933PdI+kk/Er/QSYcCAAAAAJbBwcHBmdTtHLr0wx9f
    /uCDj1XLIBFZ3rx3+9OXX/r+D9JP+pHez/8D6VAAAAAAwDK4fPnymw4ODp49qneAddH7ub/U++fa
    dCgAAAAAYFkcHBzcdFTxAOui93N/ZzoEAAAAALBsDg4O3tPLi6nrAVZUjMzt5Uz60QcAAABgWV0+
    mn7hfC/PHFU/wKqIX9j08kDvv6ZZAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAuvKa1/z/AQiJJm6tW8hzAAAAAElFTkSuQmCCUEsDBBQABgAIAAAAIQAvXG393wAAAAoBAAAP
    AAAAZHJzL2Rvd25yZXYueG1sTI/BTsMwEETvSPyDtUjcqNOCoiaNU1UgEEdIERU3N16SgL0OsdOm
    f8/CBS4jjUY7O69YT86KAw6h86RgPktAINXedNQoeNneXy1BhKjJaOsJFZwwwLo8Pyt0bvyRnvFQ
    xUZwCYVcK2hj7HMpQ92i02HmeyTO3v3gdGQ7NNIM+sjlzspFkqTS6Y74Q6t7vG2x/qxGp2B5U502
    Hw/d03bcZYvd42tavdkvpS4vprsVy2YFIuIU/y7gh4H3Q8nD9n4kE4RVwDTxVznL5gnbvYLrLM1A
    loX8j1B+AwAA//8DAFBLAwQUAAYACAAAACEAqiYOvrwAAAAhAQAAGQAAAGRycy9fcmVscy9lMm9E
    b2MueG1sLnJlbHOEj0FqwzAQRfeF3EHMPpadRSjFsjeh4G1IDjBIY1nEGglJLfXtI8gmgUCX8z//
    PaYf//wqfillF1hB17QgiHUwjq2C6+V7/wkiF2SDa2BSsFGGcdh99GdasdRRXlzMolI4K1hKiV9S
    Zr2Qx9yESFybOSSPpZ7Jyoj6hpbkoW2PMj0zYHhhiskoSJPpQFy2WM3/s8M8O02noH88cXmjkM5X
    dwVislQUeDIOH2HXRLYgh16+PDbcAQAA//8DAFBLAQItABQABgAIAAAAIQCxgme2CgEAABMCAAAT
    AAAAAAAAAAAAAAAAAAAAAABbQ29udGVudF9UeXBlc10ueG1sUEsBAi0AFAAGAAgAAAAhADj9If/W
    AAAAlAEAAAsAAAAAAAAAAAAAAAAAOwEAAF9yZWxzLy5yZWxzUEsBAi0AFAAGAAgAAAAhAEG9jDJX
    AgAA4wQAAA4AAAAAAAAAAAAAAAAAOgIAAGRycy9lMm9Eb2MueG1sUEsBAi0ACgAAAAAAAAAhAP+I
    CZOZlgAAmZYAABQAAAAAAAAAAAAAAAAAvQQAAGRycy9tZWRpYS9pbWFnZTEucG5nUEsBAi0AFAAG
    AAgAAAAhAC9cbf3fAAAACgEAAA8AAAAAAAAAAAAAAAAAiJsAAGRycy9kb3ducmV2LnhtbFBLAQIt
    ABQABgAIAAAAIQCqJg6+vAAAACEBAAAZAAAAAAAAAAAAAAAAAJScAABkcnMvX3JlbHMvZTJvRG9j
    LnhtbC5yZWxzUEsFBgAAAAAGAAYAfAEAAIedAAAAAA==
    ">
     <v:imagedata src="image008.png" o:title=""
      cropbottom="-1486f"/>
    </v:shape><![endif]--><![if !vml]><img width=455 height=198
    src="image009.png" v:shapes="Picture_x0020_2"><![endif]></span></b><b><span
    lang=NL style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    color:#0070C0'><o:p></o:p></span></b></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
    style='font-size:16.0pt;line-height:107%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'>It is obvious that the quality of the forecasted data
    is heavily relying on the consistency of the input data. Incoherent historical
    data will inevitably lead to a <span class=GramE>poor quality</span> forecast. <span
    class=GramE>Thus</span> GRIP cannot be held responsible for potential failures in
    the data, these include:<o:p></o:p></span></p>
    
    <p class=MsoListParagraphCxSpFirst align=center style='text-align:center;
    text-indent:-18.0pt;line-height:110%;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt;
    vertical-align:baseline'><![if !supportLists]><span lang=EN-US
    style='font-size:16.0pt;line-height:110%;font-family:"Arial",sans-serif;
    mso-fareast-font-family:Arial;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'><span style='mso-list:Ignore'>•<span style='font:7.0pt "LindeRegular"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><span lang=EN-US style='font-size:16.0pt;
    line-height:110%;font-family:"LindeRegular",sans-serif;mso-fareast-font-family:
    Calibri;mso-fareast-theme-font:minor-latin;mso-bidi-font-family:"LindeRegular";
    mso-bidi-theme-font:minor-bidi;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'>Mapping issues: product &amp; internal deliveries.<o:p></o:p></span></p>
    
    <p class=MsoListParagraphCxSpMiddle align=center style='text-align:center;
    text-indent:-18.0pt;line-height:110%;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt;
    vertical-align:baseline'><![if !supportLists]><span lang=EN-US
    style='font-size:16.0pt;line-height:110%;font-family:"Arial",sans-serif;
    mso-fareast-font-family:Arial;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'><span style='mso-list:Ignore'>•<span style='font:7.0pt "LindeRegular"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><span lang=EN-US style='font-size:16.0pt;
    line-height:110%;font-family:"LindeRegular",sans-serif;mso-fareast-font-family:
    Calibri;mso-fareast-theme-font:minor-latin;mso-bidi-font-family:"LindeRegular";
    mso-bidi-theme-font:minor-bidi;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'>Deliveries not/later invoiced.<o:p></o:p></span></p>
    
    <p class=MsoListParagraphCxSpMiddle align=center style='text-align:center;
    text-indent:-18.0pt;line-height:110%;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt;
    vertical-align:baseline'><![if !supportLists]><span lang=EN-US
    style='font-size:16.0pt;line-height:110%;font-family:"Arial",sans-serif;
    mso-fareast-font-family:Arial;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'><span style='mso-list:Ignore'>•<span style='font:7.0pt "LindeRegular"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><span lang=EN-US style='font-size:16.0pt;
    line-height:110%;font-family:"LindeRegular",sans-serif;mso-fareast-font-family:
    Calibri;mso-fareast-theme-font:minor-latin;mso-bidi-font-family:"LindeRegular";
    mso-bidi-theme-font:minor-bidi;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'>Data discrepancy between reporting <span class=SpellE>softwares</span>.<o:p></o:p></span></p>
    
    <p class=MsoListParagraphCxSpLast align=center style='text-align:center;
    text-indent:-18.0pt;line-height:110%;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt;
    vertical-align:baseline'><![if !supportLists]><span lang=EN-US
    style='font-size:16.0pt;line-height:110%;font-family:"Arial",sans-serif;
    mso-fareast-font-family:Arial;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'><span style='mso-list:Ignore'>•<span style='font:7.0pt "LindeRegular"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><span lang=EN-US style='font-size:16.0pt;
    line-height:110%;font-family:"LindeRegular",sans-serif;mso-fareast-font-family:
    Calibri;mso-fareast-theme-font:minor-latin;mso-bidi-font-family:"LindeRegular";
    mso-bidi-theme-font:minor-bidi;mso-ansi-language:EN-US;mso-fareast-language:
    EN-US'>Conversion factor differences.<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center;line-height:110%;
    vertical-align:baseline'><span lang=EN-US style='font-size:16.0pt;line-height:
    110%;font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center;line-height:110%;
    vertical-align:baseline'><span lang=EN-US style='font-size:16.0pt;line-height:
    110%;font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US'>The forecast
    accuracy is measured and monitored on a weekly basis. This is the performance
    KPI of the tool. Although granting 100% accuracy is hardly realistic, the aim
    is to strive to consistently get as close to it as possible.<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center;line-height:110%;
    vertical-align:baseline'><span lang=EN-US style='font-size:16.0pt;line-height:
    110%;font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US'>The
    forecast accuracy calculation method relies on the mean absolute percentage
    error (MAPE), also known as mean absolute percentage deviation (MAPD). It
    expresses the accuracy as a ratio defined by the formula:<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center;line-height:110%;
    vertical-align:baseline'><span lang=NL style='font-size:16.0pt;line-height:
    110%;font-family:"LindeRegular",sans-serif;mso-no-proof:yes'><!--[if gte vml 1]><v:shape
     id="Picture_x0020_1" o:spid="_x0000_i1026" type="#_x0000_t75" style='width:166pt;
     height:43pt;visibility:visible;mso-wrap-style:square'>
     <v:imagedata src="image004.png" o:title=""/>
    </v:shape><![endif]--><![if !vml]><img width=166 height=43
    src="image004.png" v:shapes="Picture_x0020_1"><![endif]></span><span
    lang=NL style='font-size:16.0pt;line-height:110%;font-family:"LindeRegular",sans-serif;
    mso-fareast-font-family:"LindeRegular";mso-bidi-font-family:Arial;
    color:#202122;display:none;mso-hide:all;mso-fareast-language:NL'>{\displaystyle
    {\mbox{MAPE}}={\frac {100}{n}}\sum _{t=1}^{n}\left|{\frac
    {A_{t}-F_{t}}{A_{t}}}\right|}</span><!--[if mso & !supportInlineShapes & supportFields]><span
    lang=NL><span style='mso-element:field-begin;mso-field-lock:yes'></span> SHAPE <span
    style='mso-spacerun:yes'> </span>\* MERGEFORMAT <span style='mso-element:field-separator'></span></span><![endif]--><span
    lang=NL><!--[if gte vml 1]><v:rect id="Rectangle_x0020_54" o:spid="_x0000_s1026"
     alt="{\displaystyle {\mbox{MAPE}}={\frac {100}{n}}\sum _{t=1}^{n}\left|{\frac {A_{t}-F_{t}}{A_{t}}}\right|}"
     style='width:24pt;height:24pt;visibility:visible;mso-wrap-style:square;
     mso-left-percent:-10001;mso-top-percent:-10001;mso-position-horizontal:absolute;
     mso-position-horizontal-relative:char;mso-position-vertical:absolute;
     mso-position-vertical-relative:line;mso-left-percent:-10001;mso-top-percent:-10001;
     v-text-anchor:top' o:gfxdata="UEsDBBQABgAIAAAAIQC2gziS/gAAAOEBAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRQU7DMBBF
    90jcwfIWJU67QAgl6YK0S0CoHGBkTxKLZGx5TGhvj5O2G0SRWNoz/78nu9wcxkFMGNg6quQqL6RA
    0s5Y6ir5vt9lD1JwBDIwOMJKHpHlpr69KfdHjyxSmriSfYz+USnWPY7AufNIadK6MEJMx9ApD/oD
    OlTrorhX2lFEilmcO2RdNtjC5xDF9pCuTyYBB5bi6bQ4syoJ3g9WQ0ymaiLzg5KdCXlKLjvcW893
    SUOqXwnz5DrgnHtJTxOsQfEKIT7DmDSUCaxw7Rqn8787ZsmRM9e2VmPeBN4uqYvTtW7jvijg9N/y
    JsXecLq0q+WD6m8AAAD//wMAUEsDBBQABgAIAAAAIQA4/SH/1gAAAJQBAAALAAAAX3JlbHMvLnJl
    bHOkkMFqwzAMhu+DvYPRfXGawxijTi+j0GvpHsDYimMaW0Yy2fr2M4PBMnrbUb/Q94l/f/hMi1qR
    JVI2sOt6UJgd+ZiDgffL8ekFlFSbvV0oo4EbChzGx4f9GRdb25HMsYhqlCwG5lrLq9biZkxWOiqY
    22YiTra2kYMu1l1tQD30/bPm3wwYN0x18gb45AdQl1tp5j/sFB2T0FQ7R0nTNEV3j6o9feQzro1i
    OWA14Fm+Q8a1a8+Bvu/d/dMb2JY5uiPbhG/ktn4cqGU/er3pcvwCAAD//wMAUEsDBBQABgAIAAAA
    IQAJ0/5fOQIAADUEAAAOAAAAZHJzL2Uyb0RvYy54bWysU01vEzEQvSPxHyzfm92EFMoqmypqCUJq
    S0ThFoEcr5218Be2k03Y7n9n7E1CCjfExfLM2M9v3jxPrndKoi1zXhhd4uEgx4hpaiqh1yX+8nl+
    cYWRD0RXRBrNSrxnHl9PX76YNLZgI1MbWTGHAET7orElrkOwRZZ5WjNF/MBYpqHIjVMkQOjWWeVI
    A+hKZqM8f501xlXWGcq8h+xtX8TThM85o+Ej554FJEsM3EJaXVpXcc2mE1KsHbG1oAca5B9YKCI0
    PHqCuiWBoI0Tf0EpQZ3xhocBNSoznAvKUg/QzTD/o5vHmliWegFxvD3J5P8fLH3YLhwSVYkvxxhp
    omBGn0A1oteSoZirmKcgWLushLeS7H3YQ6VdqpXZtfezxbuuK9sld4SidpjnXau7buk3Cn1rQzns
    vkK8lIyHp+OhGRS6i3lcuzYFcMGJdR2eujiPxvoCaD3ahYuKentn6HePtLmpgRWbeQv8wGtA95hy
    zjQ1IxUIM4wQ2TOMGHhAQ6vm3lTQINkEk6a1407FN2AOaJdMsT+Zgu0CopB8lY+vcrAOhdJhH18g
    xfGydT68Z0ahuCmxA3YJnGzvfOiPHo/Et7SZCykhTwqpnyUAM2YS+ci3l2Jlqj1wd6b3Lvw12NTG
    /cSoAd+W2P/YEMcwkh809P92OB5Ho6dgfPlmBIE7r6zOK0RTgCpxwKjf3oT+c2xsmkjUs+c4A824
    SP1EPXtWB7LgzaTI4R9F85/H6dTv3z79BQAA//8DAFBLAwQUAAYACAAAACEA/Voqz9oAAAAIAQAA
    DwAAAGRycy9kb3ducmV2LnhtbExP0UrDQBB8F/yHYwVfxF4UkZLmUqQiFhGKqfZ5m1uTYG4vzV2T
    +Peu+qAvswzDzM5ky8m1aqA+NJ4NXM0SUMSltw1XBl63D5dzUCEiW2w9k4FPCrDMT08yTK0f+YWG
    IlZKQjikaKCOsUu1DmVNDsPMd8SivfveYRTaV9r2OEq4a/V1ktxqhw3Lhxo7WtVUfhRHZ2AsN8Nu
    +/yoNxe7tefD+rAq3p6MOT+b7hcCdwtQkab454DvDdIfcim290e2QbUGZE38QdFu5sL2v1fnmf4/
    IP8CAAD//wMAUEsBAi0AFAAGAAgAAAAhALaDOJL+AAAA4QEAABMAAAAAAAAAAAAAAAAAAAAAAFtD
    b250ZW50X1R5cGVzXS54bWxQSwECLQAUAAYACAAAACEAOP0h/9YAAACUAQAACwAAAAAAAAAAAAAA
    AAAvAQAAX3JlbHMvLnJlbHNQSwECLQAUAAYACAAAACEACdP+XzkCAAA1BAAADgAAAAAAAAAAAAAA
    AAAuAgAAZHJzL2Uyb0RvYy54bWxQSwECLQAUAAYACAAAACEA/Voqz9oAAAAIAQAADwAAAAAAAAAA
    AAAAAACTBAAAZHJzL2Rvd25yZXYueG1sUEsFBgAAAAAEAAQA8wAAAJoFAAAAAA==
    " filled="f" stroked="f">
     <o:lock v:ext="edit" aspectratio="t"/>
     <w:wrap type="none"/>
     <w:anchorlock/>
    </v:rect><![endif]--></span><!--[if mso & !supportInlineShapes & supportFields]><span
    lang=NL><v:shape id="_x0000_i1025" type="#_x0000_t75" style='width:24pt;
     height:24pt'>
     <v:imagedata croptop="-65520f" cropbottom="65520f"/>
    </v:shape><span style='mso-element:field-end'></span></span><![endif]--><span
    lang=EN-US style='font-size:16.0pt;line-height:110%;font-family:"LindeRegular",sans-serif;
    mso-ansi-language:EN-US'><o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:19.2pt;text-align:center;line-height:normal;
    background:white'><span lang=EN-US style='font-size:16.0pt;font-family:"LindeRegular",sans-serif;
    mso-fareast-font-family:"LindeRegular";mso-bidi-font-family:Calibri;
    mso-bidi-theme-font:minor-latin;color:#202122;mso-ansi-language:EN-US;
    mso-fareast-language:NL'>where&nbsp;<span class=GramE><i>A<sub>t</sub></i></span>&nbsp;is
    the actual value and&nbsp;<i>F<sub>t</sub></i>&nbsp;is the forecast value.
    Their difference is divided by the actual value&nbsp;<i>A<sub>t</sub></i>. The
    absolute value in this ratio is summed for every forecasted point in time and
    divided by the number of fitted points&nbsp;<i>n</i>.<o:p></o:p></span></p>
    
    <p class=MsoNormal align=center style='text-align:center;line-height:110%;
    vertical-align:baseline'><span lang=EN-US style='font-size:16.0pt;line-height:
    110%;font-family:"LindeRegular",sans-serif;mso-ansi-language:EN-US'>To be
    mentioned, manual adjustments can be punctually brought to overwrite the statistical
    forecast <span class=GramE>in order to</span> reflect an event that could not be
    modelized by the algorithms.<o:p></o:p></span></p>
    

    
    </div>
    
    </body>
    
    </html>
    `;
  });

  return (
    <Card>
      <CardContent className="m-1" ref={ref}></CardContent>
    </Card>
  );
}
