import { OktaAuth } from "@okta/okta-auth-js";

const config = {
  okta: new OktaAuth({
    issuer: "https://linde.okta.com/oauth2/default",
    clientId:
      process.env.REACT_APP_ENV === "PRD"
        ? "0oaz99arryd41hF1D416"
        : "0oau7w0lyGWWeb4N8416",
    redirectUri: `${window.location.origin}/implicit/callback`,
    responseType: ["token", "id_token"],
  }),
};

export default config;
