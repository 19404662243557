import clsx from "clsx";
import { Store } from "../store";
import { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import { useOktaAuth } from "@okta/okta-react";
import "../App.css";
import { serviceCall } from "../services";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function Header() {
  const classes = useStyles();
  const { oktaAuth, authState } = useOktaAuth();
  const { state, dispatch } = useContext(Store);
  const isSuperUser = authState?.idToken?.claims?.isSuperUser;
  const handleDrawerOpen = () => {
    dispatch({
      type: "SET_DRAWER_OPENED",
      data: true,
    });
  };
  const logout = () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    oktaAuth.signOut();
  };

  // hey look pal hear me out; i just wanted to make this call somewhere i had access to okta auth and context, at root level
  // i didnt find a good solution yet okay
  useEffect(() => {
    if (!state.items.list.length && authState?.isAuthenticated) {
      console.log();
      serviceCall("metadata", "GET", null, (data) => {
        dispatch({
          type: "SET_ITEMS",
          data: data,
        });
      });
    }
  }, [state.items.list, dispatch, authState]);

  const userDiv = { display: "flex", flexDirection: "column" };
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: state.app.drawerOpened,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: state.app.drawerOpened,
          })}
        >
          <MenuIcon />
        </IconButton>
        {authState?.isAuthenticated && (
          <div className="w-100">
            <p className="logo-text">LindeInsight @ GRIP</p>
            <div className="avatar-container">
              <p className="avatar-text">{authState?.idToken?.claims.name} </p>
              <div style={userDiv}>
                <AssignmentIndIcon style={{ fontSize: "2.5rem" }} />
                {isSuperUser && <sub>SUPER</sub>}
              </div>
              <ExitToAppIcon className="logout-icon" onClick={logout} />
            </div>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;

// TODO:    <sub> {isSuperUser ? "Super" : ""}</sub>{" "}
