import React from "react";
import LineChart from "../components/LineChart";
import BasicData from "../components/BasicData";
import ForecastDataTable from "../components/ForecastDataTable";
import ForecastRangePicker from "../components/ForecastRangePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";

import { Store } from "../store";
import "../App.css";

export default function Trends() {
  const { state } = useContext(Store);
  const { authState } = useOktaAuth();

  const isSuperUser = authState?.idToken?.claims?.isSuperUser;

  if (state.app.selectedId)
    return (
      <React.Fragment>
        <BasicData />
        <LineChart />
        {isSuperUser && (
          <>
            <div className="divider"></div>
            <ForecastRangePicker />
          </>
        )}
        <div className="divider"></div>
        <ForecastDataTable />
      </React.Fragment>
    );
  else
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
}
