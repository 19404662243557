import { useContext, useMemo } from "react";
import { Store } from "../store";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import {
  Business,
  Group,
  GroupAdd,
  Shop,
  ShopTwo,
  Portrait,
  LocalShipping,
  ShowChart,
  Poll,
  Timeline,
  Sort,
  Person,
  PersonAdd,
  LocationCity,
  Public,
} from "@material-ui/icons";
import "../App.css";

const displayDataMapper = {
  "BULK": [
    [
      "SUPPLY_MODE",
      "PRODUCT_GROUP",
      "PRODUCT",
      "SHIPTO",
      "SHIPTO_NAME",
      "SHIPTO_CITY",
      "SHIPTO_COUNTRY",
      "SOLDTO_BUSINESS_AREA",
      "CUSTOMER_TYPE",
      "DELIVERY_TYPE",
      "TREND_PATTERN",
      "SEASONALITY_STRENGTH",
      "DEMAND_PATTERN",
      "PARETO_CLASS",
    ],
    [
      "SUPPLY_MODE",
      "PRODUCT_GROUP",
      "SOLDTO_NAME",
      "SOLDTO_BUSINESS_AREA",
      "CUSTOMER_TYPE",
      "DELIVERY_TYPE",
      "TREND_PATTERN",
      "SEASONALITY_STRENGTH",
      "DEMAND_PATTERN",
      "PARETO_CLASS",
    ],
    [
      "SUPPLY_MODE",
      "PRODUCT_GROUP",
      "PRODUCT",
      "TREND_PATTERN",
      "SEASONALITY_STRENGTH",
      "DEMAND_PATTERN",
      "PARETO_CLASS",
    ],
    [
      "SUPPLY_MODE",
      "PRODUCT_GROUP",
      "PRODUCT",
      "CUSTOMER_TYPE",
      "DELIVERY_TYPE",
      "TREND_PATTERN",
      "SEASONALITY_STRENGTH",
      "DEMAND_PATTERN",
      "PARETO_CLASS",
    ],
    [
      "SUPPLY_MODE",
      "PRODUCT_GROUP",
      "TREND_PATTERN",
      "SEASONALITY_STRENGTH",
      "DEMAND_PATTERN",
      "PARETO_CLASS",
    ],
    [
      "SUPPLY_MODE",
      "PRODUCT_GROUP",
      "TREND_PATTERN",
      "SEASONALITY_STRENGTH",
      "DEMAND_PATTERN",
      "PARETO_CLASS",
    ],
  ],

  "PGP": [[
    "SUPPLY_MODE",
    "MAJOR_GROUP",
    "MINOR_CODE",
    "SHIPTO",
    "SHIPTO_NAME",
    "SHIPTO_CITY",
    "SHIPTO_COUNTRY",
    "SOLDTO_BUSINESS_AREA",
    "CUSTOMER_TYPE",
    "TREND_PATTERN",
    "SEASONALITY_STRENGTH",
    "DEMAND_PATTERN",
    "PARETO_CLASS",
    "INDUSTRY_SUBLEVEL_1_NAME",
    "INDUSTRY_SUBLEVEL_2_NAME",
    "KEY_PRODUCT_AREA_NAME",
  ],
  [
    "SUPPLY_MODE",
    "MAJOR_GROUP",
    "SOLDTO_NAME",
    "SOLDTO_BUSINESS_AREA",
    "CUSTOMER_TYPE",
    "TREND_PATTERN",
    "SEASONALITY_STRENGTH",
    "DEMAND_PATTERN",
    "PARETO_CLASS",
    "INDUSTRY_SUBLEVEL_1_NAME",
    "INDUSTRY_SUBLEVEL_2_NAME",
    "KEY_PRODUCT_AREA_NAME",
  ],
  [
    "SUPPLY_MODE",
    "MAJOR_GROUP",
    "MINOR_CODE",
    "SOURCE",
    "TREND_PATTERN",
    "SEASONALITY_STRENGTH",
    "DEMAND_PATTERN",
    "PARETO_CLASS",

  ],
  [
    "SUPPLY_MODE",
    "MAJOR_GROUP",
    "MINOR_CODE",
    "CUSTOMER_TYPE",
    "TREND_PATTERN",
    "SEASONALITY_STRENGTH",
    "DEMAND_PATTERN",
    "PARETO_CLASS",
  ],
  [
    "SUPPLY_MODE",
    "MAJOR_GROUP",
    "TREND_PATTERN",
    "SEASONALITY_STRENGTH",
    "DEMAND_PATTERN",
    "PARETO_CLASS",
  ],
  [
    "MAJOR_GROUP",
    "TREND_PATTERN",
    "SEASONALITY_STRENGTH",
    "DEMAND_PATTERN",
    "PARETO_CLASS",
  ],
  ]

}

const iconsMapper = {
  SUPPLY_MODE: <Business />,
  PRODUCT_GROUP: <Shop />,
  MATERIAL: <ShopTwo />,
  SHIPTO: <Person />,
  SHIPTO_NAME: <PersonAdd />,
  SHIPTO_CITY: <LocationCity />,
  SHIPTO_COUNTRY: <Public />,
  CUSTOMER_TYPE: <Portrait />,
  DELIVERY_TYPE: <LocalShipping />,
  SOLDTO_BUSINESS_AREA: <Business />,
  SOLDTO_NAME: <GroupAdd />,
  TREND_PATTERN: <ShowChart />,
  SEASONALITY_STRENGTH: <Poll />,
  DEMAND_PATTERN: <Timeline />,
  PARETO_CLASS: <Sort />,
};

const getTitleTooltip = (key, boolean) => {
  switch (key) {
    case "TREND_PATTERN":
      return boolean || "Linear trend regression over last 24 months demand";
    case "SEASONALITY_STRENGTH":
      return (
        boolean ||
        "Variational measure classification on trend-seasonal decomposition indicating time series with inherent weak, moderate or strong seasonally recurring demand patterns"
      );
    default:
      return boolean ? false : "";
  }
};

const getTooltip = (data, key, boolean) => {
  switch (key) {
    case "TREND_PATTERN":
      return (
        boolean ||
        (data[key] === "Increasing"
          ? "Statistically significant increasing linear trend in demand"
          : data[key] === "Stable"
            ? "No statistically significant linear trend in demand"
            : "Statistically significant decreasing linear trend in demand")
      );
    case "DEMAND_PATTERN":
      return (
        boolean ||
        (data[key] === "Smooth"
          ? "Regular demand over time with a limited variation in quantity"
          : data[key] === "Intermittent"
            ? "Sporadic demand with no accentuated variability in the quantity of the single demand"
            : data[key] === "Erratic demand"
              ? "Regular distribution over time, but large variation in quantity"
              : "Sporadic demand, great number of zero-demand periods and large variation in quantity")
      );
    case "PARETO_CLASS":
      return (
        boolean ||
        (data[key] === "A"
          ? "Contributor of the first 80% of total last 12 month demand per time series hierarchy group"
          : data[key] === "B"
            ? "Contributor of the next 15% of total last 12 month demand per time series hierarchy group"
            : "Contributor of the remaining 5% of total last 12 month demand per time series hierarchy group")
      );
    default:
      return boolean ? false : "";
  }
};

function BasicData() {
  const { state } = useContext(Store);

  const data = useMemo(() => state.items?.item[state.app.selectedId]?.gts_info, [state.app?.selectedId, state.items.item]);

  // console.log("BASIC DATA", state.app?.selectedId, state.items?.item[state.app.selectedId], data)
  if (!data) return <></>;
  return (
    <div className="no-gutters">
      {(displayDataMapper[data.SUPPLY_MODE][data.GTS]).map((key) => (
        <Card className="data-card m-1 mt-0 d-inline-block" key={key}>
          <CardHeader
            avatar={<Avatar aria-label="recipe">{iconsMapper[key]}</Avatar>}
            title={
              <div
                data-help={getTitleTooltip(key, true)}
                title={getTitleTooltip(key)}
                style={{ "textTransform": "capitalize" }}
              >
                {key.replaceAll("_", " ").toLocaleLowerCase()}
              </div>
            }
            subheader={
              <div
                data-help={getTooltip(data, key, true)}
                title={getTooltip(data, key)}
              >
                {data[key]}
              </div>
            }
          />
        </Card>
      ))}
    </div>)

}

export default BasicData;
