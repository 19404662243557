import { useEffect, useState, useContext } from "react";
import { Store } from "../store";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chart from "react-google-charts";
import PredictionLineChartFilters from "./PredictionLineChartFilters";
import "../App.css";

function PredictionLineChart() {
  const [chartData, setChartData] = useState([]);
  const [opened, setOpened] = useState(null);
  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    if (state.app.selectedId && state.items.item[state.app.selectedId]) {
      console.log(state.items.item[state.app.selectedId])
      dispatch({
        type: 'SET_PREDICTIONS_LINE_CHART_DISPLAY',
        data: [state.items.item[state.app.selectedId]?.best_models]
      });
    }
  }, [state.items.item[state.app.selectedId]])

  function createChart(data, timeout) {

    if (state.items.item?.[state.app.selectedId]?.gts_info?.GTS % 2) return;
    const predictions = data.predictions;
    const lineChartDisplay = (state.app.predictionsLineChartDisplay || []).filter(x => x !== undefined);

    console.log(lineChartDisplay)
    // ODO: this is sus
    if (
      !lineChartDisplay.length ||
      !predictions// ||
      // !Object.keys(predictions).includes(lineChartDisplay[0])
    ) {
      setChartData([]);
      return;
    }
    const actual = Object.keys(data.ds_decompose.QUANTITY_KG).filter((ts) =>
      Object.keys(predictions[lineChartDisplay[0]]).includes(ts)
    );
    let chartData = actual.map((ts) => {
      let _chartData = [new Date(parseInt(ts)), data.ds_decompose.QUANTITY_KG[ts]];
      lineChartDisplay.forEach((lcd) => {
        _chartData.push(predictions[lcd][ts]);
      });
      return _chartData;
    });
    chartData = [["x", "Actual", ...lineChartDisplay], ...chartData];
    setChartData(chartData)
    // if (!timeout) setChartData(chartData);
    // else
    //   setTimeout(() => {
    //     setChartData(chartData);
    //   }, 1000);
  }


  useEffect(() => {
    console.log("use effect redraw chart yo")
    if (
      state.app.predictionsLineChartDisplay &&
      state.app.selectedId &&
      state.items.item[state.app.selectedId]
    ) {
      createChart(state.items.item[state.app.selectedId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.app.predictionsLineChartDisplay,
  ]);

  if (state.items.item?.[state.app.selectedId]?.gts_info?.GTS % 2) return <></>
  if (!state.app.selectedId) return (<></>);
  return (
    <Card>
      <CardContent>
        <div>
          <div className="inline-flex">
            <Typography color="textSecondary" gutterBottom>
              Time series validation chart
            </Typography>
          </div>
          <div className="prediction-filters">
            <PredictionLineChartFilters />
          </div>
        </div>
        {chartData.length ? (
          <div className="chart-container">
            <Chart
              width="109%"
              height="400px"
              chartType="LineChart"
              data={chartData}
              options={{
                chartArea: {
                  top: 10,
                  bottom: 60,
                  left: 60,
                },
                legend: {
                  position: "in",
                  textStyle: {
                    fontSize: 14,
                  },
                },
                explorer: {
                  actions: ["dragToZoom", "rightClickToReset"],
                  axis: "horizontal",
                  keepInBounds: true,
                  maxZoomIn: 4.0,
                },
                hAxis: {
                  format: "d MMM yyyy",
                  slantedText: true,
                  slantedTextAngle: 60,
                  textStyle: {
                    fontSize: 8,
                  },
                },
                vAxis: {
                  textStyle: {
                    fontSize: 12,
                  },
                },
                tooltip: {
                  textStyle: {
                    fontSize: 10,
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className="m-1 text-center">
            Please select predictions to display chart
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default PredictionLineChart;
