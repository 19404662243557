import toast from 'react-hot-toast';


import { ApiURL, Headers } from "../helpers";
import config from "../config";


export async function serviceCall(action, method, params, success) {
  const authClient = config.okta;
  const tokenManager = authClient.tokenManager;

  const token = await tokenManager.get("idToken");
  console.log("service call", action)
  const notify = () => toast.error(`Error fetching data for ${decodeURIComponent(action).split('tank?id=')[1]}`);
  let requestOptions = {
    method: method,
    headers: {
      Authorization: `Bearer ${token?.idToken}`,
      "Accept-Encoding": "gzip",
    },
  };
  if (params) {
    requestOptions.body = JSON.stringify(params);
  }
  console.log(`${ApiURL}${action}`, action);
  fetch(`${ApiURL}${action}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result);
      if (typeof success === "function") {
        success(result);
      }
    })
    .catch((error) => {
      console.log(error);
      notify()
    });
}
