import { useEffect, useContext } from "react";
import { Store } from "./store";
import { Toaster } from 'react-hot-toast';
import { serviceCall } from "./services";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./components/Header";
import MenuDrawer from "./components/MenuDrawer";
import Trends from "./pages/Trends";
import Predictions from "./pages/Predictions";
import Locations from "./pages/Locations";
import Help from "./pages/Help";
import CustomerFilter from "./components/CustomerFilter";
import SecureComponent from "./components/SecureComponent";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  Security,
  LoginCallback,
  useOktaAuth,
  SecureRoute,
} from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(3),
  },
}));

function App() {
  const authClient = config.okta;
  const tokenManager = authClient.tokenManager;

  const token = tokenManager.get("idToken");
  console.log(authClient, token);

  const classes = useStyles();

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  // const login = async () => oktaAuth.signInWithRedirect();
  // const logout = async () => oktaAuth.signOut('/');

  return (
    <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
      <div className={classes.root}>
        <Toaster />
        <CssBaseline />
        <Header />
        <MenuDrawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className="main-wrapper">
            <SecureComponent>
              <CustomerFilter />
            </SecureComponent>

            <Switch>
              <SecureRoute path="/" exact component={Help} />
              <SecureRoute path="/trends" exact component={Trends} />
              <SecureRoute path="/validation" exact component={Predictions} />

              <SecureRoute path="/locations" exact component={Locations} />

              <Route path="/implicit/callback" component={LoginCallback} />
            </Switch>
          </div>
        </main>
      </div>
    </Security>
  );
}



export default function Wrapped() {
  return (
    <Router>
      <App />
    </Router>
  );
}
