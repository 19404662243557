import React from 'react';
import PredictionBarChart from '../components/PredictionBarChart';
import PredictionLineChart from '../components/PredictionLineChart';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useContext } from 'react';
import { Store } from '../store';
import '../App.css';

export default function Predictions() {
  const { state } = useContext(Store);
  console.log()
  if (state.app.selectedId && state.items.item?.[state.app.selectedId]?.gts_info?.GTS % 2) {
    return <div>Aggregation level not supported</div>
  }
  else if (state.app.selectedId) return (
    <React.Fragment>
      <PredictionBarChart />
      <div className="divider"></div>
      <PredictionLineChart />
    </React.Fragment>
  );
  else return <div className="loader"><CircularProgress /></div>;

}