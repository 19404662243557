const app = (state, action) => {
  switch (action.type) {
    case 'SET_LINE_CHART_DISPLAY':
      return { ...state, lineChartDisplay: action.data };
    case 'SET_FORECASTS_LINE_CHART_DISPLAY':
      return { ...state, forecastsLineChartDisplay: action.data };
    case 'SET_PREDICTIONS_LINE_CHART_DISPLAY':
      return { ...state, predictionsLineChartDisplay: action.data };
    case 'SET_MAP_FILTERS':
      return { ...state, mapFilters: action.data };
    case 'SET_DRAWER_OPENED':
      return { ...state, drawerOpened: action.data };
    case 'SET_SELECTED_ID':
      return { ...state, selectedId: action.data };
    case 'SET_NEW_EXISTING_CHANGED':
      return { ...state, newExistingHasChanged: action.data }
    default:
      return { ...state };
  }
}

export default app;