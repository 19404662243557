import React from "react";
import reducer from "../reducers";

export const Store = React.createContext();

const initialState = {
  app: {
    selectedId: null,
    newExistingHasChanged: true,
    drawerOpened: false,
    lineChartDisplay: {
      actual: true,
      trend: true,
      forecastLow: true,
      forecastHigh: true,
      forecast: true,
      updates: false,
    },
    forecastsLineChartDisplay: {
      forecast: true,
      "forecast-low": true,
      "forecast-high": true,
    },
    predictionsLineChartDisplay: [],
    mapFilters: {
      PRODUCT_TYPE: null,
      PRODUCT_GROUP: null,
      CUSTOMER_TYPE: null,
      DELIVERY_TYPE: null,
      SOLDTO_BUSINESS_AREA_NAME: null,
    },
  },
  items: {
    list: [],
    item: {},
  },
};

export function Provider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{children}</Store.Provider>;
}
