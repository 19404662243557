import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useContext } from 'react';
import clsx from 'clsx';
import { Store } from '../store';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Timeline, Equalizer, Room, Help} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { Link, useLocation } from 'react-router-dom';
import '../App.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

function MenuDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { state, dispatch } = useContext(Store);

  function handleDrawerClose() {
    dispatch({
      type: 'SET_DRAWER_OPENED',
      data: false
    });
  }
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: state.app.drawerOpened,
        [classes.drawerClose]: !state.app.drawerOpened,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: state.app.drawerOpened,
          [classes.drawerClose]: !state.app.drawerOpened,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <List>
          <Link to="/" className={`disable-link-styles ${location.pathname === '/' ? "active" : ""}`}>
            <ListItem button>
              <ListItemIcon><Help /></ListItemIcon>
              <ListItemText primary='Help' />
            </ListItem>
          </Link>
        </List>
        <Divider/>
        <Link to="/trends" className={`disable-link-styles ${location.pathname === '/trends' ? "active" : ""}`}>
          <ListItem button>
            <ListItemIcon><Timeline /></ListItemIcon>
            <ListItemText primary='Trends' />
          </ListItem>
        </Link>
        <Link to="/validation" className={`disable-link-styles ${location.pathname === '/validation' ? "active" : ""}`}>
          <ListItem button>
            <ListItemIcon><Equalizer /></ListItemIcon>
            <ListItemText primary='Validation' />
          </ListItem>
        </Link>
        <Link to="/locations" className={`disable-link-styles ${location.pathname === '/locations' ? "active" : ""}`}>
          <ListItem button>
            <ListItemIcon><Room /></ListItemIcon>
            <ListItemText primary='Locations' />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  )
}

export default MenuDrawer;