import { useContext } from "react";
import { Store } from "../store";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  ColloreSwitchType2,
  ColloreSwitchType3,
  ColloreSwitchType6,
  ColloreSwitchType7,
  ColloreSwitchType8,
} from "../styles/switches";
import "../App.css";
import { serviceCall } from "../services";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
  },
}));

function LineChartFilters() {
  const { state, dispatch } = useContext(Store);
  const classes = useStyles();

  const displayChange = (event) => {
    console.log("display change");

    dispatch({
      type: "SET_LINE_CHART_DISPLAY",
      data: {
        ...state.app.lineChartDisplay,
        [event.target.name]: event.target.checked,
      },
    });

    // we want to retrigger the updates endpoint to get latest data
    // TODO: new existing needs to be accessed here
    if (event.target.name === "updates" && event.target.checked && 0) {
      serviceCall(
        `tank?id=${encodeURIComponent(state.app.selectedId).replace(
          "%7C",
          "|"
        )}`,
        "GET",
        null,
        (data) => {
          dispatch({
            type: "SET_ITEM",
            selectedId: state.app.selectedId,
            data: data,
          });
          const bestModelValue = Math.min(...Object.values(data.score));
          dispatch({
            type: "SET_PREDICTIONS_LINE_CHART_DISPLAY",
            data: [
              Object.keys(data.score).find(
                (model) => data.score[model] === bestModelValue
              ),
            ],
          });
        }
      );
    }
  };

  return (
    <div>
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <ColloreSwitchType2
            size="small"
            checked={state.app.lineChartDisplay["actual"]}
            onChange={displayChange}
            name="actual"
          />
        }
        label="Actual"
      />
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <ColloreSwitchType3
            size="small"
            checked={state.app.lineChartDisplay["trend"]}
            onChange={displayChange}
            name="trend"
          />
        }
        label="Trend"
      />
      {/* <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <ColloreSwitchType6
            size="small"
            checked={state.app.lineChartDisplay["fitted-upper"]}
            onChange={displayChange}
            name="fitted-upper"
          />
        }
        label="Upper confidence level"
      />
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <ColloreSwitchType6
            size="small"
            checked={state.app.lineChartDisplay["fitted-lower"]}
            onChange={displayChange}
            name="fitted-lower"
          />
        }
        label="Lower confidence level"
      /> */}
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <ColloreSwitchType7
            size="small"
            checked={state.app.lineChartDisplay["forecast"]}
            onChange={displayChange}
            name="forecast"
          />
        }
        label="Forecast"
      />
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <ColloreSwitchType8
            size="small"
            checked={state.app.lineChartDisplay["updates"]}
            onChange={displayChange}
            name="updates"
          />
        }
        label="Updates"
      />
    </div>
  );
}

export default LineChartFilters;
