import Popper from "@material-ui/core/Popper";

const AutoWidthPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  );
};

export default AutoWidthPopper;
