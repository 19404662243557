import React from 'react';
import LocationsMap from '../components/LocationsMap';
import LocationsFilters from '../components/LocationsFilters';

export default function Locations() {
  return (
    <React.Fragment>
      <LocationsFilters />
      <div className="divider"></div>
      <LocationsMap />
    </React.Fragment>
  );
}
